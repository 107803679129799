import {
  Grid,
  Checkbox,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
  Card,  
  CardActions,
  CardContent, 
  Tooltip,
  Badge
} from "@mui/material";
import React, { Component } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {  LinkItemDialog } from "./style";
import { connect } from "react-redux";
import { setSelectedVendors } from "../../../redux/actions/vendorActions";
import { FlatsearchInput } from "../../TextField";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';

import {
  getApprovedVendors,
  getUnlinkedVendors,
  getUnpprovedVendors,
  linkVendors,
  searchApprovedVendors,
  searchUnapprovedVendors,
  searchUnlinkedVendors,
} from "../../../config/apiUrl";
import ClearIcon from "@mui/icons-material/Clear";
import { debounce } from "lodash";
import axios from "axios";
import VendorList from "./VendorList";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import { LinkRounded } from "@mui/icons-material";
import SelectedVendors from "./SelectedVendors";

class LinkVendorToCategory extends Component {
  state = {
    anchorEl1: null,
    anchorEl2: null,
    anchorEl3: null,
    unlinkedVendors: [],
    unlinkedVendorsLoading: false,
    unlinkedVendorsCurrentPage: 1,
    unlinkedVendorsCurrentLastPage: 1,
    unlinkedVendorsTotal: 0,
    unlinkedVendorsInput: "",
    checkedunlinkedVendors: [],

    searchedUnlinkedVendors: [],
    searchedUnlinkedVendorsLoading: false,
    searchedUnlinkedVendorsCurrentPage: 1,
    searchedUnlinkedVendorsCurrentLastPage: 1,
    searchedUnlinkedVendorsTotal: 0,
    prevUnlinkedSearch: "",
    checkedSearchedUnlinkedVendors: [],

    showSelectedUnlinked: false,

    approvedVendors: [],
    approvedVendorsLoading: false,
    approvedVendorsCurrentPage: 1,
    approvedVendorsCurrentLastPage: 1,
    approvedVendorsTotal: 0,
    approvedVendorsInput: "",
    checkedapprovedVendors: [],

    searchedApprovedVendors: [],
    searchedApprovedVendorsLoading: false,
    searchedApprovedVendorsCurrentPage: 1,
    searchedApprovedVendorsCurrentLastPage: 1,
    searchedApprovedVendorsTotal: 0,
    prevApprovedSearch: "",
    checkedSearchedApprovedVendors: [],

    showSelectedApproved: false,

    nonapprovedVendors: [],
    nonapprovedVendorsLoading: false,
    nonapprovedVendorsCurrentPage: 1,
    nonapprovedVendorsCurrentLastPage: 1,
    nonapprovedVendorsTotal: 0,
    nonapprovedVendorsInput: "",
    checkednonapprovedVendors: [],

    searchedNonapprovedVendors: [],
    searchedNonapprovedVendorsLoading: false,
    searchedNonapprovedVendorsCurrentPage: 1,
    searchedNonapprovedVendorsCurrentLastPage: 1,
    searchedNonapprovedVendorsTotal: 0,
    prevNonapprovedSearch: "",
    checkedSearchedNonapprovedVendors: [],

    showSelectedNonapproved: false,

    vendorApproval: false
  };

  componentDidMount() {
    this.getUnlinkedVendors();
    this.getApprovedVendors();
    this.getNonapprovedVendors();

    this.setState({
      vendorApproval:this.props.masterPermission?.vendor_approval === "Y"
    })
  }

  componentDidUpdate() {
    if ( this.state.showSelectedUnlinked && !this.state.checkedunlinkedVendors.length){
      this.setState({
        showSelectedUnlinked: false,
      });
    }
    
    if ( this.state.showSelectedApproved && !this.state.checkedapprovedVendors.length){
      this.setState({
        showSelectedApproved: false,
      });
    }

    if ( this.state.showSelectedNonapproved && !this.state.checkednonapprovedVendors.length){
      this.setState({
        showSelectedNonapproved: false,
      });
    }
  }

  toggleShowSelectedUnlinked = () => {
    this.setState({ 
      showSelectedUnlinked: !this.state.showSelectedUnlinked 
    })
  };

  toggleShowSelectedApproved = () => {
    this.setState({ 
      showSelectedApproved: !this.state.showSelectedApproved 
    })
  };

  toggleShowSelectedNonapproved = () => {
    this.setState({ 
      showSelectedNonapproved: !this.state.showSelectedNonapproved 
    })
  };

  handleSelectAllClick = (event, type) => {
    let newSelecteds = [];
    if (type === "checkedunlinkedVendors") {
      if (this.state.unlinkedVendorsInput.trim() === ""){
        let newRows = [...this.state.unlinkedVendors, ...this.state.checkedunlinkedVendors];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      } else {
        let newRows = [...this.state.searchedUnlinkedVendors, ...this.state.checkedunlinkedVendors];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      }
    } else if (type === "checkedapprovedVendors") {
      if (this.state.approvedVendorsInput.trim() === ""){
        let newRows = [...this.state.approvedVendors, ...this.state.checkedapprovedVendors];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      } else {
        let newRows = [...this.state.searchedApprovedVendors, ...this.state.checkedapprovedVendors];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      }
    } else if (type === "checkednonapprovedVendors") {
      if (this.state.nonapprovedVendorsInput.trim() === ""){
        let newRows = [...this.state.nonapprovedVendors, ...this.state.checkednonapprovedVendors];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      } else {
        let newRows = [...this.state.searchedNonapprovedVendors, ...this.state.checkednonapprovedVendors];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      }
    } 

    if (event.target.checked) {
      this.setState({
        [type]: newSelecteds,
      });
      return;
    }

    let update = [];
    if (type === "checkedunlinkedVendors") {
      if (this.state.unlinkedVendorsInput.trim() === ""){
        //removing the unlinkedVendors from the checkedunlinkedVendors
        update = this.state.checkedunlinkedVendors.filter(el => !this.state.unlinkedVendors.map(ob => ob.id).includes(el.id));
      } else {
        //removing the searchedUnlinkedVendors from the checkedunlinkedVendors
        update = this.state.checkedunlinkedVendors.filter(el => !this.state.searchedUnlinkedVendors.map(ob => ob.id).includes(el.id));
      }
    } else if (type === "checkedapprovedVendors") {
      if (this.state.approvedVendorsInput.trim() === ""){
        //removing the approvedVendors from the checkedapprovedVendors
        update = this.state.checkedapprovedVendors.filter(el => !this.state.approvedVendors.map(ob => ob.id).includes(el.id));
      } else {
        //removing the searchedApprovedVendors from the checkedapprovedVendors
        update = this.state.checkedapprovedVendors.filter(el => !this.state.searchedApprovedVendors.map(ob => ob.id).includes(el.id));
      }
    } else if (type === "checkednonapprovedVendors") {
      if (this.state.nonapprovedVendorsInput.trim() === ""){
        //removing the nonapprovedVendors from the checkednonapprovedVendors
        update = this.state.checkednonapprovedVendors.filter(el => !this.state.nonapprovedVendors.map(ob => ob.id).includes(el.id));
      } else {
        //removing the searchedNonapprovedVendors from the checkednonapprovedVendors
        update = this.state.checkednonapprovedVendors.filter(el => !this.state.searchedNonapprovedVendors.map(ob => ob.id).includes(el.id));
      }
    }
    this.setState({
      [type]: update,
    });
  };

  handleItemClick = (event, name, type) => {
    // console.log(name, "name");
    // const selectedIndex = this.state[type].indexOf(name);
    const selectedIndex = this.state[type].map(el => el.id).indexOf(name.id);

    let newSelected = [];
    // console.log(selectedIndex, "selectedIndex");
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state[type], name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state[type].slice(1));
    } else if (selectedIndex === this.state[type]?.length - 1) {
      newSelected = newSelected.concat(this.state[type].slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state[type].slice(0, selectedIndex),
        this.state[type].slice(selectedIndex + 1)
      );
    }
    this.setState({
      [type]: newSelected,
    });
  };

  isItemSelected = (row, type) => {
    return this.state[type].some(
      e => e.id === row.id
    )
  };

  getUnlinkedVendors = () => {
    this.setState({
      unlinkedVendorsLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getUnlinkedVendors(
      this.props.selectedCategory?.id,
      this.state.unlinkedVendorsCurrentPage
    );
    axios
      .get(url)
      .then((response) => {
        this.setState({
          unlinkedVendors: [
            ...this.state.unlinkedVendors,
            ...response.data.data,
          ],
          unlinkedVendorsCurrentLastPage: response.data.meta?.last_page,
          unlinkedVendorsTotal: response.data.meta?.total,
          unlinkedVendorsLoading: false,
        });
      })
      .catch((err) => {
        this.props.openSnackbar(
          "error",
          "Internal Server Error. Please try again later."
        );
        console.log(err, "unlinkedVendors error");
        this.setState({
          unlinkedVendorsLoading: false,
        });
      });
  };

  getApprovedVendors = () => {
    this.setState({
      approvedVendorsLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getApprovedVendors(
      this.props.selectedCategory?.id,
      this.state.approvedVendorsCurrentPage
    );
    axios
      .get(url)
      .then((response) => {
        this.setState({
          approvedVendors: [
            ...this.state.approvedVendors,
            ...response.data.data,
          ],
          approvedVendorsCurrentLastPage: response.data.meta?.last_page,
          approvedVendorsTotal: response.data.meta?.total,
          approvedVendorsLoading: false,
        });
      })
      .catch((err) => {
        this.props.openSnackbar(
          "error",
          "Internal Server Error. Please try again later."
        );
        console.log(err, "approvedVendorsLoading error");
        this.setState({
          approvedVendorsLoading: false,
        });
      });
  };

  getNonapprovedVendors = () => {
    this.setState({
      nonapprovedVendorsLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getUnpprovedVendors(
      this.props.selectedCategory?.id,
      this.state.nonapprovedVendorsCurrentPage
    );
    axios
      .get(url)
      .then((response) => {
        this.setState({
          nonapprovedVendors: [
            ...this.state.nonapprovedVendors,
            ...response.data.data,
          ],
          nonapprovedVendorsCurrentLastPage: response.data.meta?.last_page,
          nonapprovedVendorsTotal: response.data.meta?.total,
          nonapprovedVendorsLoading: false,
        });
      })
      .catch((err) => {
        this.props.openSnackbar(
          "error",
          "Internal Server Error. Please try again later."
        );
        console.log(err, "nonapprovedVendors error");
        this.setState({
          nonapprovedVendorsLoading: false,
        });
      });
  };

  handleChange = (evt) => {
    const { name, value } = evt.target;

    this.setState({
      [name]: value,
    });
    if (value.trim() !== "") {
      this.setState({
        prevUnlinkedSearch: this.state.unlinkedVendorsInput,
        prevApprovedSearch: this.state.approvedVendorsInput,
        prevNonapprovedSearch: this.state.nonapprovedVendorsInput,
      }, () => { 
        this.getSearchResult(name, value);
      });
    }
  };

  getSearchResult = debounce((name, value) => {
    // console.log("debounce");
    if (name === "unlinkedVendorsInput") {
      this.setState({
        searchedUnlinkedVendorsCurrentPage:1,
        searchedUnlinkedVendorsCurrentLastPage:1,
        searchedUnlinkedVendors:[],
      }, () => {
        this.searchUnlinkedVendors(value);
      })
    } else if (name === "approvedVendorsInput") {

      this.setState({
        searchedApprovedVendorsCurrentPage:1,
        searchedApprovedVendorsCurrentLastPage:1,
        searchedApprovedVendors:[],
      }, () => {
        this.searchApprovedVendors(value);
      })

    } else if (name === "nonapprovedVendorsInput") {
      this.setState({
        searchedNonapprovedVendorsCurrentPage:1,
        searchedNonapprovedVendorsCurrentLastPage:1,
        searchedNonapprovedVendors:[],
      }, () => {
        this.searchNonapprovedVendors(value);
      })
    }
  }, 1000);

  searchUnlinkedVendors = (value) => {
    this.setState({
      searchedUnlinkedVendorsLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    //search unlinked vendor api

    const url = searchUnlinkedVendors(
      this.props.selectedCategory?.id,
      value.trim(),
      this.state.searchedUnlinkedVendorsCurrentPage
    );
    axios
      .get(url)
      .then((response) => {
        let final;
        // append or not respective to search is changes or not
        if (this.state.searchedUnlinkedVendorsCurrentPage !== 1) {
          final = [
            ...this.state.searchedUnlinkedVendors,
            ...response.data.data,
          ];
        } else {
          final = response.data.data;
          // if the search is changes then clear selection
          // this.setState({
          //   checkedSearchedUnlinkedVendors: [],
          // });
        }
        this.setState({
          searchedUnlinkedVendors: final,
          searchedUnlinkedVendorsCurrentLastPage: response.data.meta?.last_page,
          searchedUnlinkedVendorsTotal: response.data.meta?.total,
          searchedUnlinkedVendorsLoading: false,
        });
      })
      .catch((err) => {
        this.props.openSnackbar(
          "error",
          "Internal Server Error. Please try again later."
        );
        console.log(err, "searchedlinkedItem error");
        this.setState({
          searchedUnlinkedVendorsLoading: false,
          searchedUnlinkedVendors: [],
          searchedUnlinkedVendorsCurrentPage:1,
          searchedUnlinkedVendorsCurrentLastPage: 1,
          searchedUnlinkedVendorsTotal: 0,
        });
      });
  };

  searchApprovedVendors = (value) => {
    this.setState({
      searchedApprovedVendorsLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    //search Approved vendor api

    const url = searchApprovedVendors(
      this.props.selectedCategory?.id,
      value.trim(),
      this.state.searchedApprovedVendorsCurrentPage
    );
    axios
      .get(url)
      .then((response) => {
        let final;
        // append or not respective to search is changes or not
        if (this.state.searchedApprovedVendorsCurrentPage !== 1) {
          final = [
            ...this.state.searchedApprovedVendors,
            ...response.data.data,
          ];
        } else {
          final = response.data.data;
          // if the search is changes then clear selection
          // this.setState({
          //   checkedSearchedApprovedVendors: [],
          // });
        }
        this.setState({
          searchedApprovedVendors: final,
          searchedApprovedVendorsCurrentLastPage: response.data.meta?.last_page,
          searchedApprovedVendorsTotal: response.data.meta?.total,
          searchedApprovedVendorsLoading: false,
        });
      })
      .catch((err) => {
        this.props.openSnackbar(
          "error",
          "Internal Server Error. Please try again later."
        );
        console.log(err, "searchedApprovedVendors error");
        this.setState({
          searchedApprovedVendorsLoading: false,
          searchedApprovedVendors: [],
          searchedApprovedVendorsCurrentPage: 1,
          searchedApprovedVendorsCurrentLastPage: 1,
          searchedApprovedVendorsTotal: 0,
        });
      });
  };

  searchNonapprovedVendors = (value) => {
    this.setState({
      searchedNonapprovedVendorsLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    //search Approved vendor api

    const url = searchUnapprovedVendors(
      this.props.selectedCategory?.id,
      value.trim(),
      this.state.searchedNonapprovedVendorsCurrentPage
    );
    axios
      .get(url)
      .then((response) => {
        let final;
        // append or not respective to search is changes or not
        if (this.state.searchedNonapprovedVendorsCurrentPage !== 1) {
          final = [
            ...this.state.searchedNonapprovedVendors,
            ...response.data.data,
          ];
        } else {
          final = response.data.data;
          // if the search is changes then clear selection
          // this.setState({
          //   checkedSearchedNonapprovedVendors: [],
          // });
        }
        this.setState({
          searchedNonapprovedVendors: final,
          searchedNonapprovedVendorsCurrentLastPage:
            response.data.meta?.last_page,
          searchedNonapprovedVendorsTotal:
            response.data.meta?.total,
          searchedNonapprovedVendorsLoading: false,
        });
      })
      .catch((err) => {
        this.props.openSnackbar(
          "error",
          "Internal Server Error. Please try again later."
        );
        console.log(err, "searchedNonapprovedVendors error");
        this.setState({
          searchedNonapprovedVendorsLoading: false,
          searchedNonapprovedVendors: [],
          searchedNonapprovedVendorsCurrentLastPage:1,
          searchedNonapprovedVendorsCurrentPage:1,
          searchedNonapprovedVendorsTotal:0,
        });
      });
  };

  unlinkVendors = (from) => {
    // from = approved or non approved
    let checked;

    if (from === "approved") {
      this.setState({
        approvedVendorsLoading: true,
      });
      checked = "checkedapprovedVendors";
      // if (this.state.approvedVendorsInput.trim() === "") {
      //   // main = "approvedVendors";
      //   this.setState({
      //     approvedVendorsLoading: true,
      //   });
      // } else {
      //   checked = "checkedSearchedApprovedVendors";
      //   main = "searchedApprovedVendors";
      //   this.setState({
      //     searchedApprovedVendorsLoading: true,
      //   });
      // }
    } else {
      this.setState({
        nonapprovedVendorsLoading: true,
      });
      checked = "checkednonapprovedVendors";
      // if (this.state.nonapprovedVendorsInput.trim() === "") {
      //   // main = "nonapprovedVendors";
      //   this.setState({
      //     nonapprovedVendorsLoading: true,
      //   });
      // } else {
      //   checked = "checkedSearchedNonapprovedVendors";
      //   main = "searchedNonapprovedVendors";
      //   this.setState({
      //     searchedNonapprovedVendorsLoading: true,
      //   });
      // }
    }
    if (!this.state[checked]?.length) {
      this.props.openSnackbar("error", "No vendor(s) selected");
      this.setState({
        approvedVendorsLoading: false,
        searchedApprovedVendorsLoading: false,
        nonapprovedVendorsLoading: false,
        searchedNonapprovedVendorsLoading: false,
      });
      return;
    }
    let data = {
      vendors: this.state[checked].map((vendor) => vendor.id),
      linking_stage: "20",
    };

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = linkVendors(this.props.selectedCategory?.id);

    axios
      .post(url, data)
      .then((response) => {
        if (response.status === 200) {
          this.props.openSnackbar("success", response.data.message, true);
          this.props.handleCategoryVendorsReload();
          if (this.props.master_type === "item") {
            this.props.updateCartItemsDetails();
          }
          // here the unlink vendors have to be reset, and the from com...
          this.setState({
            unlinkedVendors: [],
            unlinkedVendorsLoading: false,
            unlinkedVendorsCurrentPage: 1,
            unlinkedVendorsCurrentLastPage: 1,
            unlinkedVendorsTotal: 0,
            unlinkedVendorsInput: "",
            checkedunlinkedVendors: [],

            searchedUnlinkedVendors: [],
            searchedUnlinkedVendorsLoading: false,
            searchedUnlinkedVendorsCurrentPage: 1,
            searchedUnlinkedVendorsCurrentLastPage: 1,
            searchedUnlinkedVendorsTotal: 0,
            prevUnlinkedSearch: "",
            checkedSearchedUnlinkedVendors: [],

            showSelectedUnlinked: false,
          }, () => {
            this.getUnlinkedVendors();
            if (from === "approved"){
              this.setState({
                approvedVendors: [],
                approvedVendorsLoading: false,
                approvedVendorsCurrentPage: 1,
                approvedVendorsCurrentLastPage: 1,
                approvedVendorsTotal: 0,
                approvedVendorsInput: "",
                checkedapprovedVendors: [],

                searchedApprovedVendors: [],
                searchedApprovedVendorsLoading: false,
                searchedApprovedVendorsCurrentPage: 1,
                searchedApprovedVendorsCurrentLastPage: 1,
                searchedApprovedVendorsTotal: 0,
                prevApprovedSearch: "",
                checkedSearchedApprovedVendors: [],

                showSelectedApproved: false,
              }, () => {
                this.getApprovedVendors();
              })
            } else {
              this.setState({
                nonapprovedVendors: [],
                nonapprovedVendorsLoading: false,
                nonapprovedVendorsCurrentPage: 1,
                nonapprovedVendorsCurrentLastPage: 1,
                nonapprovedVendorsTotal: 0,
                nonapprovedVendorsInput: "",
                checkednonapprovedVendors: [],

                searchedNonapprovedVendors: [],
                searchedNonapprovedVendorsLoading: false,
                searchedNonapprovedVendorsCurrentPage: 1,
                searchedNonapprovedVendorsCurrentLastPage: 1,
                searchedNonapprovedVendorsTotal: 0,
                prevNonapprovedSearch: "",
                checkedSearchedNonapprovedVendors: [],

                showSelectedNonapproved: false,
              }, () => {
                this.getNonapprovedVendors();
              })
            }
          })

        }
      })
      .catch((er) => {
        if (er.response) {
          if (er.response.data?.message) {
            this.props.openSnackbar("error", er.response.data?.message);
          } else if (er.response.status === 500) {
            this.props.openSnackbar(
              "error",
              "Internal Server Error. Please try again later."
            );
          } else if (er.response.status === 503) {
            this.props.openSnackbar(
              "error",
              "Service temporarily unavailable. Please try again later."
            );
          } else {
            this.props.openSnackbar(
              "error",
              `Failed to unlink the selected vendor(s)`
            );
          }
        } else {
          this.props.openSnackbar(
            "error",
            "Network Error"
          );
        }
        this.setState({
          approvedVendorsLoading: false,
          searchedApprovedVendorsLoading: false,
          nonapprovedVendorsLoading: false,
          searchedNonapprovedVendorsLoading: false,
        });
      });
  };
  linkVendors = (type) => {
    this.setState({
      unlinkedVendorsLoading: true,
    });
    //type will be the option to be performed, i.e if type = unlink then items will be unlinked
    // const { unlinkedVendorsInput } = this.state;

    let checked;
    
    checked = "checkedunlinkedVendors";
    // if (unlinkedVendorsInput.trim() === "") {
    //   //search is not active
    //   main = "unlinkedVendors";
    // } else {
    //   checked = "checkedSearchedUnlinkedVendors";
    //   main = "searchedUnlinkedVendors";
    // }
    if (!this.state[checked]?.length) {
      this.props.openSnackbar("error", "No vendor(s) selected");
      this.setState({
        unlinkedVendorsLoading: false,
        // searchedUnlinkedVendorsLoading: false,
      });
      return;
    }
    let data = {
      vendors: this.state[checked].map((vendor) => vendor.id),
      linking_stage: "10",
      link_as: type,
    };

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = linkVendors(this.props.selectedCategory?.id);

    axios
      .post(url, data)
      .then((response) => {
        if (response.status === 200) {
          if (this.props.master_type === "item") {
            this.props.updateCartItemsDetails();
          }

          this.props.openSnackbar("success", response.data.message, true);
          this.props.handleCategoryVendorsReload();

          // here vendors are linking so the unlinked data have to be reset and 
          // based on the linking status, that component needs to be rest

          this.setState({
            unlinkedVendors: [],
            unlinkedVendorsLoading: false,
            unlinkedVendorsCurrentPage: 1,
            unlinkedVendorsCurrentLastPage: 1,
            unlinkedVendorsTotal: 0,
            unlinkedVendorsInput: "",
            checkedunlinkedVendors: [],

            searchedUnlinkedVendors: [],
            searchedUnlinkedVendorsLoading: false,
            searchedUnlinkedVendorsCurrentPage: 1,
            searchedUnlinkedVendorsCurrentLastPage: 1,
            searchedUnlinkedVendorsTotal: 0,
            prevUnlinkedSearch: "",
            checkedSearchedUnlinkedVendors: [],

            showSelectedUnlinked: false,
          }, () => {
            this.getUnlinkedVendors();
            if (type === 10){
              this.setState({
                approvedVendors: [],
                approvedVendorsLoading: false,
                approvedVendorsCurrentPage: 1,
                approvedVendorsCurrentLastPage: 1,
                approvedVendorsTotal: 0,
                approvedVendorsInput: "",
                checkedapprovedVendors: [],

                searchedApprovedVendors: [],
                searchedApprovedVendorsLoading: false,
                searchedApprovedVendorsCurrentPage: 1,
                searchedApprovedVendorsCurrentLastPage: 1,
                searchedApprovedVendorsTotal: 0,
                prevApprovedSearch: "",
                checkedSearchedApprovedVendors: [],

                showSelectedApproved: false,
              }, () => {
                this.getApprovedVendors();
              })
            } else {
              this.setState({
                nonapprovedVendors: [],
                nonapprovedVendorsLoading: false,
                nonapprovedVendorsCurrentPage: 1,
                nonapprovedVendorsCurrentLastPage: 1,
                nonapprovedVendorsTotal: 0,
                nonapprovedVendorsInput: "",
                checkednonapprovedVendors: [],

                searchedNonapprovedVendors: [],
                searchedNonapprovedVendorsLoading: false,
                searchedNonapprovedVendorsCurrentPage: 1,
                searchedNonapprovedVendorsCurrentLastPage: 1,
                searchedNonapprovedVendorsTotal: 0,
                prevNonapprovedSearch: "",
                checkedSearchedNonapprovedVendors: [],

                showSelectedNonapproved: false,
              }, () => {
                this.getNonapprovedVendors();
              })
            }
          })

        }
      })
      .catch((er) => {
        console.log(er, "linkunlink");
        if (er.response) {
          if (er.response.data?.message) {
            this.props.openSnackbar("error", er.response.data?.message);
          } else if (er.response.status === 500) {
            this.props.openSnackbar(
              "error",
              "Internal Server Error. Please try again later."
            );
          } else if (er.response.status === 503) {
            this.props.openSnackbar(
              "error",
              "Service temporarily unavailable. Please try again later."
            );
          } else {
            this.props.openSnackbar(
              "error",
              `Failed to link the selected vendor(s)`
            );
          }
        } else {
          this.props.openSnackbar(
            "error",
            "Network Error"
          );
        }
        this.setState({
          unlinkedVendorsLoading: false,
          searchedUnlinkedVendorsLoading: false,
        });
      });
  };

  changeVendorsStatus = (newStatus) => {
    let checked;

    if (newStatus === "non-approved") {
      checked = "checkedapprovedVendors";
      this.setState({
        approvedVendorsLoading: true,
      });
      // if (this.state.approvedVendorsInput.trim() === "") {
      //   main = "approvedVendors";
      // } else {
      //   checked = "checkedSearchedApprovedVendors";
      //   main = "searchedApprovedVendors";
      //   this.setState({
      //     searchedApprovedVendorsLoading: true,
      //   });
      // }
    } else {
      checked = "checkednonapprovedVendors";
      this.setState({
        nonapprovedVendorsLoading: true,
      });
      // if (this.state.nonapprovedVendorsInput.trim() === "") {
      //   main = "nonapprovedVendors";
      // } else {
      //   checked = "checkedSearchedNonapprovedVendors";
      //   main = "searchedNonapprovedVendors";
      //   this.setState({
      //     searchedNonapprovedVendorsLoading: true,
      //   });
      // }
    }
    if (!this.state[checked]?.length) {
      this.props.openSnackbar("error", "No vendor(s) selected");
      this.setState({
        approvedVendorsLoading: false,
        searchedApprovedVendorsLoading: false,
        nonapprovedVendorsLoading: false,
        searchedNonapprovedVendorsLoading: false,
      });
      return;
    }
    
    let data = {
      vendor_ids: this.state[checked].map((vendor) => vendor.id),
      link_as: newStatus,
    };

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = linkVendors(this.props.selectedCategory?.id);

    axios
      .put(url, data)
      .then((response) => {
        if (response.status === 200) {
          //remove from the main
         
          this.props.openSnackbar("success", response.data.message, true);
          this.props.handleCategoryVendorsReload();

          // here the approved and nonapproved vendors have to be reset
          this.setState({
            approvedVendors: [],
            approvedVendorsLoading: false,
            approvedVendorsCurrentPage: 1,
            approvedVendorsCurrentLastPage: 1,
            approvedVendorsTotal: 0,
            approvedVendorsInput: "",
            checkedapprovedVendors: [],

            searchedApprovedVendors: [],
            searchedApprovedVendorsLoading: false,
            searchedApprovedVendorsCurrentPage: 1,
            searchedApprovedVendorsCurrentLastPage: 1,
            searchedApprovedVendorsTotal: 0,
            prevApprovedSearch: "",
            checkedSearchedApprovedVendors: [],

            showSelectedApproved: false,

            nonapprovedVendors: [],
            nonapprovedVendorsLoading: false,
            nonapprovedVendorsCurrentPage: 1,
            nonapprovedVendorsCurrentLastPage: 1,
            nonapprovedVendorsTotal: 0,
            nonapprovedVendorsInput: "",
            checkednonapprovedVendors: [],

            searchedNonapprovedVendors: [],
            searchedNonapprovedVendorsLoading: false,
            searchedNonapprovedVendorsCurrentPage: 1,
            searchedNonapprovedVendorsCurrentLastPage: 1,
            searchedNonapprovedVendorsTotal: 0,
            prevNonapprovedSearch: "",
            checkedSearchedNonapprovedVendors: [],

            showSelectedNonapproved: false,
          }, () => {
            this.getNonapprovedVendors();
            this.getApprovedVendors();
          })

        }
      })
      .catch((er) => {
        if (er.response) {
          if (er.response.data?.message) {
            this.props.openSnackbar("error", er.response.data?.message);
          } else if (er.response.status === 500) {
            this.props.openSnackbar(
              "error",
              "Internal Server Error. Please try again later."
            );
          } else if (er.response.status === 503) {
            this.props.openSnackbar(
              "error",
              "Service temporarily unavailable. Please try again later."
            );
          } else {
            this.props.openSnackbar(
              "error",
              `Failed to unlink the selected vendor(s)`
            );
          }
        } else {
          this.props.openSnackbar(
            "error",
            "Network Error"
          );
        }
        this.setState({
          approvedVendorsLoading: false,
          searchedApprovedVendorsLoading: false,
          nonapprovedVendorsLoading: false,
          searchedNonapprovedVendorsLoading: false,
        });
      });
  }

  commonItems = (arr1, arr2) => {
    return arr1.filter(function (o1) {
      return arr2.some(function (o2) {
          return o1.id === o2.id; // return the ones with equal id
     });
    });
  }

  render() {
    const { open, handleClose } = this.props;
    return (
      <div>
        <LinkItemDialog
          // fullScreen={false}
          scroll="body"
          maxWidth={"md"}
          open={open}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"            
          >
            Link Vendors To Category
             <Box className="dialog-title-action">
              <IconButton
                sx={{
                  color: (theme) => theme.palette.bluegrey[500],}}
                  onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>         
           
          </DialogTitle>
          <DialogContent sx={{pt: !this.props.isVendorSelected && '16px !important' }}>
              {this.props.isVendorSelected && <Typography component="p" color="grey.500" sx={{ my:'16px !important'}}>
                Note: Vendor selection will be reset after linking, unlinking or changing status.
              </Typography>}
              
              <div className="linkcategorydialog_main">
                <Grid container spacing={2}>
                  <Grid item md={this.state.vendorApproval ? 4 : 6} sm={6} xs={12} className="mt-2">
                    <Card className="LinkUnlinkCard" variant="outlined">
                      <CardActions
                        sx={{
                          display: "flex",
                          alignItems: "center", 
                          justifyContent: "flex-start", 
                        }}
                      >
                        <Checkbox
                          indeterminate={
                            this.state.unlinkedVendorsInput?.trim() === ""  
                            ? this.commonItems(this.state.checkedunlinkedVendors, this.state.unlinkedVendors)?.length > 0 &&
                              this.commonItems(this.state.checkedunlinkedVendors, this.state.unlinkedVendors)?.length <
                               this.state.unlinkedVendors?.length
                            : this.commonItems(this.state.checkedunlinkedVendors, this.state.searchedUnlinkedVendors)?.length > 0 && 
                              this.commonItems(this.state.checkedunlinkedVendors, this.state.searchedUnlinkedVendors)?.length < 
                              this.state.searchedUnlinkedVendors.length
                          }
                          checked={
                            this.state.unlinkedVendorsInput?.trim() === "" 
                            ? this.commonItems(this.state.checkedunlinkedVendors, this.state.unlinkedVendors)?.length > 0 &&
                              this.commonItems(this.state.checkedunlinkedVendors, this.state.unlinkedVendors)?.length ===
                                this.state.unlinkedVendors?.length
                            : this.commonItems(this.state.checkedunlinkedVendors, this.state.searchedUnlinkedVendors)?.length > 0 && 
                              this.commonItems(this.state.checkedunlinkedVendors, this.state.searchedUnlinkedVendors)?.length === 
                              this.state.searchedUnlinkedVendors.length
                          }
                          onChange={(e) => {
                            this.handleSelectAllClick(
                              e,
                              "checkedunlinkedVendors"
                            );
                          }}
                          disabled={this.state.showSelectedUnlinked}

                        />
                      
                          <Typography sx={{marginRight: "auto"}} variant="h6" component="div" className="LinkCardTitle">Unlinked Vendor</Typography>

                          <Tooltip title={this.state.checkedunlinkedVendors.length && "View Selected vendors"}>
                            <IconButton      
                              sx={{ ml: "auto", color: (theme) => this.state.showSelectedUnlinked ? theme.palette.primary.main : theme.palette.grey[600] }}
                              disabled={!this.state.checkedunlinkedVendors.length}
                              onClick={() => this.toggleShowSelectedUnlinked()}
                            >
                              <Badge badgeContent={this.state.checkedunlinkedVendors.length} color="error">
                              <ListAltOutlinedIcon 
                                //sx={{ color: (theme) => this.state.showSelectedUnlinked ? theme.palette.primary.main : theme.palette.grey[600] }}
                              />
                              </Badge>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={`Link Non Approved ${this.state.vendorApproval ? ", Approved" : ""} Vendor`}>
                          <IconButton
                            onClick={(e) => {
                              this.setState({
                                anchorEl1: e.currentTarget,
                              });
                            }}
                            sx={{ml: 'auto', color: (theme) => theme.palette.grey[600],}}
                            disabled={
                              this.state.unlinkedVendorsLoading
                              || this.state.searchedUnlinkedVendorsLoading
                              || this.state.approvedVendorsLoading
                              || this.state.searchedApprovedVendorsLoading
                              || this.state.nonapprovedVendorsLoading
                              || this.state.searchedNonapprovedVendorsLoading
                            }
                         >
                        {/* <CreateNewFolderOutlinedIcon /> */}
                          <LinkRounded />
                        </IconButton>
                        </Tooltip>
                      <Menu
                        anchorEl={this.state.anchorEl1}
                        keepMounted
                        open={Boolean(this.state.anchorEl1)}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        onClose={() => {
                          this.setState({
                            anchorEl1: null,
                          });
                        }}
                      >
                        {this.state.vendorApproval && <MenuItem
                          onClick={() => {
                            this.linkVendors(10);
                            this.setState({
                              anchorEl1: null,
                            });
                          }}
                          disabled={
                            this.state.unlinkedVendorsLoading
                            || this.state.searchedUnlinkedVendorsLoading
                            || this.state.approvedVendorsLoading
                            || this.state.searchedApprovedVendorsLoading
                            || this.state.nonapprovedVendorsLoading
                            || this.state.searchedNonapprovedVendorsLoading
                          }
                        >
                          Approved
                        </MenuItem>}
                        <MenuItem
                          onClick={() => {
                            this.linkVendors(20);
                            this.setState({
                              anchorEl1: null,
                            });
                          }}
                          disabled={
                            this.state.unlinkedVendorsLoading
                            || this.state.searchedUnlinkedVendorsLoading
                            || this.state.approvedVendorsLoading
                            || this.state.searchedApprovedVendorsLoading
                            || this.state.nonapprovedVendorsLoading
                            || this.state.searchedNonapprovedVendorsLoading
                          }
                        >
                          Non Approved
                        </MenuItem>
                      </Menu>
                      </CardActions>
                      <CardContent sx={{position:'relative', pb:'0 !important'}} id='unlinked-vendors'>
                      <FlatsearchInput
                            name="unlinkedVendorsInput"
                            placeholder="search"
                            value={this.state.unlinkedVendorsInput}
                            disabled={this.state.showSelectedUnlinked}
                            onChange={this.handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            endAdornment={
                              <React.Fragment>
                                {this.state.unlinkedVendorsInput !== "" ? (
                                  <IconButton className="search-clear-btn"
                                    onClick={() => {
                                      this.setState({
                                        unlinkedVendorsInput: "",
                                      });
                                    }}
                                    size="small">
                                    <ClearIcon />
                                  </IconButton>
                                ) : null}
                              </React.Fragment>
                            }
                          />
                          <div className="link-items-list">                          
                          {this.state.unlinkedVendorsInput.trim() === "" ? (
                          <VendorList
                            data={this.state.unlinkedVendors}
                            isItemSelected={this.isItemSelected}
                            handleItemClick={this.handleItemClick}
                            lastPage={this.state.unlinkedVendorsCurrentLastPage}
                            currentPage={this.state.unlinkedVendorsCurrentPage}
                            loading={this.state.unlinkedVendorsLoading}
                            type="checkedunlinkedVendors"
                            loadMore={() => {
                              this.setState(
                                {
                                  unlinkedVendorsCurrentPage:
                                    this.state.unlinkedVendorsCurrentPage + 1,
                                },
                                () => {
                                  this.getUnlinkedVendors();
                                }
                              );
                            }}
                          />
                        ) : (
                          <VendorList
                            searchValue = {this.state.unlinkedVendorsInput}
                            data={this.state.searchedUnlinkedVendors}
                            isItemSelected={this.isItemSelected}
                            handleItemClick={this.handleItemClick}
                            lastPage={
                              this.state.searchedUnlinkedVendorsCurrentLastPage
                            }
                            currentPage={
                              this.state.searchedUnlinkedVendorsCurrentPage
                            }
                            loading={this.state.searchedUnlinkedVendorsLoading}
                            type="checkedunlinkedVendors"
                            loadMore={() => {
                              this.setState(
                                {
                                  searchedUnlinkedVendorsCurrentPage:
                                    this.state
                                      .searchedUnlinkedVendorsCurrentPage + 1,
                                  prevUnlinkedSearch:
                                    this.state.unlinkedVendorsInput,
                                },
                                () => {
                                  this.searchUnlinkedVendors(
                                    this.state.unlinkedVendorsInput
                                  );
                                }
                              );
                            }}
                          />
                        )}
                        </div>
                        <Box className="LinkUnlink-footer">
                          <Typography component="p">
                              Showing <span>{this.state.unlinkedVendorsInput?.trim() === "" ? `${this.state.unlinkedVendors?.length} / ${this.state.unlinkedVendorsTotal}` : `${this.state.searchedUnlinkedVendors?.length} / ${this.state.searchedUnlinkedVendorsTotal}`}</span>
                          </Typography>
                        </Box>
                        <SelectedVendors 
                          toggleDrawer={this.toggleShowSelectedUnlinked}
                          open={this.state.showSelectedUnlinked}
                          data={this.state.checkedunlinkedVendors}
                          handleItemClick={this.handleItemClick}
                          type="checkedunlinkedVendors"
                          reference="unlinked-vendors"
                        />
                      </CardContent>                   
                      
                    </Card>
                    
                  </Grid>
                  {/* unlink vendor end */}

                  {/* Approved start */}
                  {this.state.vendorApproval && <Grid item lg={4} md={4} sm={6} xs={12} className="mt-2">
                    <Card className="LinkUnlinkCard" variant="outlined">
                      <CardActions
                        sx={{
                          display: "flex",
                          alignItems: "center", 
                          justifyContent: "flex-start", 
                        }}
                      >
                        <Checkbox
                          indeterminate={
                            this.state.approvedVendorsInput?.trim() === ""  
                            ? this.commonItems(this.state.checkedapprovedVendors, this.state.approvedVendors)?.length > 0 &&
                              this.commonItems(this.state.checkedapprovedVendors, this.state.approvedVendors)?.length <
                                this.state.approvedVendors?.length
                            : this.commonItems(this.state.checkedapprovedVendors, this.state.searchedApprovedVendors)?.length > 0 && 
                              this.commonItems(this.state.checkedapprovedVendors, this.state.searchedApprovedVendors)?.length < 
                              this.state.searchedApprovedVendors.length
                          }
                          checked={
                            this.state.approvedVendorsInput?.trim() === "" 
                            ? this.commonItems(this.state.checkedapprovedVendors, this.state.approvedVendors)?.length > 0 &&
                              this.commonItems(this.state.checkedapprovedVendors, this.state.approvedVendors)?.length ===
                                this.state.approvedVendors?.length
                            : this.commonItems(this.state.checkedapprovedVendors, this.state.searchedApprovedVendors)?.length > 0 && 
                              this.commonItems(this.state.checkedapprovedVendors, this.state.searchedApprovedVendors)?.length === 
                              this.state.searchedApprovedVendors.length
                          }
                          onChange={(e) => {
                            this.handleSelectAllClick(
                              e,
                              "checkedapprovedVendors"
                            );
                          }}
                          disabled={this.state.showSelectedApproved}

                        />
                          <Typography sx={{marginRight: "auto"}} variant="h6" component="div" className="LinkCardTitle">Approved Vendor</Typography>
                          <Tooltip title={this.state.checkedapprovedVendors.length && "View Selected vendors"}>
                            <IconButton                          
                              sx={{ ml: "auto", color: (theme) => this.state.showSelectedApproved ? theme.palette.primary.main : theme.palette.grey[600]  }}
                              disabled={!this.state.checkedapprovedVendors.length}
                              onClick={() => this.toggleShowSelectedApproved()}
                            >
                              <Badge badgeContent={this.state.checkedapprovedVendors.length} color="error">
                              <ListAltOutlinedIcon 
                               // sx={{ color: (theme) => this.state.showSelectedApproved ? theme.palette.primary.main : theme.palette.grey[600] }}
                              />
                              </Badge>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Unlink, Non Approve Vendor">
                          <IconButton
                            onClick={(e) => {
                              this.setState({
                                anchorEl2: e.currentTarget,
                              });
                            }}
                            sx={{ml: 'auto', color: (theme) => theme.palette.grey[600], }}
                            disabled={
                              this.state.unlinkedVendorsLoading
                              || this.state.searchedUnlinkedVendorsLoading
                              || this.state.approvedVendorsLoading
                              || this.state.searchedApprovedVendorsLoading
                              || this.state.nonapprovedVendorsLoading
                              || this.state.searchedNonapprovedVendorsLoading
                            }
                            >
                            {/* <CreateNewFolderOutlinedIcon /> */}
                            <LinkRounded />
                          </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={this.state.anchorEl2}
                            keepMounted
                            open={Boolean(this.state.anchorEl2)}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            onClose={() => {
                              this.setState({
                                anchorEl2: null,
                              });
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                this.unlinkVendors("approved");
                                this.setState({
                                  anchorEl2: null,
                                });
                              }}
                              disabled={
                                this.state.unlinkedVendorsLoading
                                || this.state.searchedUnlinkedVendorsLoading
                                || this.state.approvedVendorsLoading
                                || this.state.searchedApprovedVendorsLoading
                                || this.state.nonapprovedVendorsLoading
                                || this.state.searchedNonapprovedVendorsLoading
                              }
                            >
                              Unlink
                            </MenuItem>
                            <MenuItem
                            onClick={() => {
                              this.changeVendorsStatus("non-approved");
                              this.setState({
                                anchorEl2: null,
                              });
                            }}
                            disabled={
                              this.state.unlinkedVendorsLoading
                              || this.state.searchedUnlinkedVendorsLoading
                              || this.state.approvedVendorsLoading
                              || this.state.searchedApprovedVendorsLoading
                              || this.state.nonapprovedVendorsLoading
                              || this.state.searchedNonapprovedVendorsLoading
                            }
                            >
                              Non Approved
                            </MenuItem>
                          </Menu>
                      </CardActions>
                      <CardContent sx={{position:'relative', pb:'0 !important'}} id='approved-vendors'>
                      <FlatsearchInput
                            name="approvedVendorsInput"
                            placeholder="search"
                            value={this.state.approvedVendorsInput}
                            disabled={this.state.showSelectedApproved}
                            onChange={this.handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            endAdornment={
                              <React.Fragment>
                                {this.state.approvedVendorsInput !== "" ? (
                                  <IconButton className="search-clear-btn"
                                    onClick={() => {
                                      this.setState({
                                        approvedVendorsInput: "",
                                      });
                                    }}
                                    size="small">
                                    <ClearIcon />
                                  </IconButton>
                                ) : null}
                              </React.Fragment>
                            }
                          />

                        <div className="link-items-list">
                        {this.state.approvedVendorsInput.trim() === "" ? (
                          <VendorList
                            data={this.state.approvedVendors}
                            isItemSelected={this.isItemSelected}
                            handleItemClick={this.handleItemClick}
                            lastPage={this.state.approvedVendorsCurrentLastPage}
                            currentPage={this.state.approvedVendorsCurrentPage}
                            loading={this.state.approvedVendorsLoading}
                            type="checkedapprovedVendors"
                            loadMore={() => {
                              this.setState(
                                {
                                  approvedVendorsCurrentPage:
                                    this.state.approvedVendorsCurrentPage + 1,
                                },
                                () => {
                                  this.getApprovedVendors();
                                }
                              );
                            }}
                          />
                        ) : (
                          <VendorList
                            searchValue = {this.state.approvedVendorsInput}
                            data={this.state.searchedApprovedVendors}
                            isItemSelected={this.isItemSelected}
                            handleItemClick={this.handleItemClick}
                            lastPage={
                              this.state.searchedApprovedVendorsCurrentLastPage
                            }
                            currentPage={
                              this.state.searchedApprovedVendorsCurrentPage
                            }
                            loading={this.state.searchedApprovedVendorsLoading}
                            type="checkedapprovedVendors"
                            loadMore={() => {
                              this.setState(
                                {
                                  searchedApprovedVendorsCurrentPage:
                                    this.state
                                      .searchedApprovedVendorsCurrentPage + 1,
                                  prevApprovedSearch:
                                    this.state.approvedVendorsInput,
                                },
                                () => {
                                  this.searchApprovedVendors(
                                    this.state.approvedVendorsInput
                                  );
                                }
                              );
                            }}
                          />
                        )}
                      </div>
                      <Box className="LinkUnlink-footer">
                      <Typography component="p">
                          Showing <span>{this.state.approvedVendorsInput?.trim() === "" ? `${this.state.approvedVendors?.length} / ${this.state.approvedVendorsTotal}` : `${this.state.searchedApprovedVendors?.length} / ${this.state.searchedApprovedVendorsTotal}`}</span>
                      </Typography>
                      </Box>
                      <SelectedVendors 
                        toggleDrawer={this.toggleShowSelectedApproved}
                        open={this.state.showSelectedApproved}
                        data={this.state.checkedapprovedVendors}
                        handleItemClick={this.handleItemClick}
                        type="checkedapprovedVendors"
                        reference="approved-vendors"
                      />
                      </CardContent>
                    </Card>  
                  
                  </Grid>}
                  {/* nonapproved start */}
                  <Grid item md={this.state.vendorApproval ? 4 : 6} sm={12} xs={12} className="">
                    <Card className="LinkUnlinkCard" variant="outlined">
                    <CardActions
                      sx={{
                        display: "flex",
                        alignItems: "center", 
                        justifyContent: "flex-start", 
                      }}
                    >
                      <Checkbox
                        indeterminate={
                          this.state.nonapprovedVendorsInput?.trim() === ""  
                          ? this.commonItems(this.state.checkednonapprovedVendors, this.state.nonapprovedVendors)?.length > 0 &&
                            this.commonItems(this.state.checkednonapprovedVendors, this.state.nonapprovedVendors)?.length <
                              this.state.nonapprovedVendors?.length
                          : this.commonItems(this.state.checkednonapprovedVendors, this.state.searchedNonapprovedVendors)?.length > 0 && 
                            this.commonItems(this.state.checkednonapprovedVendors, this.state.searchedNonapprovedVendors)?.length < 
                            this.state.searchedNonapprovedVendors.length
                        }
                        checked={
                          this.state.nonapprovedVendorsInput?.trim() === "" 
                          ? this.commonItems(this.state.checkednonapprovedVendors, this.state.nonapprovedVendors)?.length > 0 &&
                            this.commonItems(this.state.checkednonapprovedVendors, this.state.nonapprovedVendors)?.length ===
                              this.state.nonapprovedVendors?.length
                          : this.commonItems(this.state.checkednonapprovedVendors, this.state.searchedNonapprovedVendors)?.length > 0 && 
                            this.commonItems(this.state.checkednonapprovedVendors, this.state.searchedNonapprovedVendors)?.length === 
                            this.state.searchedNonapprovedVendors.length
                        }
                        onChange={(e) => {
                          this.handleSelectAllClick(
                            e,
                            "checkednonapprovedVendors"
                          );
                        }}
                        disabled={this.state.showSelectedNonapproved}

                      />

                        <Typography sx={{marginRight: "auto"}} variant="h6" component="div" className="LinkCardTitle">Non-Approved Vendor</Typography>
                        <Tooltip title={this.state.checkednonapprovedVendors.length && "View Selected vendors"}>
                          <IconButton                          
                            sx={{ ml: "auto", color: (theme) => this.state.showSelectedNonapproved ? theme.palette.primary.main : theme.palette.grey[600] }}
                            disabled={!this.state.checkednonapprovedVendors.length}
                            onClick={() => this.toggleShowSelectedNonapproved()}
                          >
                            <Badge badgeContent={this.state.checkednonapprovedVendors.length} color="error">
                            <ListAltOutlinedIcon 
                             // sx={{ color: (theme) => this.state.showSelectedNonapproved ? theme.palette.primary.main : theme.palette.grey[600] }}
                            />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={`Unlink${this.state.vendorApproval ? ", Approve" : ""} Vendor`}>
                        <IconButton
                            onClick={(e) => {
                              this.setState({
                                anchorEl3: e.currentTarget,
                              });
                            }}
                            sx={{ml: 'auto', color: (theme) => theme.palette.grey[600],}}
                            disabled={
                              this.state.unlinkedVendorsLoading
                              || this.state.searchedUnlinkedVendorsLoading
                              || this.state.approvedVendorsLoading
                              || this.state.searchedApprovedVendorsLoading
                              || this.state.nonapprovedVendorsLoading
                              || this.state.searchedNonapprovedVendorsLoading
                            }
                            >
                            {/* <CreateNewFolderOutlinedIcon /> */}
                            <LinkRounded />
                          </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={this.state.anchorEl3}
                            keepMounted
                            open={Boolean(this.state.anchorEl3)}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            onClose={() => {
                              this.setState({
                                anchorEl3: null,
                              });
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                this.unlinkVendors("nonapproved");
                                this.setState({
                                  anchorEl3: null,
                                });
                              }}
                              disabled={
                                this.state.unlinkedVendorsLoading
                                || this.state.searchedUnlinkedVendorsLoading
                                || this.state.approvedVendorsLoading
                                || this.state.searchedApprovedVendorsLoading
                                || this.state.nonapprovedVendorsLoading
                                || this.state.searchedNonapprovedVendorsLoading
                              }
                            >
                              Unlink
                            </MenuItem>
                            {this.state.vendorApproval && <MenuItem
                            onClick={() => {
                              this.changeVendorsStatus("approved");
                              this.setState({
                                anchorEl3: null,
                              });
                            }}
                            disabled={
                              this.state.unlinkedVendorsLoading
                              || this.state.searchedUnlinkedVendorsLoading
                              || this.state.approvedVendorsLoading
                              || this.state.searchedApprovedVendorsLoading
                              || this.state.nonapprovedVendorsLoading
                              || this.state.searchedNonapprovedVendorsLoading
                            }
                            >
                              Approved
                            </MenuItem>}
                          </Menu>
                      </CardActions>
                      <CardContent sx={{position:'relative', pb:'0 !important'}} id='nonapproved-vendors'>
                      <FlatsearchInput
                            name="nonapprovedVendorsInput"
                            placeholder="search"
                            value={this.state.nonapprovedVendorsInput}
                            disabled={this.state.showSelectedNonapproved}
                            onChange={this.handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            endAdornment={
                              <React.Fragment>
                                {this.state.nonapprovedVendorsInput !== "" ? (
                                  <IconButton className="search-clear-btn"
                                    onClick={() => {
                                      this.setState({
                                        nonapprovedVendorsInput: "",
                                      });
                                    }}
                                    size="small">
                                    <ClearIcon />
                                  </IconButton>
                                ) : null}
                              </React.Fragment>
                            }
                          />
                        <div className="link-items-list">
                        {this.state.nonapprovedVendorsInput.trim() === "" ? (
                          <VendorList
                            data={this.state.nonapprovedVendors}
                            isItemSelected={this.isItemSelected}
                            handleItemClick={this.handleItemClick}
                            lastPage={
                              this.state.nonapprovedVendorsCurrentLastPage
                            }
                            currentPage={
                              this.state.nonapprovedVendorsCurrentPage
                            }
                            loading={this.state.nonapprovedVendorsLoading}
                            type="checkednonapprovedVendors"
                            loadMore={() => {
                              this.setState(
                                {
                                  nonapprovedVendorsCurrentPage:
                                    this.state.nonapprovedVendorsCurrentPage +
                                    1,
                                },
                                () => {
                                  this.getNonapprovedVendors();
                                }
                              );
                            }}
                          />
                        ) : (
                          <VendorList
                            searchValue = {this.state.nonapprovedVendorsInput}
                            data={this.state.searchedNonapprovedVendors}
                            isItemSelected={this.isItemSelected}
                            handleItemClick={this.handleItemClick}
                            lastPage={
                              this.state
                                .searchedNonapprovedVendorsCurrentLastPage
                            }
                            currentPage={
                              this.state.searchedNonapprovedVendorsCurrentPage
                            }
                            loading={
                              this.state.searchedNonapprovedVendorsLoading
                            }
                            type="checkednonapprovedVendors"
                            loadMore={() => {
                              this.setState(
                                {
                                  searchedNonapprovedVendorsCurrentPage:
                                    this.state
                                      .searchedNonapprovedVendorsCurrentPage +
                                    1,
                                  prevNonapprovedSearch:
                                    this.state.nonapprovedVendorsInput,
                                },
                                () => {
                                  this.searchNonapprovedVendors(
                                    this.state.nonapprovedVendorsInput
                                  );
                                }
                              );
                            }}
                          />
                        )}
                      </div>
                      <Box className="LinkUnlink-footer">
                      <Typography component="p">
                          Showing <span>{this.state.nonapprovedVendorsInput?.trim() === "" ? `${this.state.nonapprovedVendors?.length} / ${this.state.nonapprovedVendorsTotal}` : `${this.state.searchedNonapprovedVendors?.length} / ${this.state.searchedNonapprovedVendorsTotal}`}</span>
                      </Typography>
                      </Box>
                      <SelectedVendors 
                        toggleDrawer={this.toggleShowSelectedNonapproved}
                        open={this.state.showSelectedNonapproved}
                        data={this.state.checkednonapprovedVendors}
                        handleItemClick={this.handleItemClick}
                        type="checkednonapprovedVendors"
                        reference="nonapproved-vendors"
                      />
                      </CardContent>
                    </Card>
                  
                  </Grid>
                </Grid>
              </div>
            
          </DialogContent>
        </LinkItemDialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  sessionId: state.sessionReducer.sessionId,
  selectedCategory: state.categoryReducer.selectedCategory,
  masterPermission: state.permissionsReducer.masterPermission,
});
export default connect(mapStateToProps, {
  setSelectedVendors,
  openSnackbar,
})(LinkVendorToCategory);
