import React, { Component, Fragment } from "react";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Menu,
  MenuItem,
  Dialog,
  Typography,
  CardActions,
  Avatar,
  Tooltip,
  Badge,
  Drawer,
  Backdrop,
  Select,
  Divider,
  ListItemButton,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { setSelectedVendors } from "../../../redux/actions/vendorActions";
import { setSelectedItem } from "../../../redux/actions/itemActions";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import { Card } from "@mui/material";
import { FlatsearchInput } from "../../TextField";
import SearchIcon from "@mui/icons-material/Search";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import noSearchimg from "../../../assets/images/no-search.svg";
import axios from "axios";
import {
  changeItemVendorStatus,
  getAllLinkedItems,
  getAllLinkedVendors,
  getFilteredVendors,
  getLinkedItemizedVendors,
  linkVendorsToItem,
  searchCategoryVendorsByNameOrCode,
  _searchItemizedVendorsByNameOrCode,
  searchLinkedItem,
  getItemsByVendorSearch,
  unlinkItemVendors,
  linkUnlinkItem,
} from "../../../config/apiUrl";
import { debounce } from "lodash";
import { Box } from "@mui/system";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import VendorItemSkeleton from "../../Loaders/VendorItemSkeleton";
import { LinkRounded } from "@mui/icons-material";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import _ from "lodash";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { getFilterOperators } from "../../Utils/datagridHelper";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { getVendorGMPFlag } from "../../Vendors/VendorChip";

class LinkVendorsToItem extends Component {
  state = {
    anchorEl: null,
    items: [],
    checkedItems: [],
    loading: false,
    paginationLoading: false,
    itemsCurrentPage: 1,
    itemsLastPage: 1,
    itemsTotal: 0,
    itemSearchInput: "",
    prevItemSearch: "",
    itemSearchReult: [],
    checkedSearchedItems: [],
    itemSearchCurrentPage: 1,
    itemSearchLastPage: 1,
    searchedItemsTotal: 0,
    categoryVendors: [],
    categoryVendorsLoading: false,
    categoryVendorsLastPage: 1,
    categoryVendorsCurrentPage: 1,
    categoryVendorsTotal: 0,
    categoryVednorSearchInput: "",
    prevCategoryVednorSearchInput: "",
    categoryVendorSearchResult: [],
    categoryVendorSearchCurrentPage: 1,
    categoryVendorSearchLastPage: 1,
    categoryVendorSearchTotal: 0,
    selectionModel: [],
    showSelected: false,
    mainSelectionModel: [],
    showSelectedItems: false,
    mainLoading: false,
    itemsWithSelectedVendors: [],

    filteredTotal: 0,
    filteredRows: [],
    isFilterActive: false,
    filterDataLastPage: 1,
    filterDataCurrentPage: 1,
    filterModel: {
      items: [],
    },

    vendor_name: "",
    vendor_name_operator: "",
    vendor_code: "",
    vendor_code_operator: "",
    industry_type: "",
    industry_type_operator: "",
    country: "",
    country_operator: "",
    location: "",
    location_operator: "",
    State: "",
    state_operator: "",

    itemSearchType: "vendor",
    showAllItems: false,
  };
  componentDidMount() {
    this.fetchItems(1);
    this.fetchVendor(1);
    this.setState({
      checkedItems: this.props.cartCheckedItems,
    });
    if (!this.props.selectedItems?.length) {
      this.setState({
        showAllItems: true,
      });
    }
  }
  componentDidUpdate() {
    if (this.state.showSelectedItems && !this.state.checkedItems.length) {
      this.setState({
        showSelectedItems: false,
      });
    }
    if (this.state.showSelected && !this.state.mainSelectionModel.length) {
      this.setState({
        showSelected: false,
      });
    }
  }
  getItemVendors = (item_id, page) => {
    this.setState({
      [page === 1
        ? `${item_id}_loading`
        : `${item_id}_paginationsLoading`]: true,
    });
    // this.setState({
    //   loading: true,
    // });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    let url;

    if (
      this.state.itemSearchType === "vendor" &&
      this.state.itemSearchInput.trim() !== ""
    ) {
      // url = url + `&vendor_name[like]=%25${encodeURIComponent(this.state.itemSearchInput)}%25`
      url = _searchItemizedVendorsByNameOrCode(
        item_id,
        this.props.selectedCategory?.id,
        this.state.itemSearchInput,
        page
      );
    } else {
      let plant = undefined;

      if (
        Object.keys(this.props.selectedPrCategory).length &&
        this.props.accountModulePermission[
          "Plant Specific Categroy Item Vendor Linking"
        ].module_activated === "Y"
      ) {
        plant = [];
        let prlineitems = this.props.prlineitems;
        prlineitems[item_id]?.forEach((lineItemData) => {
          if (
            this.props.selectedCategory?.old_id ===
            lineItemData?.category?.category_old_id
          ) {
              if(!plant.includes(lineItemData.plant)){
                plant.push(lineItemData.plant);
              }
            }
        });
        plant = plant.join(",");
      }
      url = getLinkedItemizedVendors(
        item_id,
        this.props.selectedCategory?.id,
        page,
        plant
      );
    }
    axios
      .get(url)
      .then((res) => {
        if (page === 1) {
          this.setState({
            // items: res.data,
            [`${item_id}_loading`]: false,
            [`${item_id}_vendors`]: res.data,
            [`${item_id}_checkedVendors`]:
              this.state[`${item_id}_checkedVendors`] || [],
          });
        } else {
          this.setState((prevState) => ({
            ...prevState,
            [`${item_id}_vendors`]: {
              ...prevState[`${item_id}_vendors`],
              data: [...prevState[`${item_id}_vendors`].data, ...res.data.data],
              meta: res.data.meta,
            },
          }));

          // this.state[`${item_id}_vendors`].data = [
          //   ...this.state[`${item_id}_vendors`].data,
          //   ...res.data.data,
          // ];

          // this.state[`${item_id}_vendors`].meta = res.data.meta;

          this.setState({
            // items: res.data,
            [`${item_id}_paginationsLoading`]: false,
            // [`${item_id}_vendors`]: this.state[`${item_id}_vendors`],
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          [`${item_id}_loading`]: false,
          [`${item_id}_paginationsLoading`]: false,
        });
      });
  };
  handleClick(item) {
    this.setState((prevState) => ({ [item]: !prevState[item] }));
    //for collpasing others
    // this.state.items.forEach((element) => {
    //   if (element.id === item) return;
    //   this.setState({ [element.id]: false });
    // });
  }

  changeVendorStatus = (index, vendor_id, item_id, current_status) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = changeItemVendorStatus(
      this.props.selectedCategory?.id,
      item_id,
      vendor_id
    );
    axios
      .put(url, {
        link_as: current_status ? "non-approved" : "approved",
      })
      .then((res) => {
        let newVendorsArray = this.state[`${item_id}_vendors`];
        if (current_status) {
          newVendorsArray.data[index]["is_approved"] = "non-approved";
        } else {
          newVendorsArray.data[index]["is_approved"] = "approved";
        }
        this.setState({
          [`${item_id}_vendors`]: newVendorsArray,
        });
        this.props.openSnackbar(
          "success",
          res.data.message ? res.data.message : "Status changes successful",
          true
        );
      })
      .catch((err) => {
        this.props.openSnackbar("error", "something went wrong!");
        console.log(err);
      });
  };
  // if the menu item doesn't have any child, this method simply returns a clickable menu item that redirects to any location and if there is no child this method uses recursion to go until the last level of children and then returns the item by the first condition.
  handler(children, type) {
    const { state } = this;
    return children && children.length > 0
      ? children.map((subOption) => {
          const ItemSelected = this.isItemSelected(subOption, type);

          return (
            <React.Fragment>
              <div key={subOption.id}>
                <ListItem sx={{ padding: "8px" }}>
                  <Checkbox
                    checked={ItemSelected}
                    onChange={(event) =>
                      this.handleItemClick(event, subOption, type)
                    }
                  />
                  <ListItemText
                    inset
                    title={_.unescape(subOption.name)}
                    primary={_.unescape(subOption.name)}
                    secondary={_.unescape(subOption.code)}
                    primaryTypographyProps={{
                      variant: "p",
                      noWrap: true,
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                    secondaryTypographyProps={{
                      variant: "span",
                      color: (theme) => theme.palette.grey[500],
                      fontSize: "12px",
                    }}
                    sx={{
                      paddingLeft: 0,
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      this.handleClick(subOption.id);
                      if (!state[subOption.id]) {
                        // if (state[`${subOption.id}_vendors`]) {
                        //   return;
                        // }
                        this.getItemVendors(subOption.id, 1);
                      }
                    }}>
                    {state[subOption.id] ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItem>
                <Collapse
                  in={state[subOption.id]}
                  timeout="auto"
                  unmountOnExit
                  sx={{ backgroundColor: "grey.50" }}>
                  {this.state[`${subOption.id}_loading`] ? (
                    <VendorItemSkeleton />
                  ) : this.state[`${subOption.id}_vendors`] &&
                    this.state[`${subOption.id}_vendors`]?.data?.length > 0 ? (
                    <React.Fragment>
                      <List component="div" disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <Checkbox
                              indeterminate={
                                this.state[`${subOption.id}_checkedVendors`]
                                  .length > 0 &&
                                this.state[`${subOption.id}_checkedVendors`]
                                  .length <
                                  this.state[`${subOption.id}_vendors`].data
                                    .length
                              }
                              checked={
                                this.state[`${subOption.id}_vendors`].data
                                  .length > 0 &&
                                this.state[`${subOption.id}_checkedVendors`]
                                  .length ===
                                  this.state[`${subOption.id}_vendors`].data
                                    .length
                              }
                              onChange={(e) => {
                                this.handleSelectAllClick(
                                  e,
                                  `${subOption.id}_checkedVendors`,
                                  subOption.id,
                                  true
                                );
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Select All Vendor"}
                            primaryTypographyProps={{
                              variant: "p",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          />
                        </ListItemButton>
                      </List>
                      {this.state[`${subOption.id}_vendors`].data.map(
                        (vendor, index) => {
                          const isVendorSelected = this.isItemSelected(
                            vendor,
                            `${subOption.id}_checkedVendors`
                          );

                          return (
                            <List
                              component="div"
                              disablePadding
                              key={vendor.vendor_code}>
                              <ListItem>
                                <ListItemIcon>
                                  <Checkbox
                                    checked={isVendorSelected}
                                    onChange={(event) =>
                                      this.handleItemClick(
                                        event,
                                        vendor,
                                        `${subOption.id}_checkedVendors`,
                                        subOption.id,
                                        true
                                      )
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={vendor.vendor_name}
                                  secondary={vendor.vendor_code}
                                  primaryTypographyProps={{
                                    variant: "p",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                  }}
                                  secondaryTypographyProps={{
                                    variant: "span",
                                    color: (theme) => theme.palette.grey[500],
                                    fontSize: "12px",
                                  }}
                                />
                                {this.props.masterPermission
                                  ?.vendor_approval === "Y" && (
                                  <ListItemSecondaryAction>
                                    <Tooltip
                                      title={
                                        vendor.is_approved === "approved"
                                          ? "Approved"
                                          : "Non-Approved"
                                      }>
                                      <IconButton
                                        size="large"
                                        onClick={() => {
                                          this.changeVendorStatus(
                                            index,
                                            vendor.id,
                                            subOption.id,
                                            vendor.is_approved === "approved"
                                          );
                                        }}>
                                        {vendor.is_approved === "approved" ? (
                                          <PersonOutlineIcon color="success" />
                                        ) : (
                                          <PersonOffIcon />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                  </ListItemSecondaryAction>
                                )}
                              </ListItem>
                            </List>
                          );
                        }
                      )}
                      {this.state[`${subOption.id}_vendors`].meta.last_page !==
                        this.state[`${subOption.id}_vendors`].meta
                          .current_page && (
                        <List component="div" disablePadding>
                          <ListItemButton
                            disabled={
                              this.state[`${subOption.id}_paginationsLoading`]
                            }
                            onClick={() => {
                              this.getItemVendors(
                                subOption.id,
                                this.state[`${subOption.id}_vendors`].meta
                                  .current_page + 1
                              );
                            }}>
                            {this.state[
                              `${subOption.id}_paginationsLoading`
                            ] ? (
                              <CircularProgress />
                            ) : (
                              <ListItemText primary={"Load more"} />
                            )}
                          </ListItemButton>
                        </List>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Box
                        className="no-search"
                        sx={{ textAlign: "center", padding: "15px" }}>
                        <img
                          src={noSearchimg}
                          width={"100px"}
                          height={"auto"}
                        />
                        <Typography component="p">
                          There are no vendors
                        </Typography>
                      </Box>
                    </React.Fragment>
                  )}
                </Collapse>
              </div>
            </React.Fragment>
          );
        })
      : !this.state.loading && (
          <React.Fragment>
            <Box className="no-search">
              <img src={noSearchimg} />
              <Typography component="p">
                {this.state.itemSearchInput.trim() !== "" ? (
                  <React.Fragment>
                    There are no{" "}
                    {this.state.itemSearchType === "item" ? "Item" : "Vendor"}
                    (s) like <b>{this.state.itemSearchInput}</b>
                  </React.Fragment>
                ) : (
                  "There are no Item(s)"
                )}
              </Typography>
            </Box>
          </React.Fragment>
        );
  }

  selectedItems() {
    const { checkedItems } = this.state;
    return (
      <Box className="LinkUnlinkCard">
        <Box
          className="link-items-list selected-list"
          sx={{
            height: "250px",
            minHeight: "180px",
            overflowY: "auto",
            overflowX: "hidden",
            position: "relative",
          }}>
          <List sx={{ width: "100%" }}>
            {checkedItems &&
              checkedItems.map((subOption) => {
                return (
                  <React.Fragment>
                    <div key={subOption.id}>
                      <ListItem sx={{ padding: "8px" }}>
                        <ListItemIcon>
                          <IconButton
                            color="error"
                            className="selected-remove-btn"
                            onClick={(event) =>
                              this.handleItemClick(
                                event,
                                subOption,
                                "checkedItems"
                              )
                            }>
                            <RemoveCircleIcon />
                          </IconButton>
                        </ListItemIcon>
                        {/* <Checkbox
                  checked={ItemSelected}
                  onChange={(event) =>
                    this.handleItemClick(event, subOption, type)
                  }
                /> */}
                        <ListItemText
                          inset
                          title={_.unescape(subOption.name)}
                          primary={_.unescape(subOption.name)}
                          secondary={_.unescape(subOption.code)}
                          primaryTypographyProps={{
                            variant: "p",
                            noWrap: true,
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          secondaryTypographyProps={{
                            variant: "span",
                            color: (theme) => theme.palette.grey[500],
                            fontSize: "12px",
                          }}
                          sx={{
                            paddingLeft: 0,
                          }}
                        />
                      </ListItem>
                    </div>
                  </React.Fragment>
                );
              })}
          </List>
        </Box>
      </Box>
    );
  }

  fetchItems = (page) => {
    var url;
    this.setState({
      [page === 1 ? "loading" : "paginationLoading"]: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    if (this.state.showAllItems == true) {
      url = getAllLinkedItems(this.props.selectedCategory?.id, page);
    } else {
      url =
        `${getAllLinkedItems(this.props.selectedCategory?.id, page)}` +
        "&pagination=false";
      this.props.selectedItems
        .map((item) => item.id)
        .map((id) => {
          url += `&id[]=${id}`;
        });
    }
    axios
      .get(url)
      .then((res) => {
        let finalData;
        if (page === 1) {
          finalData = res.data.data;
        } else {
          finalData = [...this.state.items, ...res.data.data];
        }
        this.setState({
          items: finalData,
          itemsCurrentPage: res.data.meta.current_page,
          itemsLastPage: res.data.meta.last_page,
          itemsTotal: res.data.meta.total,
          [page === 1 ? "loading" : "paginationLoading"]: false,
        });
      })
      .catch((err) => {
        this.props.openSnackbar("error", err.response.data.message);
        console.log(err);
        this.setState({
          [page === 1 ? "loading" : "paginationLoading"]: false,
        });
      });
  };
  fetchVendor = (page) => {
    this.setState({
      categoryVendorsLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getAllLinkedVendors(
      this.props.selectedCategory?.id,
      page,
      true
    );
    axios
      .get(url)
      .then((res) => {
        let finalData;
        if (page === 1) {
          finalData = res.data.data;
        } else {
          finalData = [...this.state.categoryVendors, ...res.data.data];
        }
        this.setState({
          categoryVendors: finalData,
          categoryVendorsCurrentPage: res.data.meta.current_page,
          categoryVendorsLastPage: res.data.meta.last_page,
          categoryVendorsTotal: res.data.meta.total,
          categoryVendorsLoading: false,
        });
      })
      .catch((err) => {
        this.props.openSnackbar("error", err.response.data.message);
        console.log(err);
        this.setState({
          categoryVendorsLoading: false,
        });
      });
  };

  fetchFilterVendors = (page) => {
    let url = getFilteredVendors(
      this.props.selectedCategory?.id,
      this.state.vendor_name.trim(),
      this.state.vendor_name_operator,
      this.state.vendor_code.trim(),
      this.state.vendor_code_operator,
      this.state.country.trim(),
      this.state.country_operator,
      this.state.industry_type.trim(),
      this.state.industry_type_operator,
      page
    );

    url =
      url +
      `${
        this.state.State &&
        `&${
          this.state.state_operator === "contains"
            ? `state[like]=%25${encodeURIComponent(this.state.State)}%25`
            : this.state.state_operator === "equals"
            ? `state=${encodeURIComponent(this.state.State)}`
            : this.state.state_operator === "startsWith"
            ? `state[like]=${encodeURIComponent(this.state.State)}%25`
            : this.state.state_operator === "endsWith"
            ? `state[like]=%25${encodeURIComponent(this.state.State)}`
            : `state[like]=%25${encodeURIComponent(this.state.State)}%25`
        }`
      }${
        this.state.location &&
        `&${
          this.state.location_operator === "is"
            ? `locality=${encodeURIComponent(this.state.location)}`
            : this.state.location_operator === "not"
            ? `locality=${encodeURIComponent(
                this.state.location === "local" ? "foreign" : "local"
              )}`
            : ""
        }`
      }`;

    this.setState({
      categoryVendorsLoading: true,
    });
    axios
      .get(url)
      .then((res) => {
        if (page === 1) {
          this.setState({
            categoryVendorsLoading: false,
            filteredRows: res.data.data,
            filterDataCurrentPage: res.data.meta.current_page,
            filterDataLastPage: res.data.meta.last_page,
            filteredTotal: res.data.meta.total,
          });
        } else {
          let finalRows = [...this.state.filteredRows, ...res.data.data];
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          this.setState({
            categoryVendorsLoading: false,
            filteredRows: uniqueRows,
            filterDataCurrentPage: res.data.meta.current_page,
            filterDataLastPage: res.data.meta.last_page,
          });
        }
      })
      .catch((err) => {
        this.props.openSnackbar("error", err.response.data.message);
        this.setState({
          categoryVendorsLoading: false,
        });
        console.log("fetch vendors err", err);
      });
  };

  handleScrollEnd = () => {
    if (this.state.isFilterActive) {
      if (this.state.filterDataCurrentPage !== this.state.filterDataLastPage) {
        this.setState(
          {
            filterDataCurrentPage: this.state.filterDataCurrentPage + 1,
          },
          () => {
            this.fetchFilterVendors(this.state.filterDataCurrentPage);
          }
        );
      }
    } else {
      if (this.state.categoryVednorSearchInput.trim() === "") {
        if (
          this.state.categoryVendorsLastPage !==
          this.state.categoryVendorsCurrentPage
        ) {
          this.setState(
            {
              categoryVendorsCurrentPage:
                this.state.categoryVendorsCurrentPage + 1,
            },
            () => {
              this.fetchVendor(this.state.categoryVendorsCurrentPage);
            }
          );
        }
      } else {
        if (
          this.state.categoryVendorSearchLastPage !==
          this.state.categoryVendorSearchCurrentPage
        ) {
          this.setState(
            {
              categoryVendorSearchCurrentPage:
                this.state.categoryVendorSearchCurrentPage + 1,
              prevCategoryVednorSearchInput:
                this.state.categoryVednorSearchInput,
            },
            () => {
              this.searchCategoryVendors(this.state.categoryVednorSearchInput);
            }
          );
        }
      }
    }
  };

  handleFilterModelChange = (filterModel) => {
    let vendor_name = "";
    let vendor_name_operator = "";
    let vendor_code = "";
    let vendor_code_operator = "";
    let country = "";
    let country_operator = "";
    let industry_type = "";
    let industry_type_operator = "";
    let location = "";
    let location_operator = "";
    let State = "";
    let state_operator = "";

    this.setState({
      filterModel: filterModel,
    });
    filterModel.items.forEach((filter) => {
      if (filter.value) {
        if (filter.field === "vendor_name") {
          vendor_name = filter.value;
          vendor_name_operator = filter.operator;
        } else if (filter.field === "vendor_code") {
          vendor_code = filter.value;
          vendor_code_operator = filter.operator;
        } else if (filter.field === "country") {
          country = filter.value;
          country_operator = filter.operator;
        } else if (filter.field === "industry_type") {
          industry_type = filter.value;
          industry_type_operator = filter.operator;
        } else if (filter.field === "state") {
          State = filter.value;
          state_operator = filter.operator;
        } else if (filter.field === "location") {
          location = filter.value;
          location_operator = filter.operator;
        }
      }
    });
    //api call
    if (
      vendor_name ||
      vendor_code ||
      country ||
      industry_type ||
      State ||
      location
    ) {
      this.setState(
        {
          isFilterActive: true,
          vendor_name,
          vendor_name_operator,
          vendor_code,
          vendor_code_operator,
          country,
          country_operator,
          industry_type,
          industry_type_operator,
          location,
          location_operator,
          State,
          state_operator,
          filterDataCurrentPage: 1,
          filteredRows: [],
          filterDataLastPage: 1,
          filteredTotal: 0,
          categoryVednorSearchInput: "",
          selectionModel: [],
        },
        () => {
          this.fetchFilterVendors(1);
        }
      );
    } else {
      this.setState({
        isFilterActive: false,
        vendor_name: "",
        vendor_code: "",
        country: "",
        industry_type: "",
        location: "",
        State: "",
        filterDataCurrentPage: 1,
        filteredRows: [],
        filterDataLastPage: 1,
      });
    }
  };

  collpaseAllItems = () => {
    this.state.items.forEach((element) => {
      // if (element.id === item) return;
      this.setState({ [element.id]: false });
    });
  };
  handleChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({
      prevItemSearch: this.state.itemSearchInput,
      itemSearchInput: value,
    });

    // for collpasing all items
    this.collpaseAllItems();
    if (value.trim() !== "") {
      this.setState({
        loading: true,
        // resetcurernt search result data
        itemSearchCurrentPage: 1,
        itemSearchLastPage: 1,
        itemSearchReult: [],
        checkedSearchedItems: [],
        isFilterActive: false,
        filterModel: {
          items: [],
        },
      });
      this.getSearchResult(value, name);
    }
  };

  requestSearch = (searchValue) => {
    this.setState({
      // categoryVendorsLoading: true,
      prevCategoryVednorSearchInput: this.state.categoryVednorSearchInput,
      categoryVednorSearchInput: searchValue,
    });

    if (searchValue.trim() !== "") {
      this.setState(
        {
          categoryVendorsLoading: true,
          // resetcurernt search result data
          categoryVendorSearchCurrentPage: 1,
          categoryVendorSearchLastPage: 1,
          categoryVendorSearchResult: [],
        },
        () => {
          this.getSearchResult(searchValue, "categoryVednorSearchInput");
        }
      );
    }
    //api call
  };

  getSearchResult = debounce((searchValue, name) => {
    if (name === "categoryVednorSearchInput") {
      this.searchCategoryVendors(searchValue);
    } else {
      this.searchItems(searchValue);
    }
  }, 1000);

  searchCategoryVendors = (searchValue) => {
    this.setState({
      categoryVendorsLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    // const url = getFilteredVendors(
    //   this.props.selectedCategory?.id,
    //   searchValue,
    //   null,
    //   null,
    //   null,
    //   this.state.categoryVendorSearchCurrentPage
    // );
    const url = searchCategoryVendorsByNameOrCode(
      this.props.selectedCategory?.id,
      searchValue.trim(),
      this.state.categoryVendorSearchCurrentPage
    );

    axios
      .get(url)
      .then((response) => {
        let final;
        // append or not respective to search is changes or not
        if (this.state.prevCategoryVednorSearchInput.trim() === searchValue) {
          final = [
            ...this.state.categoryVendorSearchResult,
            ...response.data.data,
          ];
        } else {
          final = response.data.data;
        }

        let jsonObject = final.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueRows = Array.from(uniqueSet).map(JSON.parse);

        this.setState({
          categoryVendorSearchResult: uniqueRows,
          categoryVendorSearchLastPage: response.data.meta?.last_page,
          categoryVendorSearchCurrentPage: response.data.meta?.current_page,
          categoryVendorSearchTotal: response.data.meta?.total,
          // searchResultTotal: response.data.meta?.total,
          categoryVendorsLoading: false,
        });
      })
      .catch((er) => {
        this.props.openSnackbar("error", er.response.data.message);
        console.log(er);
        this.setState({
          categoryVendorsLoading: false,
        });
      });
  };

  searchItems = (searchValue) => {
    this.setState({
      [this.state.itemSearchCurrentPage === 1
        ? "loading"
        : "paginationLoading"]: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    let url;
    let params = {};
    if (this.state.itemSearchType === "item") {
      url = searchLinkedItem(
        this.props.selectedCategory?.id,
        searchValue,
        this.state.itemSearchCurrentPage
      );
    } else {
      // here the url for new api
      url = getItemsByVendorSearch(
        this.props.selectedCategory?.id,
        searchValue,
        this.state.showAllItems ? this.state.itemSearchCurrentPage : false
      );
    }

    if (!this.state.showAllItems) {
      params.items = this.props.selectedItems?.map((item) => item.id);
    }
    axios
      .get(url, { params })
      .then((response) => {
        let final;
        // append or not respective to search is changes or not
        if (this.state.prevItemSearch.trim() === searchValue) {
          final = [...this.state.itemSearchReult, ...response.data.data];
        } else {
          final = response.data.data;
        }

        let jsonObject = final.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueRows = Array.from(uniqueSet).map(JSON.parse);

        uniqueRows.forEach((row) => {
          this.setState({
            [`${row.id}_checkedVendors`]: [],
          });
        });
        this.state.items.forEach((row) => {
          this.setState({
            [`${row.id}_checkedVendors`]: [],
          });
        });

        this.setState({
          itemsWithSelectedVendors: [],
          itemSearchReult: uniqueRows,
          itemSearchLastPage: response.data.meta?.last_page,
          itemSearchCurrentPage: response.data.meta?.current_page,
          searchedItemsTotal: response.data.meta?.total,
          [this.state.itemSearchCurrentPage === 1
            ? "loading"
            : "paginationLoading"]: false,
        });
      })
      .catch((er) => {
        this.props.openSnackbar("error", er.response.data.message);
        console.log(er);
        this.setState({
          [this.state.itemSearchCurrentPage === 1
            ? "loading"
            : "paginationLoading"]: false,
        });
      });
  };

  handleItemsLoadMore = () => {
    if (this.state.showAllItems) {
      if (this.state.itemSearchInput.trim() === "") {
        if (this.state.itemsCurrentPage !== this.state.itemsLastPage) {
          return (
            <ListItemButton
              disabled={this.state.paginationLoading}
              onClick={() => {
                this.setState(
                  {
                    itemsCurrentPage: this.state.itemsCurrentPage + 1,
                  },
                  () => {
                    this.fetchItems(this.state.itemsCurrentPage);
                  }
                );
              }}>
              {this.state.paginationLoading ? (
                <CircularProgress />
              ) : (
                <ListItemText inset primary={"Load more"} />
              )}
            </ListItemButton>
          );
        }
      } else {
        if (
          this.state.itemSearchCurrentPage !== this.state.itemSearchLastPage
        ) {
          return (
            <ListItemButton
              disabled={this.state.paginationLoading}
              onClick={() => {
                this.setState(
                  {
                    itemSearchCurrentPage: this.state.itemSearchCurrentPage + 1,
                    prevItemSearch: this.state.itemSearchInput,
                  },
                  () => {
                    this.searchItems(this.state.itemSearchInput);
                  }
                );
              }}>
              {this.state.paginationLoading ? (
                <CircularProgress />
              ) : (
                <ListItemText inset primary={"Load more"} />
              )}
            </ListItemButton>
          );
        }
      }
    }
  };

  handleSelectAllClick = (event, type, itemID, isVendor) => {
    let newSelecteds = [];
    if (type === "checkedItems") {
      if (this.state.itemSearchInput.trim() === "") {
        let newRows;
        if (this.state.showAllItems) {
          newRows = [...this.state.items, ...this.state.checkedItems];
        } else {
          newRows = [...this.props.selectedItems, ...this.state.checkedItems];
        }
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      } else {
        let newRows = [
          ...this.state.itemSearchReult,
          ...this.state.checkedItems,
        ];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      }

      // newSelecteds = this.state.items;
    }
    //  else if (type === "checkedSearchedItems") {
    //   newSelecteds = this.state.itemSearchReult;
    // }
    else {
      newSelecteds = this.state[`${itemID}_vendors`].data;
    }

    if (event.target.checked) {
      this.setState(
        {
          [type]: newSelecteds,
        },
        () => {
          if (isVendor) {
            let isItemExist =
              this.state.itemsWithSelectedVendors.indexOf(itemID);
            let itemsWithSelectedVendors = this.state.itemsWithSelectedVendors;
            if (isItemExist === -1) {
              itemsWithSelectedVendors.push(itemID);
              this.setState({
                itemsWithSelectedVendors,
              });
            }
          }
        }
      );
      return;
    }

    if (type === "checkedItems") {
      let update = [];
      if (this.state.itemSearchInput.trim() === "") {
        //removing the linkedItems from the checked linked items
        if (this.state.showAllItems) {
          update = this.state.checkedItems.filter(
            (el) => !this.state.items.map((ob) => ob.id).includes(el.id)
          );
        } else {
          update = this.state.checkedItems.filter(
            (el) => !this.props.selectedItems.map((ob) => ob.id).includes(el.id)
          );
        }
      } else {
        //removing the searched:inkedItems from the checked linked items
        update = this.state.checkedItems.filter(
          (el) => !this.state.itemSearchReult.map((ob) => ob.id).includes(el.id)
        );
      }
      this.setState({
        [type]: update,
      });
      return;
    } else {
      let isItemExist = this.state.itemsWithSelectedVendors.indexOf(itemID);
      let itemsWithSelectedVendors = this.state.itemsWithSelectedVendors;
      if (isItemExist !== -1) {
        itemsWithSelectedVendors.splice(isItemExist, 1);
        this.setState({
          itemsWithSelectedVendors,
        });
      }
    }
    this.setState({
      [type]: [],
    });
  };

  handleItemClick = (event, name, type, item_id, isVendor) => {
    const selectedIndex = this.state[type].map((el) => el.id).indexOf(name.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state[type], name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state[type].slice(1));
    } else if (selectedIndex === this.state[type].length - 1) {
      newSelected = newSelected.concat(this.state[type].slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state[type].slice(0, selectedIndex),
        this.state[type].slice(selectedIndex + 1)
      );
    }
    this.setState(
      {
        [type]: newSelected,
      },
      () => {
        if (isVendor) {
          let isItemExist =
            this.state.itemsWithSelectedVendors.indexOf(item_id);
          let itemsWithSelectedVendors = this.state.itemsWithSelectedVendors;

          if (this.state[type].length > 0) {
            // add this item if dont exist
            if (isItemExist === -1) {
              itemsWithSelectedVendors.push(item_id);
            }
          } else {
            // remove this item if exist
            if (isItemExist !== -1) {
              itemsWithSelectedVendors.splice(isItemExist, 1);
            }
          }
          this.setState({
            itemsWithSelectedVendors,
          });
        }
      }
    );
  };

  // isItemSelected = (row, type) => this.state[type].indexOf(row) !== -1;

  isItemSelected = (row, type) => {
    return this.state[type].some((e) => e.id === row.id);
  };

  linkVendors = (type) => {
    // checkedSearchedItems
    let finalItems;
    if (this.state.itemSearchInput.trim() === "") {
      finalItems = this.state.checkedItems;
    } else {
      finalItems = this.state.checkedSearchedItems;
    }
    if (!this.state.mainSelectionModel.length && !finalItems.length) {
      this.props.openSnackbar("error", "No item(s) / vendor(s) selected");
      return;
    }
    if (!finalItems.length) {
      this.props.openSnackbar("error", "No item(s) selected");
      return;
    }
    if (!this.state.mainSelectionModel.length) {
      this.props.openSnackbar("error", "No vendor(s) selected");
      return;
    }

    this.setState({
      mainLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    let data = {};
    finalItems.forEach((item) => {
      data[item.id] = this.state.mainSelectionModel.map((vendor_data) => vendor_data.vendor_id);
    })
    let payload = {
      linking_stage: 10, 
      link_as: type,
      item_vendor_plant : {
        "_" : data
      },
    }
    if (
      Object.keys(this.props.selectedPrCategory).length &&
      this.props.accountModulePermission[
        "Plant Specific Categroy Item Vendor Linking"
      ].module_activated === "Y"
    ) {
      let prlineitems = this.props.prlineitems;
      finalItems.forEach((item) => {
        prlineitems[item.id]?.forEach((lineItemData) => {
          if (
            this.props.selectedCategory?.old_id ===
            lineItemData?.category?.category_old_id
          ) {
            if(!payload.item_vendor_plant[lineItemData.plant]){
              payload.item_vendor_plant[lineItemData.plant] = {}
            }
            payload.item_vendor_plant[lineItemData.plant][item.id] = data[item.id];
            if(payload.item_vendor_plant["_"]){
              delete payload.item_vendor_plant["_"];
            }
          }
        });
      })
    }

    axios
      .post(linkVendorsToItem(this.props.selectedCategory?.id), payload)
      .then((response) => {
        this.setState(
          {
            mainLoading: false,
            showSelected: false,
            showSelectedItems: false,
            mainSelectionModel: [],
            checkedItems: [],
          },
          () => {
            this.props.openSnackbar(
              "success",
              response.data.message
                ? response.data.message
                : "Linked successully",
              true
            );
          }
        );
        // append or not respective to search is changes or not
        this.props.updateCartItemsDetails();
        this.props.handleItemizeVendorsReload();
        // this.props.handleClose();

        finalItems.forEach((item) => {
          this.setState(
            {
              // [item.id] : false,
              [`${item.id}_vendors`]: null,
              [`${item.id}_checkedVendors`]: null,
            },
            () => {
              if (this.state[item.id]) {
                this.getItemVendors(item.id, 1);
              }
            }
          );
        });
        this.setState({
          mainLoading: false,
        });
      })
      .catch((er) => {
        if (er.response) {
          if (er.response.data?.message) {
            this.props.openSnackbar("error", er.response.data?.message);
          } else if (er.response.status === 500) {
            this.props.openSnackbar(
              "error",
              "Internal Server Error. Please try again later."
            );
          } else if (er.response.status === 503) {
            this.props.openSnackbar(
              "error",
              "Service temporarily unavailable. Please try again later."
            );
          } else {
            this.props.openSnackbar(
              "error",
              "Failed to link the selected item(s) with the selected vendor(s)"
            );
          }
        } else {
          this.props.openSnackbar("error", "Network Error");
        }
        console.log(er);
        this.setState({
          mainLoading: false,
        });
      });
  };

  unlinkVendors = () => {
    // logic for multiple items
    if (!this.state.itemsWithSelectedVendors.length) {
      this.props.openSnackbar("error", "No vendor(s) selected");
      return;
    }
    let promises = [];
    this.state.itemsWithSelectedVendors.forEach((itemID) => {
      promises.push(this.unlinkVendorsAPI(itemID));
    });
    this.setState({
      mainLoading: true,
    });
    Promise.all(promises)
      .then((res) => {
        this.setState({
          mainLoading: false,
        });
        this.props.handleItemizeVendorsReload();
        this.props.updateCartItemsDetails();
        this.props.openSnackbar(
          "success",
          "Items unlinked with vendors successfully.",
          true
        );
      })
      .catch((err) => {
        this.setState({
          mainLoading: false,
        });
        this.props.openSnackbar("error", err.response.data.message);
        console.log(err, "All promises error");
      });
  };

  unlinkVendorsAPI = (finalItem) => {
    // let finalVendors = [];
    // let finalItem;
    // this.state.items.forEach((item) => {
    //   if (this.state[item.id]) {
    //     finalItem = item.id;
    //     if (this.state[`${item.id}_checkedVendors`]) {
    //       finalVendors.push(
    //         ...this.state[`${item.id}_checkedVendors`].map(
    //           (vendor) => vendor.id
    //         )
    //       );
    //     }
    //   }
    // });

    let finalVendors = this.state[`${finalItem}_checkedVendors`].map(
      (vendor) => vendor.vendor_id
    );
    let data = {};
    data[finalItem] = finalVendors;
    let payload = {
      linking_stage: 20,
      item_vendor_plant : {
        "_" : data
      },
    }

    if (
      this.props.accountModulePermission[
        "Plant Specific Categroy Item Vendor Linking"
      ].module_activated === "Y"
    ) {
      let prlineitems = this.props.prlineitems;
      if(prlineitems[finalItem]){
        prlineitems[finalItem]?.forEach((lineItemData) => {
            if (
              this.props.selectedCategory?.old_id ===
              lineItemData?.category?.category_old_id
            ) {
              if(!payload.item_vendor_plant[lineItemData.plant]){
                payload.item_vendor_plant[lineItemData.plant] = {}
              }
              payload.item_vendor_plant[lineItemData.plant][finalItem] = data[finalItem];
            }
        });
        delete payload.item_vendor_plant["_"];
      }
    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    const url = linkVendorsToItem(this.props.selectedCategory?.id);
    return axios
      .post(url, payload)
      .then((response) => {
        //check if that item is in the cart or not
        // let isCartItem = false;
        // if (
        //   this.props.selectedItems?.some((element) => {
        //     return element.id === finalItem;
        //   })
        // ) {
        //   isCartItem = true;
        // }

        // if (isCartItem && finalVendors.length === this.state[`${finalItem}_vendors`].meta.total) {
        //   let updatedSelectedItems = [];
        //   this.props.selectedItems.forEach((cartItem) => {
        //     if ( cartItem.id !== finalItem) {
        //       updatedSelectedItems.push(cartItem);
        //     }
        //   });
        //   this.props.setSelectedItem(updatedSelectedItems);
        //   this.props.openSnackbar(
        //     "warning",
        //     "Vendors Unlinked successully and item has been removed from the cart!",
        //     true
        //   );
        // } else {
        //   this.props.openSnackbar(
        //     "success",
        //     response.data.message ? response.data.message : "Unlinked successully",
        //     true
        //   );
        // }
        // this.props.handleClose();

        let isItemExist =
          this.state.itemsWithSelectedVendors.indexOf(finalItem);
        let itemsWithSelectedVendors = this.state.itemsWithSelectedVendors;
        itemsWithSelectedVendors.splice(isItemExist, 1);

        this.setState(
          {
            // [item.id] : false,
            [`${finalItem}_vendors`]: null,
            [`${finalItem}_checkedVendors`]: null,
            itemsWithSelectedVendors,
          },
          () => {
            this.getItemVendors(finalItem, 1);
          }
        );
      })
      .catch((er) => {
        // this.toggleLoaderArray(`${finalItem}_unlinking`)
        console.log(er);
        if (er.response) {
          if (er.response.data?.message) {
            this.props.openSnackbar("error", er.response.data?.message);
          } else if (er.response.status === 500) {
            this.props.openSnackbar(
              "error",
              "Internal Server Error. Please try again later."
            );
          } else if (er.response.status === 503) {
            this.props.openSnackbar(
              "error",
              "Service temporarily unavailable. Please try again later."
            );
          } else {
            this.props.openSnackbar(
              "error",
              "Failed to Unlink the selected vendor(s)"
            );
          }
        } else {
          this.props.openSnackbar("error", "Network Error");
        }
      });
  };

  isVendorSelected = (row) => {
    return this.state.mainSelectionModel.some((e) => e.id === row.id);
  };

  handleVendorClick = (vendor) => {
    const selectedIndex = this.state.mainSelectionModel
      .map((ob) => ob.id)
      .indexOf(vendor.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.mainSelectionModel, vendor);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.mainSelectionModel.slice(1));
    } else if (selectedIndex === this.state.mainSelectionModel.length - 1) {
      newSelected = newSelected.concat(
        this.state.mainSelectionModel.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.mainSelectionModel.slice(0, selectedIndex),
        this.state.mainSelectionModel.slice(selectedIndex + 1)
      );
    }
    this.setState(
      {
        mainSelectionModel: newSelected,
      },
      () => {
        // this.updateSelectedVendors(this.state.mainSelectionModel.map(vendor => vendor.id), this.state.mainSelectionModel);
      }
    );
  };

  getSelectableRows = (data) => {
    return data.filter((params) => {
      if (!params.email?.length) {
        return false;
      }
      return true;
    });
  };

  handleVendorSelectAllClick = (event) => {
    if (this.state.showSelected) {
      this.setState(
        {
          mainSelectionModel: [],
        },
        () => {
          // this.updateSelectedVendors([]);
        }
      );
      return;
    }
    let newSelecteds = [];
    if (this.state.isFilterActive) {
      let newRows = [
        ...this.getSelectableRows(this.state.filteredRows),
        ...this.state.mainSelectionModel,
      ];
      let finalRows = newRows;
      let jsonObject = finalRows.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      newSelecteds = Array.from(uniqueSet).map(JSON.parse);
    } else {
      if (this.state.categoryVednorSearchInput.trim() === "") {
        // let newRows = [...this.state.rows, ...this.state.mainSelectionModel];
        let newRows = [
          ...this.getSelectableRows(this.state.categoryVendors),
          ...this.state.mainSelectionModel,
        ];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      } else {
        let newRows = [
          ...this.getSelectableRows(this.state.categoryVendorSearchResult),
          ...this.state.mainSelectionModel,
        ];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      }
    }

    if (event.target.checked) {
      this.setState(
        {
          mainSelectionModel: newSelecteds,
        },
        () => {
          // this.updateSelectedVendors(newSelecteds.map(vendor => vendor.id));
        }
      );
      return;
    }

    let update = [];
    if (this.state.isFilterActive) {
      update = this.state.mainSelectionModel.filter(
        (el) => !this.state.filteredRows.map((ob) => ob.id).includes(el.id)
      );
    } else {
      if (this.state.categoryVednorSearchInput.trim() === "") {
        //removing the rows from the main selection model
        update = this.state.mainSelectionModel.filter(
          (el) => !this.state.categoryVendors.map((ob) => ob.id).includes(el.id)
        );
      } else {
        //removing the searched:inkedItems from the checked linked items
        update = this.state.mainSelectionModel.filter(
          (el) =>
            !this.state.categoryVendorSearchResult
              .map((ob) => ob.id)
              .includes(el.id)
        );
      }
    }
    this.setState(
      {
        mainSelectionModel: update,
      },
      () => {
        // this.updateSelectedVendors(update.map(vendor => vendor.id), this.state.mainSelectionModel);
      }
    );
  };

  commonItems = (arr1, arr2) => {
    return arr1.filter(function (o1) {
      return arr2.some(function (o2) {
        return o1.id === o2.id; // return the ones with equal id
      });
    });
  };

  getFirstColumn = () => {
    if (this.state.showSelected) {
      return {
        field: "id",
        width: 20,
        disableColumnMenu: true,
        align: "left",
        headerAlign: "left",
        editable: false,
        sortable: false,
        filterable: false,
        renderHeader: () => (
          <Tooltip title="remove all vendors">
            <IconButton
              onClick={(event) => this.handleVendorSelectAllClick(event)}
              color="error">
              <RemoveCircleIcon />
            </IconButton>
          </Tooltip>
        ),
        renderCell: (params) => {
          return (
            <Tooltip title="remove vendor">
              <IconButton
                onClick={() => this.handleVendorClick(params.row)}
                color="error">
                <RemoveCircleIcon />
              </IconButton>
            </Tooltip>
          );
        },
      };
    } else {
      return {
        field: "id",
        width: 42,
        disableColumnMenu: true,
        align: "left",
        headerAlign: "left",
        editable: false,
        sortable: false,
        filterable: false,
        renderHeader: () => (
          <Checkbox
            indeterminate={
              this.state.isFilterActive
                ? this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.filteredRows)
                  )?.length > 0 &&
                  this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.filteredRows)
                  )?.length <
                    this.getSelectableRows(this.state.filteredRows).length
                : this.state.categoryVednorSearchInput.trim() === ""
                ? this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.categoryVendors)
                  )?.length > 0 &&
                  this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.categoryVendors)
                  )?.length <
                    this.getSelectableRows(this.state.categoryVendors).length
                : this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(
                      this.state.categoryVendorSearchResult
                    )
                  )?.length > 0 &&
                  this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(
                      this.state.categoryVendorSearchResult
                    )
                  )?.length <
                    this.getSelectableRows(
                      this.state.categoryVendorSearchResult
                    ).length
            }
            checked={
              this.state.isFilterActive
                ? this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.filteredRows)
                  )?.length > 0 &&
                  this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.filteredRows)
                  )?.length ===
                    this.getSelectableRows(this.state.filteredRows).length
                : this.state.categoryVednorSearchInput.trim() === ""
                ? this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.categoryVendors)
                  )?.length > 0 &&
                  this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.categoryVendors)
                  )?.length ===
                    this.getSelectableRows(this.state.categoryVendors).length
                : this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(
                      this.state.categoryVendorSearchResult
                    )
                  )?.length > 0 &&
                  this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(
                      this.state.categoryVendorSearchResult
                    )
                  )?.length ===
                    this.getSelectableRows(
                      this.state.categoryVendorSearchResult
                    ).length
            }
            onChange={(event) => this.handleVendorSelectAllClick(event)}
          />
        ),
        renderCell: (params) => {
          if (!params.row.email?.length) {
            return "";
          }
          return <Checkbox checked={this.isVendorSelected(params.row)} />;
        },
      };
    }
  };
  getVendorLocation = (country) => {
    if (this.props.accountlevelInfo?.country === country) {
      return "L";
    } else return "I";
  };

  toggleShowAllItems = () => {
    this.collpaseAllItems();
    this.resetItemsVendors();
    this.setState(
      {
        showAllItems: !this.state.showAllItems,
        checkedItems: [],
        itemSearchInput: "",
        itemSearchType: "vendor",
        // ...(!this.state.showAllItems && { itemSearchType:"vendor" }),
      },
      () => {
        this.fetchItems(1);
      }
    );
  };
  resetItemsVendors = () => {
    this.state.items.forEach((row) => {
      this.setState({
        [`${row.id}_checkedVendors`]: [],
      });
    });
    this.setState({
      itemsWithSelectedVendors: [],
    });
  };
  render() {
    const columns = [
      this.getFirstColumn(),
      {
        field: "vendor_name",
        headerName: "VENDOR NAME",
        width: 350,
        editable: false,
        // filterable: false,
        filterable: this.state.categoryVednorSearchInput.trim() === "",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Avatar
                sx={{
                  backgroundColor: (theme) => theme.palette.success.main,
                  width: "34px",
                  height: "34px",
                }}>
                {params.row.vendor_name.slice(0, 1)}
              </Avatar>
              <Box sx={{ lineHeight: "12px", ml: 1 }}>
                <Tooltip
                  title={params.row.vendor_name}
                  disableInteractive
                  placement="bottom-start">
                  <Typography color="text.primary" fontWeight={500} noWrap>
                    {params.row.vendor_name}
                  </Typography>
                </Tooltip>
                <Typography variant="span" color="grey.500">
                  {params.row.vendor_code}
                </Typography>
              </Box>
            </React.Fragment>
          );
        },
      },
      {
        field: "vendor_status",
        headerName: "VENDOR STATUS",
        width: 150,
        editable: false,
        filterable: this.state.categoryVednorSearchInput.trim() === "",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Typography fontWeight={500}>
                {params.row.is_approved === "approved" ? 
                  getVendorGMPFlag("Y", "A", "Approved", 'A', "") : 
                  getVendorGMPFlag("Y", "N", "Non-Approved", 'N', "")
                }
                {getVendorGMPFlag(this.props.companyInfo.gst_verification, params.row.gstin_no, params.row.gstin_status, 'G', "GSTIN")}
                {params.row.industry_type === "MSME" ? 
                  getVendorGMPFlag(this.props.companyInfo.msme_verification, params.row.industry_type_value, params.row.msme_status, 'M', "MSME") : 
                  getVendorGMPFlag(this.props.companyInfo.msme_verification, "", params.row.msme_status, 'M', "MSME")
                }
                {getVendorGMPFlag(this.props.companyInfo.pan_verification, params.row.pan_no, params.row.pan_status, 'P', "PAN")}
              </Typography>
            </React.Fragment>
          );
        },
      },
      {
        field: "location",
        headerName: "LOCATION",
        type: "singleSelect",
        valueOptions: ["local", "foreign"],
        editable: false,
        align: "center",
        filterable: this.state.categoryVednorSearchInput.trim() === "",
        // filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Typography variant="span" color="grey.500">
                {this.getVendorLocation(params.row.country)}
              </Typography>
            </React.Fragment>
          );
        },
      },
      {
        field: "country",
        headerName: "COUNTRY",
        width: 150,
        editable: false,
        filterable: this.state.categoryVednorSearchInput.trim() === "",
        filterOperators: getFilterOperators(),
      },
      {
        field: "state",
        headerName: "STATE",
        width: 150,
        editable: false,
        filterable: this.state.categoryVednorSearchInput.trim() === "",
        filterOperators: getFilterOperators(),
      },
      {
        field: "industry_type",
        headerName: "INDUSTRY TYPE",
        width: 200,
        editable: false,
        filterable: this.state.categoryVednorSearchInput.trim() === "",
        filterOperators: getFilterOperators(),
      },
    ];

    const { open, handleClose } = this.props;
    return (
      <div>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={this.state.mainLoading}>
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
        <Dialog
          scroll="body"
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{
            "& .LinkUnlink-footer": {
              padding: "10px 16px",
              textAlign: "right",
              color: (theme) => theme.palette.grey[500],
              fontSize: "14px",
            },
            "& .LinkUnlink-footer span": {
              color: (theme) => theme.palette.grey[800],
              fontWeight: "500",
            },
          }}>
          <DialogTitle id="responsive-dialog-title">
            Link Vendor To item
            <Box className="dialog-title-action">
              <IconButton
                sx={{ color: (theme) => theme.palette.bluegrey[500] }}
                onClick={this.props.handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              background: (theme) => theme.palette.grey[50],
              padding: "25px 20px !important",
            }}>
            <div className="linktovendoritem_content">
              <Grid container alignItems="center">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    component="p"
                    color="grey.500"
                    sx={{ mb: "20px", fontSize: "12px" }}>
                    Note : - Vendor(s) For which Email id Not available are
                    shown without checkbox in All Vendor Grid
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <Card variant="outlined">
                    <CardActions
                      sx={{
                        display: "flex",
                        alignItems: "center", 
                        justifyContent: "flex-start", 
                      }}                    
                    >
                      {this.state.itemSearchInput.trim() === "" ? (
                        <Checkbox
                          indeterminate={
                            this.state.showAllItems
                              ? this.state.checkedItems.length > 0 &&
                                this.state.checkedItems.length <
                                  this.state.items.length
                              : this.state.checkedItems.length > 0 &&
                                this.state.checkedItems.length <
                                  this.props.selectedItems?.length
                          }
                          checked={
                            this.state.showAllItems
                              ? this.state.items.length > 0 &&
                                this.state.checkedItems.length ===
                                  this.state.items.length
                              : this.props.selectedItems?.length > 0 &&
                                this.state.checkedItems.length ===
                                  this.props.selectedItems?.length
                          }
                          disabled={this.state.showSelectedItems}
                          onChange={(e) => {
                            this.handleSelectAllClick(e, "checkedItems");
                          }}
                        />
                      ) : (
                        <Checkbox
                          indeterminate={
                            this.state.checkedSearchedItems.length > 0 &&
                            this.state.checkedSearchedItems.length <
                              this.state.itemSearchReult.length
                          }
                          checked={
                            this.state.itemSearchReult.length > 0 &&
                            this.state.checkedSearchedItems.length ===
                              this.state.itemSearchReult.length
                          }
                          disabled={this.state.showSelectedItems}
                          onChange={(e) => {
                            this.handleSelectAllClick(
                              e,
                              "checkedSearchedItems"
                            );
                          }}
                        />
                      )}

                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          color: "text.primary",
                          fontSize: "14px",
                          marginRight: "auto",
                        }}>
                        LINKED ITEM
                      </Typography>
                      {this.props.selectedItems?.length &&
                        window.location.pathname.indexOf("prcombination") ===
                          -1 && (
                          <Tooltip
                            title={
                              this.state.showAllItems
                                ? "Show Cart Items"
                                : "Show All Items"
                            }>
                            <IconButton
                              sx={{
                                // ml: "auto",
                                color: (theme) =>
                                  this.state.showAllItems
                                    ? theme.palette.primary.main
                                    : theme.palette.grey[600],
                              }}
                              // disabled={!this.state.checkedItems.length}
                              onClick={this.toggleShowAllItems}>
                              <ViewListOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                      <Tooltip
                        title={
                          this.state.checkedItems.length && "View Selected item"
                        }>
                        <IconButton
                          sx={{
                            // ml: "auto",
                            color: (theme) =>
                              this.state.showSelectedItems
                                ? theme.palette.primary.main
                                : theme.palette.grey[600],
                          }}
                          disabled={!this.state.checkedItems.length}
                          onClick={() => {
                            this.setState({
                              showSelectedItems: !this.state.showSelectedItems,
                            });
                          }}>
                          <Badge
                            badgeContent={this.state.checkedItems.length}
                            color="error">
                            <ListAltOutlinedIcon
                            // sx={{ color: (theme) => this.state.showSelectedLinked ? theme.palette.primary.main : theme.palette.grey[600] }}
                            />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Unlink Selected Vendors">
                        <IconButton
                          onClick={() => this.unlinkVendors()}
                          size="small"
                          sx={{ ml: "auto", color: "grey.600" }}>
                          <LinkOffIcon />
                        </IconButton>
                      </Tooltip>
                    </CardActions>

                    <div id="linked-items" style={{ position: "relative" }}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <FlatsearchInput
                          name="itemSearchInput"
                          value={this.state.itemSearchInput}
                          onChange={this.handleChange}
                          placeholder="Search"
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          endAdornment={
                            <React.Fragment>
                              {this.state.itemSearchInput !== "" ? (
                                <IconButton
                                  onClick={() => {
                                    this.collpaseAllItems();
                                    this.resetItemsVendors();
                                    this.setState({
                                      itemSearchInput: "",
                                    });
                                  }}
                                  size="small">
                                  <ClearIcon />
                                </IconButton>
                              ) : null}
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                              />

                              <Select
                                // fullWidth
                                // size="small"
                                sx={{ width: 150 }}
                                variant="outlined"
                                value={this.state.itemSearchType}
                                onChange={(e) => {
                                  this.collpaseAllItems();
                                  this.resetItemsVendors();
                                  this.setState({
                                    itemSearchType: e.target.value,
                                    itemSearchInput: "",
                                  });
                                }}>
                                <MenuItem value="vendor">Vendors</MenuItem>
                                {this.state.showAllItems && (
                                  <MenuItem value="item">Items</MenuItem>
                                )}
                              </Select>
                            </React.Fragment>
                          }
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div
                          style={{
                            height: "45vh",
                            overflowY: "auto",
                          }}>
                          <List
                            component="nav"
                            aria-labelledby="nested-list-subheader">
                            {this.state.loading ? (
                              <VendorItemSkeleton />
                            ) : this.state.itemSearchInput.trim() === "" ? (
                              this.state.showAllItems ? (
                                this.handler(this.state.items, "checkedItems")
                              ) : (
                                this.handler(this.state.items, "checkedItems")
                              )
                            ) : (
                              this.handler(
                                this.state.itemSearchReult,
                                "checkedItems"
                              )
                            )}
                            {this.handleItemsLoadMore()}
                          </List>
                        </div>
                      </Grid>
                    </div>
                    <Box className="LinkUnlink-footer">
                      {this.state.showAllItems ? (
                        <Typography component="p">
                          Showing{" "}
                          <span>
                            {this.state.itemSearchInput?.trim() === ""
                              ? `${this.state.items?.length} / ${this.state.itemsTotal}`
                              : `${this.state.itemSearchReult?.length} / ${this.state.searchedItemsTotal}`}
                          </span>
                        </Typography>
                      ) : (
                        <Typography component="p">
                          Showing{" "}
                          <span>
                            {this.state.itemSearchInput?.trim() === ""
                              ? `${this.props.selectedItems?.length} / ${this.props.selectedItems?.length}`
                              : `${this.state.itemSearchReult?.length} / ${this.state.itemSearchReult?.length}`}
                          </span>
                        </Typography>
                      )}
                    </Box>
                  </Card>

                  <Drawer
                    anchor="bottom"
                    open={this.state.showSelectedItems}
                    onClose={() => {
                      this.setState({
                        showSelectedItems: false,
                      });
                    }}
                    // onOpen={() => toggleDrawer(true)}
                    // disableSwipeToOpen={false}
                    sx={{
                      zIndex: 99999999999,
                    }}
                    PaperProps={{
                      style: {
                        position: "absolute",
                        borderRadius: "10px 10px 0 0",
                        overflow: "inherit",
                      },
                    }}
                    // variant="permanent"
                    BackdropProps={{ style: { position: "absolute" } }}
                    ModalProps={{
                      container: document.getElementById("linked-items"),
                      style: { position: "absolute" },
                      keepMounted: true,
                    }}>
                    <Box
                      sx={{
                        p: "10px 16px",
                        borderRadius: "10px 10px 0 0",
                        background: (theme) => theme.palette.grey[100],
                      }}>
                      <Typography
                        color="grey.900"
                        fontSize={13}
                        fontWeight={500}>
                        Selected Items
                      </Typography>
                    </Box>
                    {this.selectedItems()}
                    {/* <h1> linked-items </h1> */}
                    {/* <ItemList
                      showSelectedLinked={true}
                      data={data}
                      handleItemClick={handleItemClick}
                      type={type}
                    /> */}
                  </Drawer>
                </Grid>
                <Grid item lg={8} md={6} sm={6} xs={12}>
                  <Card variant="outlined">
                    <CardActions
                      sx={{
                        display: "flex",
                        alignItems: "center", 
                        justifyContent: "flex-start", 
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          color: "text.primary",
                          fontSize: "14px",
                          marginRight: "auto",
                          marginLeft: "10px",
                        }}>
                        ALL VENDORS
                      </Typography>
                      {
                        <Tooltip
                          title={
                            this.state.mainSelectionModel.length &&
                            "View Selected vendors"
                          }>
                          <IconButton
                            sx={{
                              // ml: "auto",
                              color: (theme) =>
                                this.state.showSelected
                                  ? theme.palette.primary.main
                                  : theme.palette.grey[600],
                            }}
                            disabled={!this.state.mainSelectionModel.length}
                            onClick={() =>
                              this.setState({
                                showSelected: !this.state.showSelected,
                              })
                            }>
                            <Badge
                              badgeContent={
                                this.state.mainSelectionModel.length
                              }
                              color="error">
                              <ListAltOutlinedIcon />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      }
                      <Tooltip title="Link Selected Vendors">
                        <IconButton
                          onClick={(e) => {
                            this.setState({
                              anchorEl: e.currentTarget,
                            });
                          }}
                          sx={{
                            ml: "auto",
                            color: (theme) => theme.palette.grey[600],
                          }}
                          size="small">
                          <LinkRounded />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        onClose={() => {
                          this.setState({
                            anchorEl: null,
                          });
                        }}>
                        {this.props.masterPermission?.vendor_approval ===
                          "Y" && (
                          <MenuItem
                            onClick={() => {
                              this.linkVendors("approved");
                              this.setState({
                                anchorEl: null,
                              });
                            }}>
                            Approved
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            this.linkVendors("non-approved");
                            this.setState({
                              anchorEl: null,
                            });
                          }}>
                          Non Approved
                        </MenuItem>
                      </Menu>
                    </CardActions>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FlatsearchInput
                        name="categoryVednorSearchInput"
                        value={this.state.categoryVednorSearchInput}
                        onChange={(event) =>
                          this.requestSearch(event.target.value)
                        }
                        disabled={this.state.showSelected}
                        placeholder="search vendor"
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        endAdornment={
                          <React.Fragment>
                            {this.state.categoryVednorSearchInput !== "" ? (
                              <IconButton
                                onClick={() => {
                                  this.setState({
                                    categoryVednorSearchInput: "",
                                    isFilterActive: false,
                                    filterDataCurrentPage: 1,
                                    filteredRows: [],
                                    filterDataLastPage: 1,
                                  });
                                }}
                                size="small">
                                <ClearIcon />
                              </IconButton>
                            ) : null}
                          </React.Fragment>
                        }
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {this.state.showSelected ? (
                        <DataGridPro
                          key={0}
                          sx={{
                            zIndex: "2",
                            border: "none",
                            maxHeight:"45vh",
                            minHeight:"45vh",

                            "& .MuiDataGrid-columnHeaderTitleContainer": {
                              padding: "0 !important",
                            },
                          }}
                          // checkboxSelection
                          hideFooter
                          loading={this.state.categoryVendorsLoading}
                          rows={this.state.mainSelectionModel}
                          rowSelectionModel={this.state.mainSelectionModel.map(
                            (ob) => ob.id
                          )}
                          columns={columns}
                          disableColumnMenu
                          disableColumnReorder
                          disableColumnPinning
                        />
                      ) : (
                        <DataGridPro
                          key={1}
                          sx={{
                            zIndex: "2",
                            border: "none",
                            maxHeight: "45vh",
                            minHeight:"45vh",
                            "& .MuiDataGrid-columnHeaderTitleContainer": {
                              padding: "0 !important",
                            },
                          }}
                          hideFooter
                          loading={this.state.categoryVendorsLoading}
                          rows={
                            this.state.isFilterActive
                              ? this.state.filteredRows
                              : this.state.categoryVednorSearchInput.trim() ===
                                ""
                              ? this.state.categoryVendors
                              : this.state.categoryVendorSearchResult
                          }
                          columns={columns}
                          disableColumnSelector
                          disableColumnReorder
                          rowSelectionModel={
                            this.state.isFilterActive
                              ? this.state.mainSelectionModel
                                  .filter((el) =>
                                    this.state.filteredRows
                                      .map((ob) => ob.id)
                                      .includes(el.id)
                                  )
                                  .map((row) => row.id)
                              : this.state.categoryVednorSearchInput.trim() ===
                                ""
                              ? this.state.mainSelectionModel
                                  .filter((el) =>
                                    this.state.categoryVendors
                                      .map((ob) => ob.id)
                                      .includes(el.id)
                                  )
                                  .map((row) => row.id)
                              : this.state.mainSelectionModel
                                  .filter((el) =>
                                    this.state.categoryVendorSearchResult
                                      .map((ob) => ob.id)
                                      .includes(el.id)
                                  )
                                  .map((row) => row.id)
                          }
                          onRowClick={(params) => {
                            if (!params.row.email?.length) {
                              return;
                            }
                            this.handleVendorClick(params.row);
                          }}
                          isRowSelectable={(params) => {
                            if (!params.row.email?.length) {
                              return false;
                            }
                            return true;
                          }}
                          filterModel={this.state.filterModel}
                          onFilterModelChange={this.handleFilterModelChange}
                          filterMode="server"
                          onRowsScrollEnd={this.handleScrollEnd}
                          slots={{
                            noRowsOverlay: () => {
                              return (
                                <GridOverlay>
                                  <Typography>Vendors not available</Typography>
                                </GridOverlay>
                              );
                            },
                          }}
                          disableColumnPinning
                        />
                      )}
                    </Grid>
                    <Box className="LinkUnlink-footer">
                      <Typography component="p">
                        Showing{" "}
                        <span>
                          {this.state.isFilterActive
                            ? `${this.state.filteredRows.length} / ${this.state.filteredTotal}`
                            : this.state.categoryVednorSearchInput?.trim() ===
                              ""
                            ? `${this.state.categoryVendors?.length} / ${this.state.categoryVendorsTotal}`
                            : `${this.state.categoryVendorSearchResult?.length} / ${this.state.categoryVendorSearchTotal}`}
                        </span>
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  sessionId: state.sessionReducer.sessionId,
  selectedCategory: state.categoryReducer.selectedCategory,
  masterPermission: state.permissionsReducer.masterPermission,
  selectedItems: state.itemReducer.selectedItems,
  accountlevelInfo: state.permissionsReducer.accountlevelInfo,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  selectedVendors: state.vendorReducer.selectedVendors || [],
  selectedPrCategory: state.prrfqreducer.selectedPrCategory,
  prlineitems: state.prrfqreducer.setPrLineItemsData,
  companyInfo: state.companyInfoReducer.companyInfo,
});
export default connect(mapStateToProps, {
  setSelectedVendors,
  setSelectedItem,
  openSnackbar,
})(LinkVendorsToItem);
