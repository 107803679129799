import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  Stack,
  Chip,
  Avatar,
  Tooltip,
  Breadcrumbs,
  IconButton,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import {
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridOverlay,
} from "@mui/x-data-grid-pro";
import { MoreVert } from "@mui/icons-material";
import VendorMenuItems from "../../../utils/RFQList/vendorMenuItems";
import { RFQVendorStyle } from "./Style";
import {
  getTransactionVendorDetails,
  get_Inquiries_data,
  vendor_QPlus,
} from "../../../config/apiUrl";
import CommonDataGridPro from "../commonDataGridPro/commonDataGridPro";
import { CustomIconButton } from "../../../utils/RFQList/utils";
import axios from "axios";
import moment from "moment";
import JustificationPopOver from "../../../common/RFQList/justificationPopOver";
import VendorChip from "../../Vendors/VendorChip";
import { useSelector } from "react-redux";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ExtendMassNegoValidityDate from '../ExtendMassNegotiation/ExtendMassNegoValidityDate';

function VendorList({
  sessionID,
  templateId,
  itemSpecificVendor,
  selectedId,
  inquiryNumber,
  selectedItem,
  vendorCancelQuote,
  setSelectedItem,
  filterDataChip,
  statusOngoing
}) {
  const [vendorsDetails, setVendorsDetails] = useState([]);
  const [vendorsDetailsAPIRes, setVendorsDetailsAPIRes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [cancelQuote, setCancelQuote] = useState("");
  const [extendNegoStatus, setExtendNegoStatus] = useState("");
  const [extendNegoDetails, setExtendNegoDetails] = useState("");
  const [JustificationContent, setJustificationContent] = useState("");
  const [regretsStatus, setRegretsStatus] = useState("");
  const [selectedMenuId, setSelectedMenuId] = useState(null);
  const [selectedQuoteId, setSelectedQuoteId] = useState(null);
  const [selectedVendorName, setSelectedVendorName] = useState(null);
  const [countVendor, setCountVendor] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [negoMaxDate, setNegoMaxDate] = useState(null);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const companyPermissions = useSelector(
    (state) => state.companyInfoReducer.companyInfo
  );

  const openMenu = Boolean(anchorEl);
  const openMenu2 = Boolean(anchorEl2);

  const configAxios = {
    headers: { Authorization: `Bearer ${sessionID}` },
  };

  const handlePopoverClose = () => {
    setAnchorEl2(null);
  };
  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };
  const vendorNameCell = (params) => {
    const nameFirstLetter = params?.row?.name.split("", 1);
    const handleLinkClick = (index) => {
      const selectedGuid = params?.row?.quotation?.latest_TLGUID
        ? params?.row?.quotation?.latest_TLGUID
        : params?.row?.guid;
      const selectedTransactionType = params?.row?.trantype;
      /* Add this code for sealed rfq view */
      if (filterData?.sealed_rfq === true &&
        filterData?.sealed_rfq_post_nego === true && negoMaxDate) {  // Check if negoMaxDate is NOT null
         const negoMaxDateMoment = moment(negoMaxDate, "YYYY-MM-DD HH:mm:ss");
      
          if (negoMaxDateMoment.seconds() === 0) {
            // Add 59 seconds if seconds are 00
            negoMaxDateMoment.add(59, "second");
          }
      
          if (negoMaxDateMoment.isAfter(moment())) {
            setSnackbarMessage("Sorry, This RFQ is Sealed RFQ and negotiation validity is not expired.");
            setSnackbarSeverity("error");
            setSnackbar(true);
            return;
          }
      }
      
      // If negoMaxDate is null, or if conditions are not met, execute this part
      if (selectedGuid && selectedTransactionType) {
        const linkURL = `${vendor_QPlus}?vendx_action=tran_details&TLGUID=${selectedGuid}&TType=${selectedTransactionType}&TemplateID=${templateId}&fromReact=1`;
        window.open(linkURL, "_blank", "noopener noreferrer");
      } else {
        console.log("Error: Missing guid or Transaction Type.");
      }
    };

    return (
      <Fragment>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          spacing={1}
          sx={{
            width: "100%",
            height: "100%",
            "& .MuiButtonBase-root": {
              visibility: { md: "hidden", sm: "visible" },
              marginLeft: "auto",
            },
            "&:hover .MuiButtonBase-root": {
              visibility: "visible",
            },
          }}
        >
          <Avatar
            sx={{
              color: "white",
              bgcolor: "var(grey[100])",
              width: 32,
              height: 32,
            }}
          >
            {nameFirstLetter}
          </Avatar>
          <Box sx={{ width: "calc(100% - 70px)" }}>
            {filterDataChip.sealed && statusOngoing ? (
              <>
                <Tooltip title={params?.row?.name}>
                  <Typography
                    sx={{ lineHeight: "normal"}}
                    fontWeight={500}
                    noWrap
                  >
                    {params?.row?.name}
                  </Typography>
                </Tooltip>
              </>
            ) : (
              <>
                <Link
                  onClick={handleLinkClick}
                  sx={{
                    textDecoration: "none",
                    color: "text.primary",
                  }}
                >
                  <Tooltip title={params?.row?.name}>
                    <Typography
                      sx={{ lineHeight: "normal", cursor: "pointer" }}
                      fontWeight={500}
                      noWrap
                    >
                      {params?.row?.name}
                    </Typography>
                  </Tooltip>
                </Link>
              </>
            )}
            <Typography
              variant="vendorsubtext"
              color={"secondary.400"}
              display="block"
            >
              {params?.row?.code}
            </Typography>
          </Box>
          <Tooltip title="View Details" placement="bottom">
            {filterDataChip.sealed && statusOngoing ? (
              <></>
            ) : (
              <>
                <IconButton
                  aria-label="View Details"
                  size="small"
                  onClick={handleLinkClick}
                >
                  <LaunchRoundedIcon fontSize="inherit" />
                </IconButton>
              </>
            )}
          </Tooltip>
        </Stack>
      </Fragment>
    );
  };

  function CustomGridToolbar() {
    return (
      <Fragment>
        <GridToolbarContainer className="datagrid-toolbar">
          <Grid
            container
            spacing={0}
            sx={{
              width: "100%",
              pt: 1,
              "&.MuiGrid2-root": {
                paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" },
              },
            }}
          >
            <Grid xs={6} sm={6} md={4} xl={4}>
              <Box>
                <GridToolbarQuickFilter
                  className="DataGrid-SearchBar"
                  sx={{ p: 0 }}
                />
              </Box>
            </Grid>
            <Grid xs={6} sm={6} md={8} xl={8} sx={{ textAlign: "right" }}>
              <Box className="toolbar-right">
                <IconButton onClick={handleExtendNegoDateDrawer} size="small" sx={{color:"var(--secondary-600)"}}>
                  <EditCalendarIcon fontSize="small"/>
                </IconButton>
                <Box className="dg-filter">
                  <GridToolbarColumnsButton />
                  <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{
                      fileName: "Vendor Details Report",
                    }}
                  />
                  <GridToolbarFilterButton />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer>
        {/* <GridToolbarContainer
          className="datagrid-toolbar"
          sx={{ display: { sm: "flex", md: "none" }, right: 0 }}>
          <Grid
            container
            spacing={0}
            sx={{ width: "100%", alignItems: "center" }}>
            <Grid xs={2} md={6}>
              <Box>&nbsp;</Box>
            </Grid>
            <Grid xs={10} md={6} sx={{ textAlign: { xs: "right", sm: "right" } }}>
              <Box className="dg-filter">
                <GridToolbarColumnsButton />
                <GridToolbarExport 
                  printOptions={{ disableToolbarButton: true }}
                  csvOptions={{
                    fileName: "Vendor Details Report",
                  }}
                />
                <GridToolbarFilterButton />
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer> 
      <Divider />
      </Box>
      */}
      </Fragment>
    );
  }

  const quoteStatusCell = (params) => {
    const value = params.row.quotation.status;
    // Custom formatting logic
    return (
      <Box>
        {value === "Quoted" && (
          <Chip label="Quoted" size="small" variant="soft" color="success" />
        )}
        {value === "Pending" && (
          <Chip label="Pending" size="small" variant="soft" color="warning" />
        )}
        {value === "Rejected" && (
          <Chip label="Rejected" size="small" variant="soft" color="error" />
        )}
        {value === "Regretted" && (
          <Chip
            label="Regretted"
            size="small"
            variant="soft"
            color="error"
            onClick={(e) => {
              setRegretsStatus(params.row.quotation.status);
              transactionStatusModal(
                e,
                params.row.id,
                params.row.quotation.justification
              );
            }}
          />
        )}
        {value === "Requoted" && (
          <Chip label="Requoted" size="small" variant="soft" color="success" />
        )}
        {value === "Negotiations" && (
          <Chip
            label="Negotiations"
            size="small"
            variant="soft"
            color="success"
          />
        )}
        {value === "Under Nego." && (
          <Chip
            label="Under Nego."
            size="small"
            variant="soft"
            color="success"
          />
        )}
        {value === "Nego. Regretted" && (
          <Chip
            label="Nego. Regretted"
            size="small"
            variant="soft"
            color="error"
            onClick={(e) => {
              setRegretsStatus(params.row.quotation.status);
              transactionStatusModal(
                e,
                params.row.id,
                params.row.quotation.justification
              );
            }}
          />
        )}
        {value === "Off. Quoted" && (
          <Chip
            label="Off. Quoted"
            size="small"
            variant="soft"
            color="success"
          />
        )}
        {value === "Off. Requoted" && (
          <Chip
            label="Off. Requoted"
            size="small"
            variant="soft"
            color="success"
          />
        )}
        {value === "Off. Regretted" && (
          <Chip
            label="Off. Regretted"
            size="small"
            variant="soft"
            color="success"
            onClick={(e) => {
              setRegretsStatus(params.row.quotation.status);
              transactionStatusModal(
                e,
                params.row.id,
                params.row.quotation.justification
              );
            }}
          />
        )}
        {value === "Off. Nego. Regretted" && (
          <Chip
            label="Off. Nego. Regretted"
            size="small"
            variant="soft"
            color="error"
            onClick={(e) => {
              setRegretsStatus(params.row.quotation.status);
              transactionStatusModal(
                e,
                params.row.id,
                params.row.quotation.justification
              );
            }}
          />
        )}
      </Box>
    );
  };

  const negotiationCell = (params) => {
    const negotiation = params?.row?.negotiation;
    return (
      <Box>
        {negotiation === true ? (
          <Typography variant="body2">Yes</Typography>
        ) : (
          <Typography variant="body2">No</Typography>
        )}
      </Box>
    );
  };



  const lastQuotationCell = (params) => {
    const lastQuotationRef = params?.row?.quotation?.quotation_ref;
    return (
      <Box>
        {lastQuotationRef !== null && lastQuotationRef !== "null" ? (
          <Typography variant="body2">{lastQuotationRef}</Typography>
        ) : (
          <Typography>-</Typography>
        )}
      </Box>
    );
  };

  const lastActionDateCell = (params) => {
    const lastActionDate = params?.row?.quotation?.action_at;
    const formatDate = moment(lastActionDate, "YYYY-MM-DD HH:mm:ss").format(
      "DD/MM/YYYY HH:mm:ss"
    );
    return (
      <Box>
        {lastActionDate !== null && lastActionDate !== "null" ? (
          <Typography variant="body2" component="div">
            {formatDate} IST
          </Typography>
        ) : (
          <Typography>-</Typography>
        )}
      </Box>
    );
  };

  const GroupedIcon = (params) => {
    // const isTB = params.row.inquiry.is_tb;
    return (
      <Stack spacing={1} direction="row">

        <VendorChip vendor_data={params.row} module_permission={companyPermissions}/>

      </Stack>
    );
  };

  const tripleDotStatusCell = (params) => {
    const handleClick = (event, id) => {    
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
      setSelectedMenuId(id);
      setExtendNegoStatus(params.row.quotation.status);
      setExtendNegoDetails(params.row.quotation);
      setCancelQuote(params.row.cancelledQuote);
      setSelectedQuoteId(params.row.quotation.id);
      setSelectedVendorName(params.row.name);
    };

    return (
      <Box>
        <CustomIconButton>
          <MoreVert
            sx={{
              color: "var(--thm-gray)",
              cursor: "pointer",
            }}
            onClick={(e) => handleClick(e, params.row.id)}
          />
        </CustomIconButton>
      </Box>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const valueGetterForDate = (params) => {
    const lastActionDate = params?.row?.quotation?.action_at;
    if (lastActionDate !== null && lastActionDate !== undefined) {
      // Assuming the date format is "YYYY-MM-DD HH:MM:SS IST"
      const dateParts = lastActionDate.split(" ");
      if (dateParts.length === 3) {
        const [datePart, timePart, timeZone] = dateParts;
        const iso8601DateTime = `${datePart}T${timePart}`;
        const parsedDate = new Date(iso8601DateTime);

        // Check if the parsed date is a valid date object
        if (!isNaN(parsedDate)) {
          return parsedDate;
        }
      }
    }
    return null; // Return null for invalid, null, or undefined date values
  };

  const columns = [
    {
      field: "actions",
      headerName: "",
      width: 20,
      filterable: false,
      sortable: false,
      disableExport: true,
      disableColumnMenu: true,
      align: "center",
      renderCell: tripleDotStatusCell,
    },
    {
      field: "name",
      headerName: "VENDOR NAME",
      width: 300,
      renderCell: vendorNameCell,
    },
    {
      field: "quotation.status",
      headerName: "LAST STATUS",
      width: 140,
      renderCell: quoteStatusCell,
      valueGetter: (params) => params?.row?.quotation?.status,
    },
    {
      field: "inquiry.vendor_status",
      headerName: "VENDOR STATUS",
      width: 140,
      renderCell: GroupedIcon,
      valueGetter: (params) => {
        const { is_gst, gstin_no, pan_no, is_approved, pan_status, msme_status, gstin_status, industry_type, industry_type_value } = params.row;
        const { gst_verification, msme_verification, pan_verification } = companyPermissions;
      
        // Determine GST status based on permissions
        let gstStatus = '';
        if (gst_verification === 'Y' && gstin_no && gstin_status ) {
          gstStatus = "GSTIN " + gstin_status;
        } else {
          gstStatus = is_gst === true ? 'GSTIN Exist' : 'GSTIN Not Exists';
        }
      
        let msmeStatus = '';
        if(msme_verification === 'Y' && industry_type === 'MSME' && industry_type_value && msme_status ) {
          msmeStatus = "MSME " + msme_status;
        } else if (msme_verification !== 'Y' &&  industry_type === 'MSME'  && industry_type_value ) {
          msmeStatus = "MSME Exists";
        } else {
          msmeStatus = "MSME Not Exists";
        }

        let panStatus = '';
        if (pan_verification === 'Y' && pan_no && pan_status) {
          panStatus = "PAN " + pan_status;
        }else if (pan_verification !== 'Y' && pan_no ) {
          panStatus = "PAN Exists";
        }else {
          panStatus = "PAN Not Exists";
        }

        const approvalStatus = is_approved === true ? 'Approved' : 'Non-Approved';

        // Conditionally push each status to the array if it exists
        const statuses = [];
        if (approvalStatus) statuses.push(approvalStatus);
        if (gstStatus) statuses.push(gstStatus);
        if (msmeStatus) statuses.push(msmeStatus);
        if (panStatus) statuses.push(panStatus);

        return  `${statuses.join(', ')}`;

      }
    },
    {
      field: "country",
      headerName: "COUNTRY",
      width: 110,
    },
    {
      field: "inquiry.negotiation",
      headerName: "NEGOTIATION",
      width: 110,
      renderCell: negotiationCell,
      valueGetter: (params) => (params?.row?.negotiation ? "Yes" : "No"),
    },
    {
      field: "inquiry.last_quotation_ref",
      headerName: "LAST QUOTATION REF #",
      width: 160,
      renderCell: lastQuotationCell,
      valueGetter: (params) => params?.row?.quotation?.quotation_ref,
    },
    {
      field: "inquiry.action_at",
      headerName: "LAST QUOTATION DATE",
      width: 180,
      type: "date",
      renderCell: lastActionDateCell,
      valueGetter: (params) => {
        const lastActionDate = params?.row?.quotation?.action_at;
        if (lastActionDate !== null && lastActionDate !== undefined) {
          const dateParts = lastActionDate.split(" ");
          if (dateParts.length === 3) {
            const [datePart, timePart, timeZone] = dateParts;
            const iso8601DateTime = `${datePart}T${timePart}`;
            const parsedDate = new Date(iso8601DateTime);
            if (!isNaN(parsedDate)) {
              return parsedDate;
            }
          }
        }
        return null;
      },
      valueFormatter: (params) => {
        return params?.value
          ? moment(params.value).format("DD/MM/YYYY HH:mm:ss") + " IST"
          : "";
      },
    },
  ];

  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <Typography>No data available</Typography>
    </GridOverlay>
  );

  const transactionStatusModal = (event, id, justification) => {
    setAnchorEl2(event.currentTarget);
    setJustificationContent(justification);
    event.stopPropagation();
  };

  const fetchVendorsDetails = async () => {
    let url =
      get_Inquiries_data +
      selectedId +
      "/vendors?embed[]=inquiry&embed[]=action";
    try {
      let response = await axios.get(url, configAxios);
      if (response?.data && response?.data?.vendors) {
        const indexedVendors = response?.data?.vendors
          .map(
            (vendor, idx) => ({...vendor, "idx": idx})
          );
        response.data.vendors = indexedVendors;
        setVendorsDetailsAPIRes(response?.data?.vendors);
        setVendorsDetails(response?.data?.vendors);
        setCountVendor(response?.data?.vendors?.length);
        setFilterData(response?.data?.filter_data);
        setNegoMaxDate(response?.data?.nego_max_date);
        setLoading(false);
        setSelectedItem(null);
      } else {
        // Handle empty data case
        setSelectedItem(null);
        setLoading(false);
        setVendorsDetails([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedItem != null && itemSpecificVendor) {
      let itemVendors = selectedItem?.vendors;
      const tempData = vendorsDetailsAPIRes?.filter((vendor, index) => {
        if (itemVendors.includes(parseInt(vendor.id))) return vendor;
      });

      if (tempData.length === 0) {
        // setVendorsDetails(vendorsDetailsAPIRes);
        setVendorsDetails([]);
      } else {
        setVendorsDetails(tempData);
      }
    } else {
      setVendorsDetails(vendorsDetailsAPIRes);
    }
  }, [selectedItem]);

  useEffect(() => {
    fetchVendorsDetails();
  }, []);

  // Start Extend Mass Negotiation Validity Date drawer script
  const [openExtendNegoDateDrawer, setOpenExtendNegoDateDrawer] = useState(false);
  const [mainMassNegoResponse, setMainMassNegoResponse] = React.useState({});
  
  const CloseExtendNegoDateDrawer = () => {
    setOpenExtendNegoDateDrawer(false);
  };

  const getMassExtendVendorDetails = async () => {
    try {
      let url = getTransactionVendorDetails(selectedId, 'Negotiation');
      const response = await axios.get(url, configAxios);
      
      setMainMassNegoResponse(response.data);
      
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const handleExtendNegoDateDrawer = (event) => {
    event.stopPropagation();
    setOpenExtendNegoDateDrawer(true);
    //Api mass nego call
    getMassExtendVendorDetails();
  }
  // End Extend Mass Negotiation Validity Date drawer script

  return (
    <Box>
      {RFQVendorStyle}
      <Box className="DataGFullScreen-wrap">
        <Box className="Dialog-AppTitle">
          <Typography variant="subtitle2" color="var(--grey-700)">
            VENDORS
            {selectedItem !== null && itemSpecificVendor
              ? ` (${selectedItem?.vendors?.length})`
              : ` (${countVendor})`}
          </Typography>
          {selectedItem !== null ? (
            <Breadcrumbs aria-label="breadcrumb" className="Breadcrumb-Vendor">
              <Typography
                onClick={() => {
                  selectedItem !== null
                    ? setSelectedItem(null)
                    : fetchVendorsDetails();
                }}
                sx={{ cursor: "pointer" }}
                underline="hover"
                color="inherit"
              >
                ALL VENDORS
              </Typography>
              {selectedItem !== null && (
                <Typography>{selectedItem?.name}</Typography>
              )}
            </Breadcrumbs>
          ) : (
            ""
          )}
        </Box>
        <CommonDataGridPro
          loading={loading}
          rows={vendorsDetails}
          columns={columns}
          getRowId={(row) => `${row?.id}-${row?.idx}`}
          headerHeight={40}
          columnHeaderHeight={40}
          getRowHeight={() => "25px"}
          pagination={true}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          Toolbar={CustomGridToolbar}
          NoRowsOverlay={CustomNoRowsOverlay}
          disableRowSelectionOnClick
          className="fullScreenGrid"
          sx={{
            "&.MuiDataGrid-root": {
              border: 0,
            },
            "& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
            {
              borderBottomColor: "bluegray.500",
            },
          }}
        />
      </Box>

      <VendorMenuItems
        openMenu={openMenu}
        sessionID={sessionID}
        vendorsDetails={vendorsDetails}
        selectedQuoteId={selectedQuoteId}
        selectedId={selectedId}
        selectedMenuId={selectedMenuId}
        handleClose={handleClose}
        anchorEl={anchorEl}
        extendNegoStatus={extendNegoStatus}
        extendNegoDetails={extendNegoDetails}
        cancelQuote={cancelQuote}
        inquiryNumber={inquiryNumber}
        vendorCancelQuote={vendorCancelQuote}
        setAnchorEl={setAnchorEl}
        selectedVendorName={selectedVendorName}
        setValidData={(data) => {
          if (data) {
            fetchVendorsDetails()
          }
        } }
      />
      <JustificationPopOver
        open={openMenu2}
        anchorEl={anchorEl2}
        onClose={handlePopoverClose}
        justificationTitle={`Justification (${regretsStatus})`}
        justificationDetails={JustificationContent ? JustificationContent : "-"}
      />
      <ExtendMassNegoValidityDate
        CloseExtendNegoDateDrawer={CloseExtendNegoDateDrawer}
        openExtendNegoDateDrawer={openExtendNegoDateDrawer}
        sessionID={sessionID}
        mainMassNegoResponse={mainMassNegoResponse}
        inquiryNumber={inquiryNumber}
        selectedId={selectedId}
        setValidData={(data) => {
          if (data) {
            fetchVendorsDetails()
          }
        } }
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackbar}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>

  );

}

export default VendorList;
