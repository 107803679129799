import axios from "axios";
import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Chip,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Popover,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Alert,
  Snackbar,
  Backdrop,
  CircularProgress,
  FormHelperText,
  FormControl,
  Select,
  List,
  ListItem,
  MenuItem,
  Tab,
  Tabs,
  Collapse,
  Paper,
  Divider,
} from "@mui/material";
import { MoreVert, Add, Remove, GroupAddOutlined } from "@mui/icons-material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Grid from "@mui/material/Unstable_Grid2";
import {
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridOverlay,
  getGridSingleSelectOperators,
  useGridRootProps,
  DataGridPro
} from "@mui/x-data-grid-pro";
import RFQMenuItems from "../../../utils/RFQList/rfqMenuItems";
import { Tune } from "@mui/icons-material";
import QcsGridDrawer from "../../../components/RFQList/drawer/qcsGridDrawer/qcsGridDrawer";
import PrGridDrawer from "../../../components/RFQList/drawer/prGridDrawer/prGridDrawer";
import moment from "moment";
import { CustomIconButton } from "../../../utils/RFQList/utils";
import AccountDateRangePicker from "../../../utils/RFQList/accountDatePicker";
import RFQSearchModal from "../../../components/RFQList/modal/rfqSearchModal/rfqSearchModal";
import JustificationPopOver from "../../../common/RFQList/justificationPopOver";
import {
  all_templates,
  getAccountModulePermission,
  getFeaturePermission,
  getMasterPermission,
  getUserInfo,
  get_Inquiries,
  q_plus_screen,
  tbQuotedVendor,
  users_Data,
  validity_date_update,
  vendx_connect,
  fetchcompanywisedateDatas,
  get_All_Inquiries_Data,
  getR2aDetails
} from "../../../config/apiUrl";
import { RFQDataGStyle } from "./Style";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import ItemWiseVendor from "../../../components/RFQList/itemWiseVendor/itemWiseVendor";
import LinkUnlinkUser from "../../../components/RFQList/linkUnlinkUser/linkUnlinkUser";
import {
  ExcelIcon,
  HexagonIcon,
  ItemSpecificVendorIcon,
  GooglePdfIcon,
  GoogleSheetsIcon
} from "../../../components/SvgIcon/SvgIcon";
import CommonDataGridPro from "../../../components/RFQList/commonDataGridPro/commonDataGridPro";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setRfqListInfo } from "../../../redux/actions/rfqListAction";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { setUserInfo } from "../../../redux/actions/setUserInfo";
import DaterangepickerCustom from "../../../utils/RFQList/accountDatePicker";
import {
  OPEN_SNACKBAR,
  SET_ACCOUNT_MODULE_PERMISSION,
  SET_ACCOUNT_MODULE_PERMISSION_ERROR,
  SET_FEATURE_PERMISSION,
  SET_FEATURE_PERMISSION_ERROR,
  SET_MASTER_PERMISSION,
  SET_MASTER_PERMISSION_ERROR,
  SET_USER_INFO,
  SET_USER_INFO_ERROR
} from "../../../redux/constants/type";
import { setMasterPermission } from "../../../redux/actions/permissionsAction";
import InnerHTML from "dangerously-set-html-content";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListIcon from '@mui/icons-material/ListOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAltOutlined';


import $ from "jquery";
import jQuery from "jquery";

import { URL_MAPPING } from "../../../config/host-mapping";

export const customBooleanFilterOperator = {
  label: "Is",
  value: "is"
};

const PageToolbar = styled("div")(({ theme }) => ({
  background: "#ffffff",
  position: "sticky",
  top: 0,
  width: "100%",
  minHeight: "54px",
  padding: "6px 16px",
  zIndex: "99"
}));

export function CustomExpandIcon() {
  return <Add className="icon" />;
}

export function CustomCollapseIcon() {
  return <Remove className="icon" />;
}

const currentHost = window.location.host;
const parentHostURI =
  URL_MAPPING[currentHost]["parent_host"] ||
  URL_MAPPING["default"]["parent_host"];

export function loadScripts(sources) {
  sources.forEach((src) => {
    var script = document.createElement("script");
    script.src = src;
    script.async = false; //<-- the important part
    document.body.appendChild(script); //<-- make sure to append to body instead of head
  });
}
export function loadStyle(sources) {
  sources.forEach((src) => {
    var linkElement = document.createElement("link");
    linkElement.setAttribute("rel", "stylesheet");
    linkElement.setAttribute("type", "text/css");
    linkElement.setAttribute("href", src);
    document.head.appendChild(linkElement);
  });
}

export function removeJSFile(filename, filetype) {
  var targetelement =
    filetype == "js" ? "script" : filetype == "css" ? "link" : "none"; //determine element type to create nodelist from
  var targetattr =
    filetype == "js" ? "src" : filetype == "css" ? "href" : "none"; //determine corresponding attribute to test for
  var allsuspects = document.getElementsByTagName(targetelement);
  for (var i = allsuspects.length; i >= 0; i--) {
    //search backwards within nodelist for matching elements to remove
    if (
      allsuspects[i] &&
      allsuspects[i].getAttribute(targetattr) != null &&
      allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1
    )
      allsuspects[i].parentNode.removeChild(allsuspects[i]); //remove element by calling parentNode.removeChild()
  }
}

function RfqDataGrid(props) {
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = React.useState(null);
  const [categoryId, setCategoryId] = React.useState(null);
  const [r2aId, setR2aId] = React.useState(null);
  const [selecetedRows, setSelectedRows] = React.useState(null);
  const [filterDataChip, setfilterDataChip] = React.useState(null);
  const [userData, setUserData] = React.useState([]);
  const [templateNames, setTemplateNames] = React.useState([]);
  const [selectedRowIds, setSelectedRowIds] = React.useState([]);
  const [templateId, setTemplateId] = React.useState([]);
  const [itemSpecificVendor, setItemSpecificVendor] = React.useState([]);
  const [openPrDrawer, setOpenPrDrawer] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [vendorCancelQuote, setVendorCancelQuote] = React.useState(false);
  const [severity, setseverity] = React.useState("");
  const [SnackMessage, setSnackMessage] = React.useState("");
  const [openValidityDateModal, setOpenValidityDateModal] = React.useState(false);
  const [RFQNoForValidityDateModal, setRFQNoForValidityDateModal] = React.useState("false");
  const [statusClosed, setStatusClosed] = React.useState(false);
  const [statusOngoing, setStatusOngoing] = React.useState(false);
  const [openQcsDrawer, setOpenQcsDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
  const [linkDataFlag, setLinkDataFlag] = React.useState(false);
  const [openLinkUnlinkBox, setOpenLinkUnlinkBox] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [justificationMessage, setJustificationMessage] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [isPriortized, setIsPriortized] = React.useState(false);
  const [anchorElTest, setAnchorElTest] = React.useState(null);
  const [hoveredItemId, setHoveredItemId] = React.useState(null);
  // const [selectedDateRange, setSelectedDateRange] = React.useState(null);
  const [filteredListData, setFilteredListData] = React.useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState(null); //
  const [selecetedValidDate, setSelecetedValidDate] = React.useState(null);
  const [InquiryData, setInquiryData] = React.useState({});
  const [selecetedRowValidDate, setSelecetedRowValidDate] = React.useState(null);
  const [dateValues, setDateValues] = React.useState(null);
  const [filteredUserData, setFilteredUserData] = React.useState([]); // Initialize filteredUserData state
  // const [RFQListData, setRFQListData] = React.useState([]);
  const RFQListData = useSelector((state) => state.rfqListReducer.rfqListInfo);
  const [filteredRows, setFilteredRows] = React.useState(RFQListData);
  const today = dayjs();
  const [value, setValue] = React.useState([null, null]);
  const [selectedValueOptions, setSelectedValueOptions] = React.useState([]);
  const [JustificationContent, setJustificationContent] = React.useState("");
  const [filteredData, setFilteredData] = React.useState([]);
  const [ValidRFQDate, setValidRFQDate] = React.useState(null);
  const [MaxRfqValidity, setMaxRfqValidity] = React.useState('')
  const [validitychange, setupdatevalidity] = React.useState(false)
  const [Error, setError] = React.useState(false)
  // const handleValueOptionClick = (valueOption) => {
  //   setSelectedValueOptions([...selectedValueOptions, valueOption]);
  // };
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const Permisison = useSelector((state) => state.permissionsReducer);
  const [Profile, setProfile] = React.useState({});
  const [MasterPermission, setMasterPermission] = React.useState({});
  const [FeaturePermission, setFeaturePermission] = React.useState({});
  const [AccountPermission, setAccountPermission] = React.useState({});
  const [HasPerformedUpdate, setHasPerformedUpdate] = React.useState(false);
  const [flagFromChild, setFlagFromCHild] = React.useState(false);
  const [filterModel, setFilterModel] = React.useState({
    items: []
  });

  const [InquiryCreatedBy, setInquiryCreatedBy] = React.useState();
  const [AnchorElTBquoted, setAnchorElTBquoted] = React.useState(false)
  const [LoadingPerl, setLoadingPerl] = React.useState(true)
  const [QuotedVendorTB, setQuotedVendorTB] = React.useState({
    __html: "",
  });
  const initialLinkUnlinkData = {
    inquiryName: null,
    inquiryNo: null,
    linkedUserId: null,
    createdBy: null
  };
  const [jsonData, setJsonData] = React.useState(null);


  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(fetchcompanywisedateDatas);
        const fetchedData = response.data;
        setJsonData(fetchedData);
      } catch (err) {
        setError('Error fetching data');
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, []);
  // const sessionID = "1697172904246510.723257869618774";
  // const sessionID = "1733115396230670.0749474816460314";

  // const menulabels = {
  //     extend_validity_to_rfq: "Extend Validity to RFQ",
  //     send_rfq_to_new_vendor: "Send RFQ To New Vendor",
  //     view_multiple_solution: "View Multiple Solution",
  //     priority: "Priority",
  //     not_priority: "Not Priority",
  //     inquiry_logs: "Inquiry Logs",
  //     link_unlink_user: "Link/Unlink User",
  //     r2a_reports: "R2A Reports",
  //     close_rfq: "Close RFQ",
  //     extend_validity_thread: "Extend Validity thread",
  //     technical_bid_preview: "Technical Bid Preview"
  // };

  // const filterLabels = {
  //     multiple_solution: "Multiple Solution",
  //     r2a: "R2A",
  //     sealed: "Sealed",
  //     prioritized: "Prioritized",
  //     contract: "Contract",
  //     multi_plant: "Multi Plant",
  //     technical_bid: "Technical Bid"
  //     // slab : 'slab'
  // };

  const sessionID = props.session_id;

  const menulabels = props.inquiry_menu_labels;
  
  const filterLabels = props.filter_labels;

  React.useEffect(() => {
    window.$ = $;
    window.jQuery = jQuery;
    window.jq = jQuery;
  }, []);

  React.useEffect(() => {
    if (RFQListData.length > 0) {
      const currentFilterItems = [...filterModel?.items];

      // Function to check if a filter with a specific id already exists
      const isFilterWithIdExists = (id) => currentFilterItems.some(item => item.id == id);

      // Add "vendor_count" filter if it doesn't already exist
      if ("filter_param" in props && "quoted_vendors" in props.filter_param) {
        const vendorCountFilterId = 86318;
        if (!isFilterWithIdExists(vendorCountFilterId)) {
          currentFilterItems.push({
            field: "vendor_count",
            operator: "quoted",
            id: vendorCountFilterId,
            value: props.filter_param.quoted_vendors.toString()
          });
        }
      }

      // Add "filter_data" filter if it doesn't already exist
      if ("filter_param" in props && "rfq_inclusion" in props.filter_param) {
        const filterDataFilterId = 95364;
        if (!isFilterWithIdExists(filterDataFilterId)) {
          currentFilterItems.push({
            field: "filter_data",
            operator: "isAnyOf",
            id: filterDataFilterId,
            value: props?.filter_param?.rfq_inclusion
          });
        }
      }

      // Create the updated filter model
      const newFilterModel = {
        items: currentFilterItems
      };

      setFilterModel(newFilterModel); // Update the filter model
    }
  }, [RFQListData]);


  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const configAxios = {
    headers: { Authorization: `Bearer ${sessionID}` }
  };

  React.useEffect(() => {
    dispatch(setUserInfo(sessionID));
  }, []);

  const [linkUnlinkData, setLinkUnlinkData] = React.useState(
    initialLinkUnlinkData
  );
  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };

  const receiveFlagFromChild = (flag) => {
    setFlagFromCHild(flag);
  };

  const openRfqNoLinkQplusScreen = (inqId) => {
    const form = document.createElement("form");
    form.target = "_blank";
    form.action = q_plus_screen;
    form.method = "post";

    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = "inq_id";
    hiddenInput.value = inqId;

    form.appendChild(hiddenInput);
    document.body.appendChild(form);
    form.submit();
  };

  const openMenu = Boolean(anchorEl);
  const openMenu2 = Boolean(anchorEl2);
  const openTest = Boolean(anchorElTest);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0
  });

  const handleChange = async (event) => {
    if (event[0] == null && event[1] == null) {
      setValue([null, null]);
    }
    if (event[0] !== null && event[1] !== null) {
      const newStartDate = dayjs(event[0]);
      const newEndDate = dayjs(event[1]);

      if (newStartDate.year().toString().length > 3 && newEndDate.year().toString().length > 3) {
        if (newStartDate.isValid() && newEndDate.isValid()) {
          setValue([event[0], event[1]]);
          if (!newStartDate.isSame(value[0]) && !newEndDate.isSame(value[1])) {
            setValue(event);
          }
          if (!newEndDate.isSame(value[1])) {
            setValue([event[0], event[1]]);
          }
        } else {
          console.error("Invalid date input");
        }
      }
    }
  };


  React.useEffect(() => {
    const currentDate = new Date();
    const endDate = dayjs(currentDate).format('YYYY-MM-DD');

    const lastYearDate = new Date();
    lastYearDate.setFullYear(currentDate.getFullYear() - 1);
    const startDate = dayjs(lastYearDate).format('YYYY-MM-DD');
    setValue([
      dayjs(startDate),
      dayjs(endDate)
    ]);
    const ranges = [
      { start: startDate, end: endDate }
    ]
  }, []);



  function CustomGridToolbar() {
    return (
      <React.Fragment>
        <GridToolbarContainer className="datagrid-toolbar">
          <Grid
            container
            spacing={0}
            sx={{
              width: "100%",
              pt: 1,
              "&.MuiGrid2-root": {
                paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" }
              }
            }}
          >
            {/* <Grid xs={9} sm={8} md={5} xl={4}> */}
            <Grid xs={12} sm={12} md={5} xl={4}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box className="QsearchBox">
                  <GridToolbarQuickFilter
                    className="DataGrid-SearchBar"
                    placeholder="Search..."
                    sx={{ pb: 0 }}
                    debounceMs={1000}
                  />
                  <IconButton
                    variant=""
                    color="default"
                    size="small"
                    onClick={() => OpenSearchFilterModal()}
                  >
                    <Tune />
                  </IconButton>
                </Box>
                {rowSelectionModel !== null &&
                  rowSelectionModel?.length > 1 &&
                  Permisison?.accountModulePermission?.["Transaction Access"]
                    ?.module_activated == "Y" ? (
                  <React.Fragment>
                    <Tooltip
                      title="Multiple RFQ Link-Unlink"
                      placement="bottom"
                    >
                      <IconButton
                        variant=""
                        color="default"
                        size="small"
                        onClick={() => handleOpenLinkUnlinkBox()}
                      >
                        <GroupAddOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Export RFQ Link-Unlink Details"
                      placement="bottom"
                    >
                      <IconButton
                        variant=""
                        color="default"
                        size="small"
                        onClick={() => downloadExcel()}
                      >
                        <ExcelIcon />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
            <Grid
              xs={3}
              sm={4}
              sx={{ display: { xs: "none", sm: "none", md: "none" } }}
            >
              {/* <SelectGridView /> */}
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={7}
              xl={8}
              sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            >
              <Box className="toolbar-right">
                <Box>
                  <DaterangepickerCustom
                    value={value}
                    pastthreeyeardate={true}
                    jsonData={jsonData}
                    setDateRangeValue={(value) => {
                      handleChange(value);
                    }}
                  />
                </Box>
                <Box className="dg-filter">
                  <GridToolbarColumnsButton />
                  <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
                  <GridToolbarFilterButton />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer>

        <GridToolbarContainer
          className="datagrid-toolbar"
          sx={{ display: { sm: "flex", md: "none" } }}
        >
          <Grid
            container
            spacing={0}
            sx={{ width: "100%", alignItems: "center" }}
          >
            <Grid xs={6} md={6}>
              <Box>
                <DaterangepickerCustom
                  pastthreeyeardate={true}
                  value={value}
                  jsonData={jsonData}
                  setDateRangeValue={(value) => {
                    handleChange(value);
                  }}
                />
              </Box>
            </Grid>
            <Grid
              xs={6}
              md={6}
              sx={{ textAlign: { xs: "right", sm: "right" } }}
            >
              <Box className="dg-filter">
                <GridToolbarColumnsButton />
                <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
                <GridToolbarFilterButton />
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer>
      </React.Fragment>
    );
  }

  const handleCloseDialogue = () => {
    setOpenDialogueBox(false);
  };

  const handleCloseLinkUnlinkBox = () => {
    setOpenLinkUnlinkBox(false);
  };

  const handleOpenLinkUnlinkBox = () => {
    if (selecetedRows && selecetedRows.length > 0) {
      const updatedLinkUnlinkData = {
        ...initialLinkUnlinkData,
        inquiryName: selecetedRows.map((row) => row.number).join(", "),
        inquiryNo: selecetedRows.map((row) => row.id).join(", "),
        linkedUserId: selecetedRows
          .map((row) => row.linked_user_ids)
          .join(", "),
        createdBy: selecetedRows.map((row) => row.created_by).join(", ")
      };

      // Set the updated linkUnlinkData
      setLinkUnlinkData(updatedLinkUnlinkData);
    }
    // Open the LinkUnlinkUser component here
    setOpenLinkUnlinkBox(true);
  };

  const handleTbQuotedVendor = async (row) => {
    console.log('tbQuotedVendor', row);
    const formData = new FormData();
    formData.append("inquiry_id", row.id);
    formData.append("action", 100);

    formData.append("master_type", row?.item_specific_vendor
      ? "item"
      : "category");
    formData.append("product_id", row?.inquiry_categories?.id);

    loadStyle([
      parentHostURI + "/css/masters/admin-jquery-ui.min.css",
      parentHostURI + "/jquery_grid/css/ui.jqgrid.css",
      parentHostURI + "/css/masters/jquery-ui-1.8.20.custom.css",
      parentHostURI + "/css/bluestar_blue.css",
    ]);

    loadScripts([
      parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
      parentHostURI + "/jquery_grid/javascript/jquery.jqGrid.min.js"
    ]);
    setAnchorElTBquoted(true);
    setLoadingPerl(true);
    try {
      const response = await axios.post(tbQuotedVendor, formData);
      if (response) {
        console.log('response', response);
        setQuotedVendorTB(response.data);
        setLoadingPerl(false);
      } else {
        removeJSFile(parentHostURI + "/css/masters/admin-jquery-ui.min.css", "css");
        removeJSFile(parentHostURI + "/jquery_grid/css/ui.jqgrid.css", "css");
        removeJSFile(parentHostURI + "/css/masters/jquery-ui-1.8.20.custom.css", "css");
        removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
        removeJSFile(parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js", "js");
        removeJSFile(parentHostURI + "/jquery_grid/javascript/jquery.jqGrid.min.js", "js");
        // Handle empty data case
      }
    } catch (error) {
      removeJSFile(parentHostURI + "/css/masters/admin-jquery-ui.min.css", "css");
      removeJSFile(parentHostURI + "/jquery_grid/css/ui.jqgrid.css", "css");
      removeJSFile(parentHostURI + "/css/masters/jquery-ui-1.8.20.custom.css", "css");
      removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
      removeJSFile(parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js", "js");
      removeJSFile(parentHostURI + "/jquery_grid/javascript/jquery.jqGrid.min.js", "js");
      console.error("Error fetching data:", error);
    }
  }

  const handleTbQuotedVendorClose = () => {
    // window.location.reload()
    dispatch(setRfqListInfo(sessionID, [
      {
        "start": startdate,
        "end": enddate
      }
    ]));
    setAnchorElTBquoted(false);
    setQuotedVendorTB({ __html: "" });
  };

  const rfqStatusCell = (params) => {
    const { value } = params;
    const isClosedStatus = value === "Closed";
    setStatusClosed(isClosedStatus);
    return (
      <Box sx={{ textTransform: "uppercase" }}>
        {isClosedStatus && (
          <Chip
            size="small"
            label="Closed"
            sx={{ cursor: "pointer" }}
            variant="soft"
            color="default"
            aria-haspopup="true"
            onClick={(e) =>
              transactionStatusModal(e, params.row.id, params.row.justification)
            }
          />
        )}
        {!isClosedStatus && (
          <Chip
            size="small"
            label={
              value === "Ongoing"
                ? "Ongoing"
                : value === "Expired"
                  ? "Expired"
                  : "Unknown"
            }
            variant="soft"
            color={
              value === "Ongoing"
                ? "success"
                : value === "Expired"
                  ? "error"
                  : "default"
            }
          />
        )}
      </Box>
    );
  };

  const rfqStartDateCell = (params) => {
    const { value } = params;
    const formatDate = moment(value, "YYYY-MM-DD HH:mm:ss").format(
      "DD/MM/YYYY HH:mm:ss a"
    );
    const splittedArray = formatDate?.split(" ");
    return (
      <Box>
        <Typography variant="body2" component="div">
          {splittedArray[0]}
        </Typography>
        <Typography variant="caption" component="div">
          {splittedArray[1] + " " + "IST"}
        </Typography>
      </Box>
    );
  };

  const rfqEndDateCell = (params) => {
    const { value } = params;

    const formatDate = moment(value, "YYYY-MM-DD HH:mm:ss").format(
      "DD/MM/YYYY HH:mm:ss"
    );
    const splittedArray = formatDate?.split(" ");
    return (
      <Box sx={{ cursor: "pointer" }}>
        <Typography variant="body2" component="div">
          {splittedArray[0]}
        </Typography>
        <Typography variant="caption" component="div">
          {splittedArray[1] + " " + "IST"}
        </Typography>
      </Box>
    );
  };

  const negotiationStatusCell = (params) => {
    const negotiationData = params?.row?.negotiation;
    return negotiationData === true ? (
      <Typography variant="body2">Yes</Typography>
    ) : (
      <Typography variant="body2">No</Typography>
    );
  };

  const QuotedFrom = (params) => {
    const { value, id, row } = params;
    const isCancelQuote = row.status === "Closed";
    const isOngoingStatus = row.status === "Ongoing";
    const quoteNum = value?.quoted; // Extracting the first part before the space
    const quoteTotalNum = value?.requested; // Extracting the two characters after the space
    const slash = " / ";
    const handleOpenDialogue = () => {
      setOpenDialogueBox(true);
      setSelectedId(id);
      setLinkUnlinkData([{ inquiryNo: row.number }]);
      setfilterDataChip(row.filter_data);
      setTemplateId(row.template_id);
      setItemSpecificVendor(row.item_specific_vendor);
      setVendorCancelQuote(isCancelQuote)
      setStatusOngoing(isOngoingStatus)
    };
    return (
      <React.Fragment>
        <Box component="div">
          <Box
            component="div"
            onClick={() => handleOpenDialogue()}
            sx={{ cursor: "pointer" }}
          >
            <span style={{ color: "var(--primary-400)" }}>
              {quoteNum !== null &&
                quoteNum !== "null" &&
                quoteTotalNum !== "NULL"
                ? quoteNum
                : 0}
            </span>
            <span style={{ color: "var(--primary-400)" }}>{slash}</span>
            <span>
              {quoteTotalNum !== null &&
                quoteTotalNum !== "null" &&
                quoteTotalNum !== "NULL"
                ? quoteTotalNum
                : 10}
              &nbsp; VENDORS
            </span>
          </Box>
        </Box>
      </React.Fragment>
    );
  };

  const qcsStatusCell = (params) => {
    const { id, row } = params;
    const handleQcsDrawerOpen = () => {
      setOpenQcsDrawer(true);
      setSelectedId(row.id);
      setLinkUnlinkData([{ inquiryNo: row.number }]);
    };
    const isClickable = row.qcs_status !== 'N/A';
    return (
      <Box>
        <Typography
          color={isClickable ? "primary" : "textSecondary"}
          variant="body2"
          sx={{
            cursor: isClickable ? "pointer" : "default",
            textDecoration: isClickable ? "underline" : "none",
          }}
          onClick={isClickable ? handleQcsDrawerOpen : undefined}
        >
          {params.row.qcs_status}
        </Typography>
      </Box>
    );
  };

  const prStatusCell = (params) => {
    const { id, row } = params;
    const handlePrDrawerOpen = () => {
      setOpenPrDrawer(true);
      setSelectedId(row.id);
      setLinkUnlinkData([{ inquiryNo: row.number }]);
    };
    return (
      <Box>
        {params.row?.group_id ? <Typography
          color="primary"
          variant="body2"
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={handlePrDrawerOpen}
        >
          Click here
        </Typography> :
          <Typography
            variant="body2"
          >
            -
          </Typography>}
      </Box>
    );
  };

  const itemWiseVendorCell = (params) => {
    const { id, row } = params;
    const isClosedStatus = row.status === "Closed"
    const isOngoingStatus = row.status === "Ongoing"
    const handleOpenDialogue = () => {
      setOpenDialogueBox(true);
      setSelectedId(id);
      setLinkUnlinkData([{ inquiryNo: row.number }]);
      setfilterDataChip(row.filter_data);
      setTemplateId(row.template_id);
      setItemSpecificVendor(row.item_specific_vendor);
      setVendorCancelQuote(isClosedStatus)
      setStatusOngoing(isOngoingStatus)
    };
    return (
      <Tooltip title="Itemized Vendor" placement="bottom">
        <CustomIconButton onClick={(e) => handleOpenDialogue(e)}>
          <AddBoxOutlinedIcon
            sx={{
              fontSize: "24px",
              color: "var(--grey-600)",
              "&:hover": {
                cursor: "pointer"
              }
            }}
          />
        </CustomIconButton>
      </Tooltip>
    );
  };

  const typeCell = (params) => {
    const value = params.row.item_specific_vendor;
    return (
      <Box sx={{ cursor: "pointer" }}>
        {value === true ? "Item Specific" : "Category Specific"}
      </Box>
    );
  };

  const handlePopoverClose = () => {
    setAnchorEl2(null);
  };

  const transactionStatusModal = (event, id, justification) => {
    setAnchorEl2(event.currentTarget);
    setJustificationContent(justification);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpenTest = (event, itemId) => {
    setAnchorElTest(event.currentTarget);
    setHoveredItemId(itemId);
  };

  const handlePopoverCloseTest = () => {
    setAnchorElTest(null);
    setHoveredItemId([]);
  };

  const tripleDotStatusCell = (params) => {
    const updatedLinkUnlinkData = {
      ...initialLinkUnlinkData,
      inquiryName: params.row.number,
      inquiryNo: params.row.id,
      linkedUserId: params.row.linked_user_ids,
      createdBy: params.row.created_by
    };
    const handleClick = (event, id) => {
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
      setCategoryId(params?.row?.inquiry_categories?.id);
      setR2aId(params?.row?.r2a_id);
      setIsPriortized(params?.row?.filter_data?.prioritized);
      setSelectedId(id);
      setLinkUnlinkData(updatedLinkUnlinkData);
      setLinkDataFlag(true);
      setInquiryData(params?.row);
    };
    return (
      <Box>
        <CustomIconButton onClick={(e) => handleClick(e, params.row.id)}>
          <MoreVert
            sx={{
              color: "var(--thm-gray)",
              cursor: "pointer",
              rowSelectionModel
            }}
          />
        </CustomIconButton>
      </Box>
    );
  };

  const tagsFilterOperators = getGridSingleSelectOperators()
    .filter((operator) => operator.value === "isAnyOf")
    .map((operator) => {
      const newOperator = { ...operator };
      const newGetApplyFilterFn = (filterItem, column) => {
        return (params) => {
          if (filterItem?.value) {
            let isOk = true;
            filterItem?.value.forEach((fv) => {
              if (isOk == true && params.value[fv] == false) {
                isOk = false;
              }
            });
            return isOk;
          }
        };
      };
      newOperator.getApplyFilterFn = newGetApplyFilterFn;
      return newOperator;
    });

  const valueGetterForDate = (params) => {
    const { value } = params;
    // Assuming the date format is "YYYY-MM-DD HH:MM:SS IST"
    const dateParts = value.split(" ");
    if (dateParts.length === 3) {
      const [datePart, timePart, timeZone] = dateParts;
      const iso8601DateTime = `${datePart}T${timePart}`;
      const parsedDate = new Date(iso8601DateTime);

      // Check if the parsed date is a valid date object
      if (!isNaN(parsedDate)) {
        return parsedDate;
      }
    }
    return null; // Return null for invalid date strings
  };

  const QuotedFromFilterInput = (props) => {
    const rootProps = useGridRootProps();
    const { item, applyValue, focusElementRef = null } = props;
    const filterTimeout = React.useRef();
    const [applying, setIsApplying] = React.useState(false);
    const [filterValueState, setFilterValueState] = React.useState(
      item.value ?? ""
    );
    React.useEffect(() => {
      const itemValue = item.value ?? "";
      setFilterValueState(itemValue);
    }, [item.value]);
    const updateFilterValue = (value) => {
      clearTimeout(filterTimeout.current);
      setFilterValueState(value);
      setIsApplying(true);
      filterTimeout.current = setTimeout(() => {
        setIsApplying(false);
        applyValue({ ...item, value: value });
      }, rootProps.filterDebounceMs);
    };

    const handleQuotationFromFilterChange = (event) => {
      const itemValue = event.target.value;
      setFilterValueState(itemValue);
      updateFilterValue(itemValue);
    };

    return (
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "end",
          height: 48,
          pl: "20px"
        }}
      >
        <TextField
          name="lower-bound-input"
          placeholder="Value"
          label="Value"
          variant="standard"
          value={filterValueState}
          onChange={handleQuotationFromFilterChange}
          inputRef={focusElementRef}
          sx={{ mr: 2 }}
        />
      </Box>
    );
  };

  const rfqVariantFilterData = [
    // { value: "slab", label: "Slab" },
    { value: "sealed", label: filterLabels.sealed },
    { value: "prioritized", label: filterLabels.prioritized },
    { value: "contract", label: filterLabels.contract },
    { value: "multiple_solution", label: filterLabels.multiple_solution },
    { value: "multi_plant", label: filterLabels.multi_plant },
    { value: "r2a", label: filterLabels.r2a },
    // Permisison?.accountModulePermission?.["Technical Bid"]?.module_activated == "Y" ? { value: "technical_bid", label: filterLabels.technical_bid } : ''
  ]
  if (Permisison?.accountModulePermission?.["Technical Bid"]?.module_activated == "Y") {
    rfqVariantFilterData.push({ value: "technical_bid", label: filterLabels.technical_bid })
  }

  const columns = [
    {
      field: "actions",
      headerName: "",
      width: 40,
      align: "center",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      renderCell: tripleDotStatusCell
    },
    {
      field: "addBox",
      headerName: "",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 50,
      align: "center",
      disableExport: true,
      renderCell: itemWiseVendorCell
    },
    {
      field: "filter_data",
      headerName: "RFQ VARIANT",
      sortable: false,
      filterable: true,
      disableColumnMenu: false,
      type: "singleSelect",
      valueOptions: rfqVariantFilterData,
      filterOperators: tagsFilterOperators,
      width: 90,
      disableExport: true,
      align: "center",
      renderCell: (params) => {
        const { value, id } = params;
        return (
          <Box>
            {value.multi_plant ||
              value.sealed ||
              value.prioritized ||
              value.contract ||
              value.multiple_solution ||
              value.r2a ||
              (value.technical_bid && rfqVariantFilterData.some(option => option.value == 'technical_bid')) ? (
              <>
                <IconButton
                  onMouseEnter={(e) => handlePopoverOpenTest(e, id)}
                  onMouseLeave={handlePopoverCloseTest}
                >
                  <HexagonIcon
                    aria-owns={openTest ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    // onClick={handlePopoverOpenTest}
                    onMouseEnter={(e) => handlePopoverOpenTest(e, id)}
                    onMouseLeave={handlePopoverCloseTest}
                    sx={{ color: "var(--grey-600)" }}
                  />
                </IconButton>
              </>
            ) : (
              <IconButton disabled>
                <HexagonIcon sx={{ color: "var(--grey-400)" }} />
              </IconButton>
            )}

            {(value.multi_plant ||
              value.sealed ||
              value.prioritized ||
              value.contract ||
              value.multiple_solution ||
              value.r2a ||
              value.technical_bid) && (
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: "none",
                    "&.MuiPopover-root .MuiPaper-root": {
                      maxWidth: "300px",
                      padding: "5px",
                      "& .MuiChip-root": {
                        margin: "5px"
                      },
                      cursor: "pointer"
                    }
                  }}
                  open={hoveredItemId === id}
                  anchorEl={anchorElTest}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  onClose={handlePopoverCloseTest}
                  disableRestoreFocus
                >
                  {params?.row?.filter_data?.prioritized && (
                    <Chip label={filterLabels.prioritized} size="small" />
                  )}
                  {params?.row?.filter_data?.multi_plant && (
                    <Chip label={filterLabels.multi_plant} size="small" />
                  )}
                  {/* {params?.row?.filter_data?.slab && (
                    <Chip label="Slab" size="small" />
                  )} */}
                  {params?.row?.filter_data?.contract && (
                    <Chip label={filterLabels.contract} size="small" />
                  )}
                  {params?.row?.filter_data?.sealed && (
                    <Chip label={filterLabels.sealed} size="small" />
                  )}
                  {params?.row?.filter_data?.multiple_solution && (
                    <Chip label={filterLabels.multiple_solution} size="small" />
                  )}
                  {params?.row?.filter_data?.r2a && (
                    <Chip label={filterLabels.r2a} size="small" />
                  )}
                  {Permisison?.accountModulePermission?.["Technical Bid"]?.module_activated == "Y" &&
                    params?.row?.valid_date_tb !== null && params?.row?.filter_data?.technical_bid && (
                      <Chip label={filterLabels.technical_bid} size="small" />
                    )}
                </Popover>
              )}
          </Box>
        );
      }
    },
    {
      field: "number",
      headerName: "RFQ NO.",
      width: 315,
      renderCell: (params) => {
        const { id } = params;
        return (
          <Stack
            direction="row"
            justifyContent="between"
            alignItems="center"
            sx={{
              width: "100%",
              height: "100%",
              "& .MuiButtonBase-root": {
                visibility: { md: "hidden", sm: "visible" },
                marginLeft: "auto"
              },
              "&:hover .MuiButtonBase-root": {
                visibility: "visible"
              },
              cursor: "pointer"
            }}
          >
            <Box
              sx={{
                width: "calc(100% - 30px)",
                alignItems: "center",
                display: "flex",
                gap: "4px"
              }}
            >
              <Tooltip title={params.value} placement="bottom">
                <Typography
                  variant="subtitle2"
                  component="h4"
                  fontWeight={700}
                  noWrap
                  sx={{
                    color: "text.primary",
                    textDecoration: "underline"
                  }}
                  onClick={() => openRfqNoLinkQplusScreen(id)}
                >
                  {params.value}
                </Typography>
              </Tooltip>
              {params?.row?.item_specific_vendor === true ? (
                <Tooltip title="Itemize RFQ" placement="bottom">
                  <ItemSpecificVendorIcon />
                </Tooltip>
              ) : (
                ""
              )}
            </Box>
            <Tooltip title="View Details" placement="bottom">
              <IconButton
                aria-label="View Details"
                size="small"
                onClick={() => openRfqNoLinkQplusScreen(id)}
              >
                <LaunchRoundedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
      valueGetter: (params) => params.row.number
    },

    {
      field: "group_id",
      headerName: "GROUP RFQ NO.",
      width: 110,
      align: "right",
      renderCell: (params) => {
        return (
          <Box>
            {!params?.value || params?.value?.length === 0 ? (
              <Typography variant="body2" fontWeight={500}>
                -
              </Typography>
            ) : (
              <Typography variant="body2" fontWeight={500}>
                {params.value}
              </Typography>
            )}
          </Box>
        );
      }
    },
    {
      field: "status",
      headerName: "TRANSACTION STATUS",
      width: 160,
      renderCell: rfqStatusCell
    },
    {
      field: "vendor_count",
      headerName: "VENDOR(S) RESPONSE",
      width: 160,
      disableExport: true,
      align: "left",
      filterOperators: [
        {
          value: "request",
          label: "Requested",
          getApplyFilterFn: (filterItem) => {
            if (
              !filterItem.field ||
              !filterItem.value ||
              !filterItem.operator
            ) {
              return null;
            }
            return ({ value }) => {
              return Number(value.requested) == Number(filterItem.value);
            };
          },
          InputComponent: QuotedFromFilterInput
        },
        {
          value: "quoted",
          label: "Responded",
          getApplyFilterFn: (filterItem) => {
            if (
              !filterItem.field ||
              !filterItem.value ||
              !filterItem.operator
            ) {
              return null;
            }

            return ({ value }) => {
              return Number(value.quoted) == Number(filterItem.value);
            };
          },
          InputComponent: QuotedFromFilterInput
        }
      ],
      renderCell: QuotedFrom,
      valueGetter: (params) => {
        const { value } = params;
        return value;
      }
    },
    {
      field: "tb_quoted_vendor",
      headerName: "TB QUOTED VENDOR",
      width: 160,
      disableExport: true,
      align: "left",
      // filterOperators: [
      //   {
      //     value: "request",
      //     label: "Requested",
      //     getApplyFilterFn: (filterItem) => {
      //       if (
      //         !filterItem.field ||
      //         !filterItem.value ||
      //         !filterItem.operator
      //       ) {
      //         return null;
      //       }
      //       return ({ value }) => {
      //         return Number(value.requested) == Number(filterItem.value);
      //       };
      //     },
      //     InputComponent: QuotedFromFilterInput
      //   },
      //   {
      //     value: "quoted",
      //     label: "Quoted",
      //     getApplyFilterFn: (filterItem) => {
      //       if (
      //         !filterItem.field ||
      //         !filterItem.value ||
      //         !filterItem.operator
      //       ) {
      //         return null;
      //       }

      //       return ({ value }) => {
      //         return Number(value.quoted) == Number(filterItem.value);
      //       };
      //     },
      //     InputComponent: QuotedFromFilterInput
      //   }
      // ],
      renderCell: (params) => {
        if (params?.row?.valid_date_tb == null) {
          return '-';
        } else {
          return <React.Fragment>
            <Box component="div">
              <Box
                component="div"
                onClick={() => handleTbQuotedVendor(params.row)}
                sx={{ cursor: "pointer" }}
              >
                <span style={{ color: "var(--primary-400)" }}>
                  {params.row.tb_quoted_vendor}

                </span>

              </Box>
            </Box>
          </React.Fragment>
        }
      },
      valueGetter: (params) => {
        const { value } = params;
        return value == null ? '-' : value;
      }
    },
    {
      field: "negotiation",
      headerName: "NEGOTIATION STATUS",
      width: 160,
      renderCell: negotiationStatusCell,
      valueGetter: (params) => (params.row.negotiation ? "YES" : "NO")
    },
    {
      field: "created_at",
      headerName: "RFQ DATE",
      width: 140,
      type: "date",
      renderCell: rfqStartDateCell,
      valueGetter: valueGetterForDate,
      // valueGetter: valueGetterForDate,
      valueFormatter: (params) => {
        const { value } = params;
        return (
          moment(value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss") +
          " IST"
        );
      }
    },
    {
      field: "expiry_at",
      headerName: "VALIDITY DATE",
      width: 140,
      // editable: statusClosed ? false : true,
      filterable: true,
      type: "date",
      renderCell: rfqEndDateCell,
      valueGetter: valueGetterForDate,
      valueFormatter: (params) => {
        const { value } = params;
        return (
          moment(value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss") +
          " IST"
        );
      }
    },
    {
      field: "qcs_status",
      headerName: "QCS STATUS",
      width: 140,
      filterable: false,
      disableExport: true,
      renderCell: qcsStatusCell
    },
    {
      field: "pr_status",
      headerName: "PR STATUS",
      width: 140,
      filterable: false,
      disableExport: true,
      renderCell: prStatusCell
    },
    {
      field: "category_name",
      headerName: "CATEGORY NAME",
      width: 160,
      renderCell: (params) => {
        return (
          <Tooltip title={params?.row?.inquiry_categories.name}>
            <Typography variant="body2" noWrap>
              {params?.row?.inquiry_categories.name}
            </Typography>
          </Tooltip>
        );
      },
      valueGetter: (params) => params?.row?.inquiry_categories.name
    },
    {
      field: "category_code",
      // align: "left",
      headerName: "CATEGORY CODE",
      width: 160,
      renderCell: (params) => {
        // const { value } = params;
        return (
          <Tooltip
            title={
              params?.row?.inquiry_categories?.code !== null
                ? params?.row?.inquiry_categories?.code
                : ""
            }
          >
            <Typography variant="body2" noWrap>
              {params?.row?.inquiry_categories?.code !== null ? (
                params?.row?.inquiry_categories?.code
              ) : (
                <Typography ml={4}>-</Typography>
              )}
            </Typography>
          </Tooltip>
        );
      },
      valueGetter: (params) =>
        params?.row?.inquiry_categories.code
          ? params?.row?.inquiry_categories.code
          : "-"
    },
    {
      field: "inquiry_type",
      headerName: "TYPE",
      width: 140,
      renderCell: typeCell,
      valueGetter: (params) =>
        params?.row?.item_specific_vendor
          ? "Item Specific"
          : "Category Specific"
    },
    {
      field: "template_name",
      headerName: "TEMPLATE NAME",
      width: 260,
      renderCell: (params) => {
        const rfq = params.row;
        // const templateName = templateNames[rfq.template_id] || "-";
        const templateName = templateNames[rfq.template_id];
        return (
          <Tooltip title={templateName}>
            {templateName ? (
              <Typography variant="body2" noWrap>
                {templateName}
              </Typography>
            ) : (
              <Typography
                sx={{
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                -
              </Typography>
            )}
          </Tooltip>
        );
      },
      valueGetter: (params) => {
        const rfq = params.row;
        const templateName = templateNames[rfq.template_id] || "-";
        return templateName;
      }
    }
  ];

  const OpenSearchFilterModal = () => {
    setShowModal(true);
  };

  const CloseSearchFilterModal = () => {
    setShowModal(false);
  };

  const handleCloseSearchModal = () => {
    CloseSearchFilterModal();
  };


  const getMissingDateRanges = (sortedData, filterStartDate, filterEndDate) => {
    let missingRanges = [];

    // Initialize moment objects for filter dates
    let startFilterDate = moment(filterStartDate, "YYYY-MM-DD");
    let endFilterDate = moment(filterEndDate, "YYYY-MM-DD");

    // Create an object to track months with data
    let monthsWithData = {};

    // Populate the monthsWithData object based on sortedData
    sortedData.forEach(entry => {
      let entryDate = moment(entry?.created_at, "YYYY-MM-DD");
      let monthYear = entryDate.format("YYYY-MM");
      monthsWithData[monthYear] = true;
    });

    // Iterate over each month between start and end filter dates
    let currentDate = moment(startFilterDate).startOf('month');
    let endDate = moment(endFilterDate).startOf('month');

    while (currentDate.isSameOrBefore(endDate)) {
      let monthYear = currentDate.format("YYYY-MM");

      if (!monthsWithData[monthYear]) {
        // Missing month found, calculate the range
        let start = moment(currentDate).startOf('month').format("YYYY-MM-DD");
        let end = moment(currentDate).endOf('month').format("YYYY-MM-DD");
        missingRanges.push({ start, end });
      }

      // Move to the next month
      currentDate.add(1, 'month');
    }


    const calculateDynamicRange = (dateRanges) => {
      if (dateRanges.length === 0) {
        return null; // Handle case where array is empty
      }
      if (moment(dateRanges[0].start, 'YYYY-MM-DD').isBefore(currentDate)) {

        const consolidatedRanges = [];
        let currentRange = null;

        dateRanges.forEach(range => {
          const start = range.start;
          const end = range.end;

          if (!currentRange) {
            // Start a new range
            currentRange = { start, end };
          } else {
            const currentEnd = currentRange.end;

            // Check if the current range can be extended
            const nextDate = new Date(currentEnd);
            nextDate.setDate(nextDate.getDate() + 1); // Move to the next day
            const endDate = new Date(end);
            if (nextDate.getFullYear() === endDate.getFullYear() && nextDate.getMonth() === endDate.getMonth()) {
              // Extend the current range
              currentRange.end = end;
            } else {
              // Push the current range and start a new one
              consolidatedRanges.push({ ...currentRange });
              currentRange = { start, end };
            }
          }
        });

        // Push the last range
        if (currentRange) {
          consolidatedRanges.push(currentRange);
        }

        return consolidatedRanges;

      } else {
        const start = dateRanges[0].start;

        const end = dateRanges[dateRanges.length - 1].end;

        return [{ start, end }];
      }
    };

    let dynamicRange = calculateDynamicRange(missingRanges);


    return dynamicRange;
  };


  const disablePastAndFutureThreeYears = (date) => {
    const threeYearsAgo = dayjs().subtract(3, 'year');
    const threeYearsFromNow = dayjs().add(3, 'year');
    return date.isBefore(threeYearsAgo, 'day') || date.isAfter(threeYearsFromNow, 'day');
  };

  // Callback function to update filtered list data

  const handleFilterChange = async (filteredData) => {
    await setFilteredListData(filteredData);
  };

  const [FilteredListDatas, setFilteredListDatas] = React.useState([]);
  const handleFilterChanges = async (filteredDatas) => {
    setFilteredListDatas(filteredDatas);
  };

  const prevFetchedRanges = React.useRef([]);
  const [getRange, setRange] = React.useState(true);

  const [startdate, setstartdate] = React.useState(null);
  const [enddate, setenddate] = React.useState(null);

  const fetchData = React.useCallback(async () => {
    if (FilteredListDatas.length == 0) {
      if (value && value[0] && value[0].$d) {
        setLoading(true);
        const startDate = moment(value[0].$d).format("YYYY-MM-DD");
        const endDate = value[1] ? moment(value[1].$d).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");


        const filteredRows = RFQListData.filter((row) => {
          const date = moment(row.created_at, "YYYY-MM-DD");
          return date.isBetween(startDate, endDate, undefined, '[]');
        });
        setstartdate(startDate);
        setenddate(endDate);
        setFilteredRows(filteredRows);

        const missingRanges = getMissingDateRanges(RFQListData, startDate, endDate);

        if (!disablePastAndFutureThreeYears(dayjs(startDate))) {

          console.log("missingRanges", missingRanges);

          if (missingRanges && missingRanges.length > 0) {
            for (const range of missingRanges) {
              const rangeKey = `${range.start}-${range.end}`;
              if (!prevFetchedRanges.current.includes(rangeKey)) {
                try {
                  await dispatch(setRfqListInfo(sessionID, [range])); // Fetch and dispatch data for each range
                  prevFetchedRanges.current.push(rangeKey); // Mark this range as fetched
                } catch (error) {
                  console.error(`Error fetching data for range ${rangeKey}:`, error);
                }
              }
            }
          }
        }
        setLoading(false)
      } else {
        setFilteredRows(RFQListData);
        setLoading(false); // Ensure setLoading(false) is at the appropriate place
      }
    } else {
      if (value && value[0] && value[0].$d) {
        const startDate = moment(value[0].$d).format("YYYY-MM-DD");
        const endDate = value[1] ? moment(value[1].$d).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");

        try {
          let apiUrl = get_All_Inquiries_Data + `?${FilteredListDatas}&start_date=${startDate}&end_date=${endDate}`;
          let response = await axios.get(apiUrl, configAxios);

          const filteredData = response.data.inquires;
          setFilteredRows(filteredData);
          await setFilteredListData(filteredData);
        } catch (error) {
          // Handle error
          setSnackbar(true);
          setSnackMessage("Filtered Data Not Found");
          setseverity("error");

        }

      }
    }
  }, [value, RFQListData, FilteredListDatas]);

  React.useEffect(() => {
    fetchData();
  }, [value, FilteredListDatas]);



  React.useEffect(() => {
    if (RFQListData && RFQListData.length > 0 && value && value[0] && value[0].$d) {
      const startDate = moment(value[0].$d).format("YYYY-MM-DD");
      const endDate = value[1] ? moment(value[1].$d).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");

      const filteredRows = RFQListData.filter((row) => {
        const date = moment(row.created_at, "YYYY-MM-DD");
        return date.isBetween(startDate, endDate, undefined, '[]');
      });

      setFilteredRows(filteredRows);
    } else {
      setFilteredRows(RFQListData);
    }
  }, [RFQListData, value]);

  React.useEffect(() => {
    async function fetchUsersData() {
      try {
        const response = await axios.get(users_Data, configAxios);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        setUserData(response.data.users);
      } catch (error) {
        console.error("Error fetching User Data:", error);
      }
    }
    fetchUsersData();
  }, []);

  React.useEffect(() => {
    async function fetchAllTemplateData() {
      try {
        const response = await axios.get(all_templates, configAxios);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const templateData = response.data.data;
        const templateNameMap = Object.fromEntries(
          templateData.map((template) => [template.id, template.name])
        );
        setTemplateNames(templateNameMap);
      } catch (error) {
        console.error("Error fetching User Data:", error);
      }
    }
    fetchAllTemplateData();
  }, []);

  // React.useEffect(() => {
  //   async function fetchRfqListData() {
  //     try {
  //       const response = await axios.get(get_Inquiries, configAxios);
  //       if (response.status !== 200) {
  //         throw new Error("Network response was not ok");
  //       }
  //       setRFQListData(response.data.inquires);
  //     } catch (error) {
  //       console.error("Error fetching User Data:", error);
  //     }
  //   }
  //   fetchRfqListData();
  // }, []);

  React.useEffect(() => {
    dispatch(setRfqListInfo(sessionID, [
      {
        "start": startdate,
        "end": enddate
      }
    ]));
  }, [dispatch]);

  const downloadExcel = async () => {
    const selecetedRowsIds = selecetedRows.map((row) => row.id).join(",");
    window.open(
      q_plus_screen +
      `?action=export_rfq_link_unlink_user&selected_rows_id=${selecetedRowsIds}`
    );
  };

  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <Typography>No data available</Typography>
    </GridOverlay>
  );

  const getCellClassName = (params) => {
    if (params.field === "rfq_status" && params.row.rfq_status === "30") {
      return "hovered-cell";
    }
    return "";
  };

  const handleRowSelectionChange = (newSelection) => {
    // Extract selected IDs from newSelection
    const selectedIds = newSelection;

    // Filter the rows based on selected IDs and set them in selectedRows state
    const selectedRowsData = RFQListData.filter((row) =>
      selectedIds.includes(row.id)
    );

    setSelectedRows(selectedRowsData);
    setRowSelectionModel(newSelection);
  };


  const checkRFQValidity = (rfqDateStr, extensionHours) => {
    var rfqDate = parseDateString(rfqDateStr);
    var currentDate = new Date();
    if (rfqDate < currentDate) return false;

    return (rfqDate - currentDate) / 1000 <= extensionHours * 3600;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatUnit = unit => String(unit).padStart(2, '0');

    return `${formatUnit(date.getDate())}/${formatUnit(date.getMonth() + 1)}/${date.getFullYear()} ` +
      `${formatUnit(date.getHours())}:${formatUnit(date.getMinutes())}:${formatUnit(date.getSeconds())}`;
  }

  const parseDateString = (dateString) => {
    var parts = dateString.split(/[\s/:]/);
    return new Date(`${parts[2]}-${parts[1]}-${parts[0]}T${parts[3]}:${parts[4]}:${parts[5]}`);
  }

  const restrictValidityDate = (bidValidityDate, transaction_status, qouted_vendor_count) => {
    if (Permisison?.featurePermission?.rfq_validity_extension?.feature_value == 'Y' && qouted_vendor_count >= 1 && (transaction_status === 'Ongoing' || transaction_status === 'Expired')) {
      if (transaction_status === 'Expired') {
        setSnackbar(true);
        setseverity("error");
        setSnackMessage(
          "Extension of RFQ validity has been restricted."
        );
        return 1;
      }
      const formattedDate = formatDate(bidValidityDate);
      const extension_value = Permisison.featurePermission.rfq_validity_extension.feature_permission_value;
      const isValid = checkRFQValidity(formattedDate, extension_value);

      if (isValid) {
        setSnackbar(true);
        setseverity("error");
        setSnackMessage(
          "Extension of RFQ validity has been restricted."
        );
        return 1;
      }
    }
    return 0;
  }
  // const handleRowSelectionChange = (newSelection) => {
  //   console.log('newSelection', newSelection, newSelection.includes('__selectAll__'));
  //   // Extract selected IDs from newSelection
  //   const isSelectAllChecked = newSelection.length === RFQListData.length;
  //   let selectedIds = [];
  //   // If all rows are selected, update selection to only include current page's data
  //   if (isSelectAllChecked) {
  //     const startIdx = paginationModel.page * paginationModel.pageSize;
  //     const endIdx = startIdx + paginationModel.pageSize;
  //     const currentPageRows = RFQListData.slice(startIdx, endIdx);
  //     // const currentPageRows = RFQListData.slice(0, paginationModel?.pageSize); // Assuming 5 rows per page, adjust accordingly
  //     const currentPageIds = currentPageRows.map((row) => row.id);
  //     console.log('currentPageIds', currentPageIds);
  //     selectedIds = currentPageIds
  //     // setSelectionModel(currentPageIds);
  //   } else {
  //     selectedIds = newSelection
  //     // setSelectionModel(newSelectionModel);
  //   }
  //   // const selectedIds = newSelection;
  //   // Filter the rows based on selected IDs and set them in selectedRows state
  //   const selectedRowsData = RFQListData.filter((row) =>
  //   selectedIds.includes(row.id)
  //   );
  //   console.log('selectedIds', selectedIds, selectedRowsData);

  //   setSelectedRows(selectedRowsData);
  //   setRowSelectionModel(selectedIds);
  // };

  const handleValidityDateOpen = (params) => {
    const { value, field, id } = params;
    setSelecetedValidDate(value);
    const dateParts = params?.row?.created_at.split(" ");
    if (dateParts.length === 3) {
      const [datePart, timePart, timeZone] = dateParts;
      const iso8601DateTime = `${datePart}T${timePart}`;
      const parsedDate = new Date(iso8601DateTime);

      // Check if the parsed date is a valid date object
      if (!isNaN(parsedDate)) {
        setValidRFQDate(parsedDate);
        const rfqValidityDate = moment(parsedDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        if (Permisison?.featurePermission?.max_rfq_validity_days?.feature_value == 'Y') {
          const inputDate = dayjs(rfqValidityDate);
          const maxrfqDate = inputDate.add(
            Permisison?.featurePermission?.max_rfq_validity_days
              ?.feature_permission_value,
            "day"
          );

          setMaxRfqValidity(`${maxrfqDate.format(
            "DD/MM/YYYY HH:mm:ss"
          )}`)
        }
      }
    }
    if (
      params?.row?.closed_at === null &&
      (userInfo?.user_id == params?.row?.created_by ||
        userInfo?.is_super_user == "Y" ||
        Permisison?.masterPermission?.extend_rfq == "Y")
    ) {
      if (field === "expiry_at") {
        const { vendor_count: { count_quoted_vendors: quotedVendorCount }, status: transactionStatus } = params.row;
        if (restrictValidityDate(value, transactionStatus, quotedVendorCount)) {
          return;
        }
        setOpenValidityDateModal(true);
        setInquiryCreatedBy(params?.row?.created_by);
        setRFQNoForValidityDateModal(params?.row?.number);
      }
      setDateValues(params);
    }
  };

  const handleValidityDateClose = () => {
    setOpenValidityDateModal(false);
    setSelecetedRowValidDate(null);
    setJustificationMessage(null);
    setDateValues(null);
  };

  const handleJustificationChange = (e) => {
    setJustificationMessage(e.target.value);
  };

  const fetchPermissions = () => {
    axios.defaults.headers["Authorization"] = `Bearer ${sessionID}`;
    const url = getUserInfo;
    axios
      .get(url)
      .then((res) => {
        setProfile(res.data);
        dispatch({
          type: SET_USER_INFO,
          payload: res.data
        });
        const feturepermisson = getFeaturePermission(res.data.data.division_id);
        axios
          .get(feturepermisson)
          .then((res) => {
            setFeaturePermission(res.data);
            dispatch({
              type: SET_FEATURE_PERMISSION,
              payload: res.data
            });
          })
          .catch((err) => {
            dispatch({
              type: SET_FEATURE_PERMISSION_ERROR,
              payload: err.message
            });
          });
        const url = getMasterPermission(res.data.data.division_id);
        axios
          .get(url)
          .then((res) => {
            setMasterPermission(res.data);
            dispatch({
              type: SET_MASTER_PERMISSION,
              payload: res.data
            });
          })
          .catch((err) => {
            dispatch({
              type: SET_MASTER_PERMISSION_ERROR,
              payload: err.message
            });
          });
        const modulepermisson = getAccountModulePermission(
          res.data.data.division_id
        );
        axios
          .get(modulepermisson)
          .then((res) => {
            setAccountPermission(res.data);
            dispatch({
              type: SET_ACCOUNT_MODULE_PERMISSION,
              payload: res.data
            });
          })
          .catch((err) => {
            dispatch({
              type: SET_ACCOUNT_MODULE_PERMISSION_ERROR,
              payload: err.message
            });
          });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setSnackbar(true);
          setseverity("error");
          setSnackMessage(
            "Your Login session could not be validated. Please Login again..."
          );
          dispatch({
            type: OPEN_SNACKBAR,
            payload: {
              type: "error",
              msg: "Your Login session could not be validated. Please Login again..."
            }
          });
          dispatch({
            type: SET_USER_INFO_ERROR,
            payload:
              "Your Login session could not be validated. Please Login again..."
          });
        } else {
          setSnackbar(true);
          setseverity("error");
          setSnackMessage(
            error?.response?.data.message
              ? error?.response?.data.message
              : "Something went wrong"
          );
          // dispatch({
          //   type: OPEN_SNACKBAR,
          //   payload: {
          //     type: "error",
          //     msg: error?.response?.data.message
          //       ? error?.response?.data.message
          //       : "Something went wrong"
          //   }
          // });
          dispatch({
            type: SET_USER_INFO_ERROR,
            payload: error.message
          });
        }
      });
  };

  React.useEffect(() => {
    setHasPerformedUpdate(false);
    if (
      Profile &&
      Object.keys(MasterPermission).length > 0 &&
      Object.keys(AccountPermission).length > 0
    ) {
      const { vendor_count: { count_quoted_vendors: quotedVendorCount }, status: transactionStatus } = dateValues.row;
      if (restrictValidityDate(dateValues.value, transactionStatus, quotedVendorCount)) {
        return;
      }
      if (
        (InquiryCreatedBy && Profile?.data?.user_id == InquiryCreatedBy) ||
        Profile?.data?.is_super_user == "Y" ||
        MasterPermission?.data?.extend_rfq == "Y"
      ) {
        if (!selecetedRowValidDate) {
          return;
        }
        const { id, value } = dateValues;
        // Create the validity_date string in the desired format
        const validity_date = moment(selecetedRowValidDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        if (
          FeaturePermission?.data?.max_rfq_validity_days?.feature_value == "Y"
        ) {
          const ChangedDate = dayjs(validity_date);
          const rfqValidityDate = moment(ValidRFQDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          const inputDate = dayjs(rfqValidityDate);
          const resultDate = inputDate.add(
            FeaturePermission?.data?.max_rfq_validity_days
              ?.feature_permission_value,
            "day"
          );
          setMaxRfqValidity(`${resultDate.format(
            "DD/MM/YYYY HH:mm:ss"
          )}`)

          if (
            ChangedDate.isBefore(resultDate) ||
            ChangedDate.isSame(resultDate)
          ) {
            axios
              .patch(
                validity_date_update(id),
                {
                  valid_date: validity_date,
                  justification: justificationMessage
                },
                configAxios
              )
              .then((response) => {
                if (response.status === 200) {

                  dispatch(setRfqListInfo(sessionID,
                    [
                      {
                        "start": startdate,
                        "end": enddate
                      }
                    ]
                  ));

                  setJustificationMessage(null);
                  setSelecetedRowValidDate(null);
                  setSnackbar(true);
                  setseverity("success");
                  setSnackMessage("Validity Date Updated Successfully");
                  setDateValues(null);
                  setupdatevalidity(true);
                } else {
                  console.error(
                    `PATCH request failed with status code ${response.status}.`
                  );
                }
                setHasPerformedUpdate(true);
              })
              .catch((error) => {
                console.error(`Error: ${error.message}`);
                setHasPerformedUpdate(true);
              });

            handleValidityDateClose();
          } else {
            setSnackbar(true);
            setError(true)
            setSnackMessage(
              `Maximum RFQ Validity ${resultDate.format(
                "DD/MM/YYYY HH:mm:ss"
              )} IST`
            );
            setseverity("error");
            setHasPerformedUpdate(true);
          }
        } else {
          axios
            .patch(
              validity_date_update(id),
              {
                valid_date: validity_date,
                justification: justificationMessage
              },
              configAxios
            )
            .then((response) => {
              if (response.status === 200) {
                dispatch(setRfqListInfo(sessionID,
                  [
                    {
                      "start": startdate,
                      "end": enddate
                    }
                  ]
                ));//Test purpose only Has to change this Only Rejected RFQ List is coming for now
                setJustificationMessage(null);
                setSelecetedRowValidDate(null);
                setSnackbar(true);
                setseverity("success");
                setSnackMessage("Validity Date Updated Successfully");
                setDateValues(null);
              } else {
                console.error(
                  `PATCH request failed with status code ${response.status}.`
                );
              }
              setHasPerformedUpdate(true);
            })
            .catch((error) => {
              console.error(`Error: ${error.message}`);
              setHasPerformedUpdate(true);
            });

          handleValidityDateClose();
        }
        // .format('YYYY-MM-DD HH:mm:ss');
        // return false;
      } else {
        setSnackbar(true);
        setseverity("error");
        setSnackMessage("You don't have permission");
        // dispatch({
        //   type: OPEN_SNACKBAR,
        //   payload: {
        //     type: "error",
        //     msg: "You don't have permission"
        //   }
        // });
        setHasPerformedUpdate(true);
      }
    }
  }, [Profile, MasterPermission, AccountPermission, FeaturePermission]);

  React.useEffect(() => {
    if (HasPerformedUpdate) {
      setProfile({});
      setAccountPermission({});
      setMasterPermission({});
      setFeaturePermission({});
    }
  }, [HasPerformedUpdate]);
  const handleSaveDate = () => {
    fetchPermissions();
  };

  const handleDateChange = (date) => {
    setSelecetedRowValidDate(date.$d);
  };

  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");

  // React.useEffect(() => {
  //   // setTimeout(function () {
  //   //   setFlagFromCHild(false);
  //   // }, 3000);
  // }, [flagFromChild]);


  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <React.Fragment>
      {RFQDataGStyle}
      <main
        sx={{
          width: "100%",
          maxWidth: "100%",
          padding: 0
        }}
      >
        <PageToolbar className="datagrid-PageToolbar head-datagrid-toolbar">
          <Stack spacing={2} direction="row" alignItems={"center"}>
            <Typography className="app-pagetitle">
              Transaction Register
            </Typography>
            <Stack
              spacing={1}
              direction="row"
              alignItems={"center"}
              sx={{
                ml: "auto !important",
                display: { xs: "none", sm: "none", md: "inline-flex" },
              }}
            >
              {/* <SelectGridView />
              <UserPreference /> */}
            </Stack>
          </Stack>
        </PageToolbar>
        <RFQSearchModal
          sessionID={sessionID}
          showModal={showModal}
          setShowModal={setShowModal}
          CloseSearchFilterModal={CloseSearchFilterModal}
          OpenSearchFilterModal={OpenSearchFilterModal}
          onFilterChange={handleFilterChange}
          FilteredListDatas={handleFilterChanges}
          sendFlagToParent={receiveFlagFromChild}
          value={[
            {
              "start": startdate,
              "end": enddate
            }
          ]}

        />
        <Backdrop
          style={{ color: "var(--thm-white)", zIndex: "10000000000" }}
          open={flagFromChild}
        >
          <CircularProgress style={{ color: "var(--thm-white)" }} />
        </Backdrop>
        <Box className="DataGFullScreen-wrap">
          <CommonDataGridPro
            loading={loading}
            rows={
              filteredListData?.length > 0 ? filteredListData : filteredRows
            }
            columns={columns}
            headerHeight={40}
            columnHeaderHeight={40}
            checkboxSelection
            Toolbar={CustomGridToolbar}
            NoRowsOverlay={CustomNoRowsOverlay}
            onRowSelectionModelChange={handleRowSelectionChange}
            filterModel={filterModel}
            onFilterModelChange={handleFilterModelChange}
            // rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick
            onCellClick={handleValidityDateOpen}
            pagination={true}
            pageSizeOptions={[25, 50, 100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            // getRowHeight={() => "auto"}
            getRowId={(row) => row.id}
            className="fullScreenGrid"
            sx={{
              "&.MuiDataGrid-root": {
                border: 0
              },
              "& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
              {
                borderBottomColor: "var(--bluegrey-500)"
              }
            }}
            getCellClassName={getCellClassName}
          />
        </Box>
      </main>
      <RFQMenuItems
        sessionID={sessionID}
        openMenu={openMenu}
        menulabels={menulabels}
        handleClose={handleClose}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        userData={userData}
        categoryId={categoryId}
        r2aId={r2aId}
        linkUnlinkData={linkUnlinkData}
        isPriortized={isPriortized}
        linkDataFlag={linkDataFlag}
        InquiryData={InquiryData}
      />
      <ItemWiseVendor
        sessionID={sessionID}
        templateId={templateId}
        itemSpecificVendor={itemSpecificVendor}
        openDialogueBox={openDialogueBox}
        handleCloseDialogue={handleCloseDialogue}
        selectedId={selectedId}
        linkUnlinkData={linkUnlinkData}
        filterDataChip={filterDataChip}
        vendorCancelQuote={vendorCancelQuote}
        statusOngoing={statusOngoing}
        filterLabels={filterLabels}
        // setNegoDialogueClose={handleCloseDialogue}
      />
      <Box>
        <Drawer
          className="RFQSidebar-Drawer"
          onClose={() => setOpenPrDrawer(false)}
          variant="temporary"
          anchor="right"
          open={openPrDrawer}
        >
          <PrGridDrawer
            sessionID={sessionID}
            setOpenPrDrawer={setOpenPrDrawer}
            selectedId={selectedId}
            linkUnlinkData={linkUnlinkData}
          />
        </Drawer>
        <Drawer
          className="RFQSidebar-Drawer"
          onClose={() => setOpenQcsDrawer(false)}
          variant="temporary"
          anchor="right"
          open={openQcsDrawer}
        >
          <QcsGridDrawer
            sessionID={sessionID}
            setOpenQcsDrawer={setOpenQcsDrawer}
            selectedId={selectedId}
            linkUnlinkData={linkUnlinkData}
          />
        </Drawer>
      </Box>
      <JustificationPopOver
        open={openMenu2}
        anchorEl={anchorEl2}
        onClose={handlePopoverClose}
        justificationTitle="Justification (RFQ Closed)"
        justificationDetails={JustificationContent ? JustificationContent : "-"}
      />
      <LinkUnlinkUser
        sessionID={sessionID}
        openDialogueBox={openLinkUnlinkBox}
        handleCloseDialogue={handleCloseLinkUnlinkBox}
        linkUnlinkData={linkUnlinkData}
        userData={userData}
        rowSelectionModel={rowSelectionModel}
        SelctedRFQ={rowSelectionModel}
      />
      
      <Dialog
        open={openValidityDateModal}
        onClose={handleValidityDateClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}>
        <DialogTitle
          sx={{
            "&.MuiTypography-root": {
              p: 2
            }
          }}
        >
          {RFQNoForValidityDateModal}
        </DialogTitle>
        <DialogContent sx={{ padding: "16px 16px 0 16px" }}>
          <DialogContentText>
            <Typography
              component="div"
              variant="subtitle1"
              fontWeight={500}
              color="var(--black-textcolor)"
            >
              Please select the new validity date and time:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                fullWidth
                renderInput={(props) => <TextField {...props} />}
                ampm={false}
                // label="Select Date and Time"
                // inputFormat="L LT"
                disablePast

                value={
                  selecetedRowValidDate !== null
                    ? dayjs(selecetedRowValidDate)
                    : dayjs(selecetedValidDate)
                }
                format="DD/MM/YYYY HH:mm:ss"
                onChange={handleDateChange}
                sx={{
                  width: "100%",
                  mt: 1
                }}
                slotProps={{
                  textField: {
                    helperText: MaxRfqValidity != '' && `Maximum RFQ Validity ${MaxRfqValidity} IST`,
                    error: Error
                  },
                  layout: {
                    sx: {
                      "& .MuiDateCalendar-root, & .MuiMultiSectionDigitalClock-root":
                      {
                        maxHeight: "240px"
                      },
                      "& .MuiDateCalendar-root": {
                        width: "300px"
                      },
                      "& .MuiDayCalendar-weekDayLabel": {
                        width: "30px",
                        height: "30px"
                      },
                      "& .MuiPickersDay-root": {
                        width: "30px",
                        height: "30px"
                      },
                      "& .MuiPickersCalendarHeader-root": {
                        marginTop: "8px",
                        maxHeight: "25px",
                        minHeight: "25px"
                      }
                    }
                  }
                }}
              />
            </LocalizationProvider>
            <TextField
              autoFocus
              fullWidth
              multiline
              required={true}
              variant="outlined"
              label="Justification"
              rows={3}
              sx={{ width: "100%", resize: "none", marginTop: "8px" }}
              value={justificationMessage} // Bind the input field value to the state
              onChange={handleJustificationChange} // Handle changes to the justification text
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleValidityDateClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          {justificationMessage !== null &&
            selecetedRowValidDate !== null &&
            justificationMessage !== "" &&
            selecetedRowValidDate !== "" ? (
            <Button
              onClick={handleSaveDate}
              color="primary"
              variant="contained"
            >
              Confirm
            </Button>
          ) : (
            <Button disabled color="primary" variant="outlined">
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={AnchorElTBquoted}
        onClose={handleTbQuotedVendorClose}
        fullScreen
        sx={{ m: 4 }}
      >
        <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
          TB Quoted Vendor
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleTbQuotedVendorClose}
          sx={{ position: "absolute", right: 8, top: 13 }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {LoadingPerl === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <InnerHTML html={QuotedVendorTB} />
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackbar}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          variant="filled"
        >
          {SnackMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
export default RfqDataGrid;
